import React from 'react'
import CommonTitle from '../../../../../Components/CommonTitle'
import SlideTab from '../../../../../Components/SlideTab'
import '../index.scss'

export default function CL_4(props) {
  const { common_title, slide_data } = props
  return (
    <>
      <div className={`mdui-container-filud common`}>
        <div className={`mdui-container common_block`}>
          <CommonTitle res_data={common_title} />
        </div>
      </div>
      <div className={`mdui-container-filud common`}>
        <div className={`mdui-container common_block`}>
          <SlideTab swiper_data={slide_data} />
        </div>
      </div>
    </>
  )
}