import { Switch, Route } from 'react-router-dom';
import Portal from './Pages/Portal';
import './Tools/css/animate.min.css';
import wow from 'wow.js/dist/wow.js';
import './locales/i18n';

new wow({
  boxClass: 'wow',
  animateClass: 'animated',
  offset: 0,
  mobile: true,
  live: true
}).init()

function App() {

  return (
    <div className="App">
      <Switch>
        <Route path="/" component={Portal} />
      </Switch>
    </div>
  );
}

export default App;
