import React from 'react';
import BlockTitle from '../../../../Components/Portal/BlockTitle';
import Card1 from '../../../../Components/Portal/Card1';
import Expand6Css from './index.module.scss'

const Expand6 = (props) => {
    const { item, layout, subPage } = props
    return (
        <div className={`${subPage === true && Expand6Css.Expand6_block2} ${subPage === false && `bw_theme_odd_block_blue ${Expand6Css.Expand6_block}`}`}>
            <div className={`mdui-container${layout && `-${layout}`}`}>
                <div className={Expand6Css.Expand6_title}>{item.blockTitle && <BlockTitle item={item.blockTitle} />}</div>
                {item.card_data && <Card1 card_data={item.card_data} />}
            </div>
        </div>
    );
}

export default Expand6;
