import React, { Fragment } from 'react';
import { nanoid } from 'nanoid';
import BlockTitle from '../../../../Components/Portal/BlockTitle';
import Expand13Css from './index.module.scss';

const Expand13 = (props) => {
    const { item, layout } = props
    return (
        <>
            {
                item.blockTitle && <BlockTitle content={item.blockTitle[0]} line={item.blockTitle[1]} />
            }
            <div className={`mdui-container${layout && `-${layout}`}`}>
                <div className="mdui-row">
                    <div className={`mdui-col-md-8`}>
                        {
                            item.instructions.map(
                                item =>
                                    <Fragment key={nanoid()}>
                                        <p className={`${Expand13Css.instructions_title}`}>{item.title}</p>
                                        <p className={`${Expand13Css.instructions_summary}`}>{item.summary}</p>
                                    </Fragment>
                            )
                        }
                    </div>
                    <div className={`mdui-col-md-4`}>
                        <img src={item.cover} alt="" width='100%' className={`${Expand13Css.instructions_cover}`} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Expand13;
