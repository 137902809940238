import React from 'react';
import ChainCss from './index.module.scss';

const Chain = (param) => {
    const { chain_data } = param
    const {
        firstData,
        secondData,
        thirdData,
        fourthData,
        fifthData,
        sixthData
    } = chain_data
    return (
        <div className={`mdui-row ${ChainCss.chain}`}>
            <div className='mdui-col-xs-12 mdui-col-sm-4 mdui-col-md-4 wow slideInLeft animated'>
                <div className='mdui-col-xs-12 mdui-hidden-sm-down' style={{ height: '120px' }}></div>
                <div className='mdui-col-xs-12'>
                    <div className={`mdui-card mdui-shadow-0 ${ChainCss.chain_card_block} ${ChainCss.chain_card}`}>
                        <div className="mdui-card-header">
                            <i className="mdui-icon material-icons mdui-card-header-avatar" style={{ fontSize: '38px', color: '#f7b500' }}>&#xe1db;</i>
                            <div className="mdui-card-header-title">{firstData.title}</div>
                            <div className={`mdui-card-header-subtitle ${ChainCss.chain_card_summary}`}>{firstData.summary}</div>
                        </div>
                    </div>
                </div>
                <div className='mdui-col-xs-12'>
                    <div className={`mdui-card mdui-shadow-0 ${ChainCss.chain_card}`}>
                        <div className="mdui-card-header">
                            <i className="mdui-icon material-icons mdui-card-header-avatar" style={{ fontSize: '38px', color: '#0091ff' }}>&#xe24b;</i>
                            <div className="mdui-card-header-title">{secondData.title}</div>
                            <div className={`mdui-card-header-subtitle ${ChainCss.chain_card_summary}`}>{secondData.summary}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mdui-col-md-4 mdui-hidden-sm-down wow pulse animated'>
                <div className='mdui-col-xs-12 mdui-valign'>
                    <div className='mdui-center' style={{ height: '360px' }}>
                        <img src={thirdData.img} alt="" height='100%' />
                    </div>
                </div>
            </div>
            <div className='mdui-col-xs-12 mdui-col-sm-4 mdui-col-md-3 mdui-col-offset-md-1 wow slideInRight animated'>
                <div className='mdui-col-xs-12 mdui-hidden-sm-down' style={{ height: '120px' }}></div>
                <div className='mdui-col-xs-12'>
                    <div className={`mdui-card mdui-shadow-0 ${ChainCss.chain_card_block} ${ChainCss.chain_card}`}>
                        <div className="mdui-card-header">
                            <i className="mdui-icon material-icons mdui-card-header-avatar" style={{ fontSize: '38px', color: '#f2858b' }}>&#xeb3e;</i>
                            <div className="mdui-card-header-title">{fourthData.title}</div>
                            <div className={`mdui-card-header-subtitle ${ChainCss.chain_card_summary}`}>{fourthData.summary}</div>
                        </div>
                    </div>
                </div>
                <div className='mdui-col-xs-12'>
                    <div className={`mdui-card mdui-shadow-0 ${ChainCss.chain_card}`}>
                        <div className="mdui-card-header">
                            <i className="mdui-icon material-icons mdui-card-header-avatar" style={{ fontSize: '38px', color: '#7d5eea' }}>&#xe322;</i>
                            <div className="mdui-card-header-title">{fifthData.title}</div>
                            <div className={`mdui-card-header-subtitle ${ChainCss.chain_card_summary}`}>{fifthData.summary}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className=' mdui-col-sm-4 mdui-col-md-12 mdui-valign wow slideInUp animated'>
                <div className={`mdui-card mdui-shadow-0 ${ChainCss.chain_last_card_block} ${ChainCss.chain_card_block}`}>
                    <div className="mdui-card-header">
                        <i className="mdui-icon material-icons mdui-card-header-avatar" style={{ fontSize: '38px', color: '#32c5ff' }}>&#xe428;</i>
                        <div className="mdui-card-header-title">{sixthData.title}</div>
                        <div className={`mdui-card-header-subtitle ${ChainCss.chain_card_summary}`}>{sixthData.summary}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Chain;
