import banner5 from "../Source/Image/banner5.png";
import banner1 from "../Source/Image/banner1.png";
import banner2 from "../Source/Image/banner2.png";
import banner3 from "../Source/Image/banner3.png";
import banner6 from "../Source/Image/banner6.png";
import banner7 from "../Source/Image/banner7.png";
import banner8 from "../Source/Image/banner8.png";
import banner9 from "../Source/Image/banner9.png";
import banner10 from "../Source/Image/banner10.png";
import about from "../Source/Image/about.png";
import banner_h_r from "../Source/Image/banner_h_r.png";
import sw_cover1 from "../Source/Image/sw_cover1.png";
import sw_bg from "../Source/Image/sw_bg.png";
import sw_cover2 from "../Source/Image/sw_cover2.png";
import chain_png from "../Source/Image/chain.png";
import content_cover_1 from "../Source/Image/content_cover_1.png";
import content_cover_2 from "../Source/Image/content_cover_2.png";
import content_cover_3 from "../Source/Image/content_cover_3.png";
import content_cover_4 from "../Source/Image/content_cover_4.png";
import content_cover_5 from "../Source/Image/content_cover_5.png";
import card_default_1 from "../Source/Image/card_default_1.png";
import card_default_2 from "../Source/Image/card_default_2.png";
import card_default_3 from "../Source/Image/card_default_3.png";
import card_default_4 from "../Source/Image/card_default_4.png";
import card_default_5 from "../Source/Image/card_default_5.png";
import ike_data_01 from "../Source/Image/ike_data_01.png";
import ike_data_02 from "../Source/Image/ike_data_02.png";
import ike_data_03 from "../Source/Image/ike_data_03.png";
import ike_data_04 from "../Source/Image/ike_data_04.png";
import ike_data_05 from "../Source/Image/ike_data_05.png";
import ike_data_06 from "../Source/Image/ike_data_06.png";
import preschool from "../Source/Image/preschool.png";
import chemical from "../Source/Image/chemical.png";
import intelligent from "../Source/Image/intelligent.png";

// 首页
export const homeState = {
    layout: "", // fluid
    subPage: false, // 判断是否为子页面
    title: "宜氪数据",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "宜氪数据一站式数据分析平台",
                summary: "打通数据治理全流程，快速满足政企用户各类不同的数据治理场景",
                bg: banner1,
                img: banner_h_r,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// banner横幅文字
            expand: "expand_3",
            status: true,// banner横幅文字, 值为true时,才会展示
            bannerText: "宜氪数据一家为企业推动数字化转型的公司，用数学模型解决实际问题"
        },
        {// 首页轮播图
            expand: "expand_4",
            status: true,
            blockTitle: {
                line: true,
                content: 'Cheerio-自然语言处理及分析',
                wide: false,
            },
            sw_data: [
                {
                    title: "数据采集",
                    itemLIst: [
                        "数据采集",
                        "IoT"
                    ],
                    img: {
                        cover: sw_cover1,
                        bg: sw_bg
                    }
                },
                {
                    title: "数据清洗",
                    itemLIst: [
                        "封箱法",
                        "回归法",
                        "聚类法"
                    ],
                    img: {
                        cover: sw_cover2,
                        bg: sw_bg
                    }
                },
                {
                    title: "自然语言分析",
                    itemLIst: [
                        "词法分析",
                        "语义分析",
                        "篇章分析",
                        "文本分析"
                    ],
                    img: {
                        cover: sw_cover1,
                        bg: sw_bg
                    }
                },
                {
                    title: "数据分析",
                    itemLIst: [
                        "神经网络",
                        "相关性分析",
                        "组合分析",
                        "选择模型"
                    ],
                    img: {
                        cover: sw_cover2,
                        bg: sw_bg
                    }
                },
            ],
        },
        {// 六边形图文组件
            expand: "expand_5",
            status: true,
            blockTitle: {
                line: true,
                content: '业务分析与敏捷开发',
                wide: false,
            },
            // blockTitle: [
            //     "业务分析与敏捷开发",
            //     true
            // ],
            chain_data: {
                firstData: {
                    title: "成本低",
                    summary: "降低学习成本"
                },
                secondData: {
                    title: "效率高",
                    summary: "提升交付效率和协作效率"
                },
                thirdData: {
                    img: chain_png
                },
                fourthData: {
                    title: "市场广",
                    summary: "扩大应用开发劳动力"
                },
                fifthData: {
                    title: "资源多",
                    summary: "缓解低价值需求资源困境"
                },
                sixthData: {
                    title: "兼容好",
                    summary: "与协作系统、组织架构无缝集成"
                },
            },
        },
        {// 解决方案
            expand: "expand_6",
            status: true,
            blockTitle: {
                line: true,
                content: '不同的行业 共同的选择',
                wide: false,
            },
            card_data: [
                {
                    title: "智能制造",
                    summary: "Intelligent manufacturing",
                    bg: card_default_1
                },
                {
                    title: "幼教早教",
                    summary: "Preschool education early education",
                    bg: card_default_2
                },
                {
                    title: "精细化工",
                    summary: "Fine chemical industry",
                    bg: card_default_3
                }
            ],
        },
        {// 标准图文组件
            expand: "expand_7",
            status: true,
            template_background: about,
            title: "上海宜氪数据科技有限公司",
            summary: "IKE是一家数据智能技术企业，拥有完整的大数据和自然语言AI智能产品线，基于行业知识及数据中台建设经验，并于数字云、云计算、云安全等项目整合经验；提供面向企业数字化转型的行业解决方案，构建了企业级和SaaS服务两大业务体系，涉及数字医疗、新零售、智能制造和房地产等多个领域。致力于用数据智能促进企业智能化转型。",
            background_image: ""
        },
        {// 招聘信息组件
            expand: "expand_8",
            status: true,
            blockTitle: {
                line: true,
                content: '期待优秀的你 加入我们',
                wide: false,
            },
            card_data: [
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/134972928.html?s=gsxq_zwlb_gsxqlb&t=17",
                    title: "数据分析工程师",
                    summary: [
                        "运用数据挖掘/统计学习的理论和方法，深入挖掘和分析用户行为、业务数据",
                        "负责数据清洗相关策略工作，包括对虚假、重复、低质等数据的发现及处理",
                        "负责数据探索、数据特征分析、数据挖掘的验证及建模工作",
                        "编写数据分析报告"
                    ],
                    detail: [
                        "本科及以上学历，统计学、计算机科学等相关专业背景",
                        "两年以上汽车等制造业数据分析工作经验考虑",
                        "了解数据分析的主要流程，关联分析、分类预测、协同过滤、聚类分析、回归分析、时间序列分析等常用分析方法",
                        "熟悉Python语言的编写，能够用python数据建模和相关算法能力",
                        "熟悉目前流行数据库，熟练使用SQL语句等",
                        "具备责任心和良好的团队协作精神，乐于沟通交流和分享，充满激情，乐于接受挑战"
                    ]
                },
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/136524178.html?s=sou_sou_soulb&t=0_0",
                    title: "Python后端开发工程师",
                    summary: [
                        "本科及以上学历，计算机相关专业",
                        "具有良好的数据结构和算法基础，具备扎实的编程能力",
                        "3年以上python web框架(Django或Flask)开发经验，掌握RESTful API的开发思想，热爱编程、具有良好的代码风格，了解Python的高级特性",
                        "熟悉常用数据库，精通MySQL，Redis等，熟悉数据库优化，了解非关系型数据库例如MongoDB",
                        "具有构建分布式任务系统经验（Celery/RabbitMQ/Redis）优先",
                        "熟悉Linux操作系统，了解docker，能够进行日常服务的测试部署",
                        "了解前端相关技术，React、HTML、JS、CSS等",
                        "良好的自学能力、独立解决问题的能力及沟通与团队协作能力",
                        "独立思考，有产品意识，能提出系统改善和产品优化者优先"
                    ],
                    detail: []
                },
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/129315562.html?s=gsxq_zwlb_gsxqlb&t=17",
                    title: "自然语言处理工程师",
                    summary: [
                        "利用自然语言处理技术，从非结构化的知识中提取实体和关系，构建知识图谱",
                        "利用自然语言处理技术，从文章、商品描述、评价等信息中进行NER、关系抽取等，优化和完善团队的核心知识图谱",
                        "基于知识图谱和自然语言处理技术，构建语料库，实现自动摘要，情感分析，意图识别，舆情分析等功能"
                    ],
                    detail: []
                },
            ],
        },
        {// feedback的数据
            expand: "expand_9",
            status: true,
            blockTitle: {
                line: true,
                content: '我们可以为您提供此服务',
                wide: true,
            },
        }
    ],
}

// 数据清洗
export const data_clear = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "数据清洗",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "数据清洗",
                summary: "大数据离不开数据分析，数据分析离不开数据，海量的数据中有很多是我们我们需要的数据，也有很多我们不需要的数据。正如世界上没有完全纯净的东西，数据也会存在杂质，这就需要我们对数据进行清洗才能保证数据的可靠性。",
                bg: banner5,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: '数据清洗'
        },
        {// 数据清洗界面数据
            expand: "expand_11",
            status: true,
            img_text: {
                feedback: true,
                shadow: true,
                context: [
                    {
                        id: "1",
                        img: content_cover_1,
                        title: "分箱法",
                        sumary:
                            "分箱法是一个经常使用到方法，所谓的分箱法，就是将需要处理的数据根据一定的规则放进箱子里，然后进行测试每一个箱子里的数据，并根据数据中的各个箱子的实际情况进行采取方法处理数据。看到这里很多朋友只是稍微明白了，但是并不知道怎么分箱。如何分箱呢？我们可以按照记录的行数进行分箱，使得每箱有一个相同的记录数。或者我们把每个箱的区间范围设置一个常数，这样我们就能够根据区间的范围进行分箱。其实我们也可以自定义区间进行分箱。这三种方式都是可以的。分好箱号，我们可以求每一个箱的平均值，中位数、或者使用极值来绘制折线图，一般来说，折线图的宽度越大，光滑程度也就越明显。",
                    },
                    {
                        id: "2",
                        img: content_cover_2,
                        title: "回归法",
                        sumary:
                            "回归法就是利用了函数的数据进行绘制图像，然后对图像进行光滑处理。回归法有两种，一种是单线性回归，一种是多线性回归。单线性回归就是找出两个属性的最佳直线，能够从一个属性预测另一个属性。多线性回归就是找到很多个属性，从而将数据拟合到一个多维面，这样就能够消除噪声。",
                    },
                    {
                        id: "3",
                        img: content_cover_3,
                        title: "聚类法",
                        sumary:
                            "聚类法的工作流程是比较简单的，但是操作起来确实复杂的，所谓聚类法就是将抽象的对象进行集合分组，成为不同的集合，找到在集合意外的孤点，这些孤点就是噪声。这样就能够直接发现噪点，然后进行清除即可。",
                    },
                ]
            }
        },
    ],
}

// 语义分析
export const semantic_analysis = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "语义分析",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "语义分析",
                summary: "分析句子各个语言单位之间的语义关联，并将语义关联以依存结构呈现。",
                bg: banner5,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: '语义分析'
        },
        {// 语义分析界面数据
            expand: "expand_11",
            status: true,
            img_text: {
                feedback: false,
                shadow: true,
                context: [
                    {
                        id: "1",
                        img: content_cover_4,
                        title: "分箱法",
                        sumary:
                            "分箱法是一个经常使用到方法，所谓的分箱法，就是将需要处理的数据根据一定的规则放进箱子里，然后进行测试每一个箱子里的数据，并根据数据中的各个箱子的实际情况进行采取方法处理数据。看到这里很多朋友只是稍微明白了，但是并不知道怎么分箱。如何分箱呢？我们可以按照记录的行数进行分箱，使得每箱有一个相同的记录数。或者我们把每个箱的区间范围设置一个常数，这样我们就能够根据区间的范围进行分箱。其实我们也可以自定义区间进行分箱。这三种方式都是可以的。分好箱号，我们可以求每一个箱的平均值，中位数、或者使用极值来绘制折线图，一般来说，折线图的宽度越大，光滑程度也就越明显。",
                    },
                    {
                        id: "2",
                        img: content_cover_5,
                        title: "回归法",
                        sumary:
                            "回归法就是利用了函数的数据进行绘制图像，然后对图像进行光滑处理。回归法有两种，一种是单线性回归，一种是多线性回归。单线性回归就是找出两个属性的最佳直线，能够从一个属性预测另一个属性。多线性回归就是找到很多个属性，从而将数据拟合到一个多维面，这样就能够消除噪声。",
                    },
                ]
            }
        },
    ],
}

// 数据挖掘分析
export const data_analysis = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "数据挖掘分析",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "数据挖掘分析",
                summary: "利用最先进的数据挖掘技术，为企业捕捉全网信息，基于海量数据和精准算法，就网站流量走势、用户行为、产品销量等进行准确预测。",
                bg: banner5,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: '数据挖掘分析'
        },
    ],
}

// 数据科学(数据采集)
export const dataScience = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "数据采集",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "数据采集",
                summary: "大数据离不开数据分析，数据分析离不开数据，海量的数据中有很多是我们我们需要的数据，也有很多我们不需要的数据。正如世界上没有完全纯净的东西，数据也会存在杂质，这就需要我们对数据进行清洗才能保证数据的可靠性。",
                bg: banner2,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 数据科学(数据采集)界面数据
            expand: "expand_10",
            status: true,
            blockTitle:
            {// 标题
                line: false,
                content: '数据采集'
            },
            card: [
                {
                    cover: card_default_4,
                    title: "数据采集",
                    summary: "是一种按照一定的规则，自动地抓取万维网信息的程序或者脚本。另外一些不常使用的名字还有蚂蚁、自动索引、模拟程序或者蠕虫。",
                    url: "/data_science/data_collection/resultlist"
                },
                {
                    cover: card_default_5,
                    title: "IoT",
                    summary: "物联网通过温湿度传感器、气体传感器、视频传感器等外部硬件设备与系统进行通信，将传感器监测到的数据传至系统中进行采集使用。",
                    url: "/data_science/iot"
                },
            ],
        },
    ],
}

// 数据采集结果
export const dataScienceList = {
    title: "数据采集结果",
    banner: {
        title: "数据采集",
        summary: "全网海量数据资源向你敞开，探索数据的无限可能",
        bg: banner3,
    },
    cat: [
        {
            name: "财经",
            url: "/cat1/"
        },
        {
            name: "汽车之家",
            url: "/cat1/"
        }
    ],
    dataItem: [
        {
            id: 1,
            title: "HJ 1128-2020 核动力厂核事故环境应急监测技术规范",
            time: "2022-01-17",
            url: "/data_science/data_collection/resultlist/detail/"
        },
        {
            id: 2,
            title: "HJ 1128-2020 核动力厂核事故环境应急监测技术规范",
            time: "2022-01-17",
            url: "/data_science/data_collection/resultlist/detail/"
        },
        {
            id: 3,
            title: "HJ 1128-2020 核动力厂核事故环境应急监测技术规范",
            time: "2022-01-17",
            url: "/data_science/data_collection/resultlist/detail/"
        },
        {
            id: 4,
            title: "HJ 1128-2020 核动力厂核事故环境应急监测技术规范",
            time: "2022-01-17",
            url: "/data_science/data_collection/resultlist/detail/"
        },
        {
            id: 5,
            title: "HJ 1128-2020 核动力厂核事故环境应急监测技术规范",
            time: "2022-01-17",
            url: "/data_science/data_collection/resultlist/detail/"
        },
        {
            id: 6,
            title: "HJ 1128-2020 核动力厂核事故环境应急监测技术规范",
            time: "2022-01-17",
            url: "/data_science/data_collection/resultlist/detail/"
        },
    ]
}

// 数据采集结果详情
export const dataScienceListDetail = {
    title: "数据采集结果详情",
    banner: {
        title: "数据采集",
        summary: "全网海量数据资源向你敞开，探索数据的无限可能",
        bg: banner3,
    },
    file_data: {
        Title: "HJ 1128-2020 核动力厂核事故环境应急监测技术规范",
        FileType: "Excel 格式",
        FileSize: "236.00KB",
        RecommendTheWebsite: {
            url: "www.ike-data.com",
            title: "www.ike-data.com"
        },
        Authorization: "免费下载"
    }
}

// IoT（物联网）
export const iot = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "IoT（物联网）",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "IoT（物联网）",
                summary: "共同营造健康的互联网生态环境",
                bg: banner3,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// feedback的数据
            expand: "expand_9",
            status: true,
            blockTitle:
            {// 标题
                line: false,
                content: '我们可以为您提供此服务'
            },
        }
    ],
}

// 敏捷开发
export const agile_development = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "敏捷开发",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "低代码+AI如何为企业数字化修路",
                summary: "低门槛高效率，行业软件客户化开发的新选择",
                bg: banner5,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: '低代码为何而生'
        },
        {// 数据清洗界面数据
            expand: "expand_11",
            status: true,
            img_text: {
                feedback: false,
                shadow: true,
                context: [
                    {
                        id: "1",
                        img: ike_data_02,
                        title: "",
                        sumary: "低代码开发平台（LCDP）本身也是一种软件，它为开发者提供了一个创建应用软件的开发环境。与传统代码IDE不同的是，低代码开发平台提供的是更高维和易用的可视化IDE。大多数情况下，开发者并不需要使用传统的手写代码方式进行编程，而是可以通过图形化拖拽、参数配置等更高效的方式完成开发工作。简单来说，低代码开发面向每个人，让人们不管是否懂编程、不管是何职业，都能快速的设计出一个管理应用。随着高级编程语言不断发展成熟，以及国内外计算机人才的培养规模逐渐扩大，2010-2015年称得上是传统软件和SaaS软件兴起的时代，编程人员发现：软件的功能大同小异，重复度很高，导致很大部分的软件开发成本都浪费在重复的功能编程上。随着这些概念的日渐成熟，国外软件厂商就陆续发布出低代码或零代码开发平台，探索并证明了这类产品成功的可能性。基于外国的成功初探，中国市场也掀起了“低代码/零代码”的热潮，并在近两年逐步形成完整的产品生态体系。",
                    },
                ]
            }
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: '低代码+RPA+AI'
        },
        {// 大尺寸图块
            expand: "expand_15",
            status: true,
            img: ike_data_03
        },
    ],
}

// 关于宜氪
export const about_us = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "关于我们",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "关于我们",
                summary: "About us",
                bg: banner7,
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 数据清洗界面数据
            expand: "expand_11",
            status: true,
            img_text: {
                feedback: false,
                shadow: false,
                context: [
                    {
                        id: "1",
                        img: ike_data_04,
                        title: "公司介绍",
                        sumary: "IKE是一家数据智能技术企业，拥有完整的大数据和自然语言AI智能产品线，基于行业知识及数据中台建设经验，并于数字云、云计算、云安全等项目整合经验；提供面向企业数字化转型的行业解决方案，构建了企业级和SaaS服务两大业务体系，涉及数字医疗、新零售、智能制造和房地产等多个领域。致力于用数据智能促进企业智能化转型。-2015年称得上是传统软件和SaaS软件兴起的时代，编程人员发现：软件的功能大同小异，重复度很高，导致很大部分的软件开发成本都浪费在重复的功能编程上。随着这些概念的日渐成熟，国外软件厂商就陆续发布出低代码或零代码开发平台，探索并证明了这类产品成功的可能性。基于外国的成功初探，中国市场也掀起了“低代码/零代码”的热潮，并在近两年逐步形成完整的产品生态体系。",
                    },
                ]
            }
        },
        {// 大尺寸图块
            expand: "expand_15",
            status: true,
            img: ike_data_05,
            blockTitle:
            {// 标题
                expand: "expand_18",
                status: true,
                line: false,
                content: '核心价值观'
            },
        },
        {// 标准图文组件
            expand: "expand_7",
            status: true,
            template_background: about,
            title: "我们的战略思想",
            summary: "使命：聚焦大数据时代客户的需求和挑战，提供自主研发的一流软件和服务，持续为客户创造最大价值 核心价值观：公司的核心价值观是我们的信念，是我们前进的内在动力，也是对用户及员工的承诺 愿景:让信息创造价值",
            background_image: banner8
        },
    ],
}

// 词法分析
export const lexical_analysis = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "词法分析",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "词法分析",
                summary: "基于大数据和用户行为，提供分词、词性标注、命名实体识别，定位基本语言元素，全面支撑机器对基础文本的理解与分析。",
                bg: banner6,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: '功能介绍'
        },
        {// 列表图文
            expand: "expand_13",
            status: true,
            instructions: [
                {
                    title: "分词",
                    summary: "指的是将汉字序列切分成词序列。汉语中，词是承载语义的最基本的单元。分词是信息检索、文本分类、情感分析等多项中文自然语言处理任务的基础",
                },
                {
                    title: "词性标注",
                    summary: "是指为自然语言文本中的每个词汇赋予一个词性的过程",
                },
                {
                    title: "命名实体识别",
                    summary: "是在句子的词序列中定位并识别人名、地名、机构名等实体",
                }
            ],
            cover: ike_data_01,
        },
        {// demo展示组件
            expand: "expand_14",
            status: true,
            active_model: false,
            post_to: [
                {
                    req_path: '/demo/lexical_analysis/',
                    model: [
                        'text',
                        'document'
                    ]
                }
            ]
        },
    ]
}

// 篇章分析
export const discourse_analysis = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "篇章分析",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "篇章分析",
                summary: "语义角色标注 (Semantic Role Labeling, SRL) 是一种浅层的语义分析技术，标注句子中某些短语为给定谓词的论元 (语义角色) ，如施事者、受事者、经验者、时间和地点等。",
                bg: banner6,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        // {// 标题
        //     expand: "expand_18",
        //     status: true,
        //     line: false,
        //     content: '功能介绍'
        // },
        {// demo展示组件
            expand: "expand_14",
            status: true,
            active_model: true,
            blockTitle: {// 标题
                expand: "expand_18",
                status: true,
                line: false,
                content: '功能介绍'
            },
            post_to: [
                {
                    icon: "icon-fl1",
                    title: "文本分类",
                    req_path: "/demo/text_classification/",
                    describe: {
                        title: "文本分类",
                        summary: "自动对汉语文本中的拼写、语法、标点、敏感词等多种问题进行纠错校对，提示错误位置并返回修改建议。"
                    },
                    model: [
                        'text'
                    ]
                },
                {
                    icon: "icon-tq",
                    title: "关键词提取",
                    req_path: "/demo/keyword_extraction/",
                    describe: {
                        title: "关键词提取",
                        summary: "自动对汉语文本中的拼写、语法、标点、敏感词等多种问题进行纠错校对，提示错误位置并返回修改建议。"
                    },
                    model: [
                        'text'
                    ]
                },
                {
                    icon: "icon-emo",
                    title: "情感分析",
                    req_path: "/demo/sentiment_analysis/",
                    describe: {
                        title: "情感分析",
                        summary: "自动对汉语文本中的拼写、语法、标点、敏感词等多种问题进行纠错校对，提示错误位置并返回修改建议。"
                    },
                    model: [
                        'text'
                    ]
                },
                {
                    icon: "icon-cy",
                    title: "文章摘要",
                    req_path: "/demo/abstract_of_the_article/",
                    describe: {
                        title: "文章摘要",
                        summary: "自动对汉语文本中的拼写、语法、标点、敏感词等多种问题进行纠错校对，提示错误位置并返回修改建议。"
                    },
                    model: [
                        'text'
                    ]
                },
            ],
        },
    ]
}

// 文本分析
export const text_analysis = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "文本分析",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "文本分析",
                summary: "语义角色标注 (Semantic Role Labeling, SRL) 是一种浅层的语义分析技术，标注句子中某些短语为给定谓词的论元 (语义角色) ，如施事者、受事者、经验者、时间和地点等。",
                bg: banner6,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: '功能介绍'
        },
        {// demo展示组件
            expand: "expand_14",
            status: true,
            active_model: true,
            post_to: [
                {
                    icon: "icon-xsd",
                    title: "文本相似度",
                    req_path: "/",
                    describe: {
                        title: "文本相似度",
                        summary: "自动对汉语文本中的拼写、语法、标点、敏感词等多种问题进行纠错校对，提示错误位置并返回修改建议。"
                    },
                    model: [
                        'text'
                    ]
                },
                {
                    icon: "icon-xc",
                    title: "新词发现",
                    req_path: "/",
                    describe: {
                        title: "新词发现",
                        summary: "自动对汉语文本中的拼写、语法、标点、敏感词等多种问题进行纠错校对，提示错误位置并返回修改建议。"
                    },
                    model: [
                        'text'
                    ]
                },
                {
                    icon: "icon-jc",
                    title: "文本纠错",
                    req_path: "/",
                    describe: {
                        title: "文本纠错",
                        summary: "自动对汉语文本中的拼写、语法、标点、敏感词等多种问题进行纠错校对，提示错误位置并返回修改建议。"
                    },
                    model: [
                        'text'
                    ]
                },
                {
                    icon: "icon-cz",
                    title: "词组相似度",
                    req_path: "/",
                    describe: {
                        title: "词组相似度",
                        summary: "自动对汉语文本中的拼写、语法、标点、敏感词等多种问题进行纠错校对，提示错误位置并返回修改建议。"
                    },
                    model: [
                        'text'
                    ]
                },
            ]
        },
    ]
}

// 加入我们
export const join_us = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "加入宜氪",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "加入我们",
                summary: "Join us",
                bg: banner9,
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: '期待优秀的你 加入我们'
        },
        {// 招聘信息组件
            expand: "expand_8",
            status: true,
            card_data: [
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/134972928.html?s=gsxq_zwlb_gsxqlb&t=17",
                    title: "数据分析工程师",
                    summary: [
                        "运用数据挖掘/统计学习的理论和方法，深入挖掘和分析用户行为、业务数据",
                        "负责数据清洗相关策略工作，包括对虚假、重复、低质等数据的发现及处理",
                        "负责数据探索、数据特征分析、数据挖掘的验证及建模工作",
                        "编写数据分析报告"
                    ],
                    detail: [
                        "本科及以上学历，统计学、计算机科学等相关专业背景",
                        "两年以上汽车等制造业数据分析工作经验考虑",
                        "了解数据分析的主要流程，关联分析、分类预测、协同过滤、聚类分析、回归分析、时间序列分析等常用分析方法",
                        "熟悉Python语言的编写，能够用python数据建模和相关算法能力",
                        "熟悉目前流行数据库，熟练使用SQL语句等",
                        "具备责任心和良好的团队协作精神，乐于沟通交流和分享，充满激情，乐于接受挑战"
                    ]
                },
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/136524178.html?s=sou_sou_soulb&t=0_0",
                    title: "Python后端开发工程师",
                    summary: [
                        "本科及以上学历，计算机相关专业",
                        "具有良好的数据结构和算法基础，具备扎实的编程能力",
                        "3年以上python web框架(Django或Flask)开发经验，掌握RESTful API的开发思想，热爱编程、具有良好的代码风格，了解Python的高级特性",
                        "熟悉常用数据库，精通MySQL，Redis等，熟悉数据库优化，了解非关系型数据库例如MongoDB",
                        "具有构建分布式任务系统经验（Celery/RabbitMQ/Redis）优先",
                        "熟悉Linux操作系统，了解docker，能够进行日常服务的测试部署",
                        "了解前端相关技术，React、HTML、JS、CSS等",
                        "良好的自学能力、独立解决问题的能力及沟通与团队协作能力",
                        "独立思考，有产品意识，能提出系统改善和产品优化者优先"
                    ],
                    detail: []
                },
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/129315562.html?s=gsxq_zwlb_gsxqlb&t=17",
                    title: "自然语言处理工程师",
                    summary: [
                        "利用自然语言处理技术，从非结构化的知识中提取实体和关系，构建知识图谱",
                        "利用自然语言处理技术，从文章、商品描述、评价等信息中进行NER、关系抽取等，优化和完善团队的核心知识图谱",
                        "基于知识图谱和自然语言处理技术，构建语料库，实现自动摘要，情感分析，意图识别，舆情分析等功能"
                    ],
                    detail: []
                },
                // {
                //     url: "https://jobs.51job.com/shanghai-pdxq/131962084.html?s=gsxq_zwlb_gsxqlb&t=17",
                //     title: "数据营销客户经理",
                //     summary: [
                //         "负责公司数据业务、云架构售前宣讲及客户答疑",
                //         "参与客户 / 合作伙伴数据可视化 / 定制化开发项目",
                //         "参与客户 / 合作伙伴数据上云、云迁移、云桌面等项目"
                //     ],
                //     detail: []
                // },
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/133148000.html?s=gsxq_zwlb_gsxqlb&t=17",
                    title: "数据库工程师（DBA）",
                    summary: [
                        "数据库、数据仓库仓支持",
                        "对数据分析业务有关的内容进行数据库开发支持",
                        "负责DB相关运行环境出现问题排查、故障处理"
                    ],
                    detail: []
                },
            ],
        },
    ]
}

// 解决方案
export const solution = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "解决方案",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "解决方案",
                summary: "Solution",
                bg: banner10,
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 解决方案
            expand: "expand_6",
            status: true,
            blockTitle: {// 标题
                status: true,
                line: false,
                content: '服务领域'
            },
            card_data: [
                {
                    title: "智能制造",
                    summary: "Intelligent manufacturing",
                    bg: card_default_1,
                    url: "/solution/intelligent_manufacturing"
                },
                {
                    title: "幼教早教",
                    summary: "Preschool education early education",
                    bg: card_default_2,
                    url: "/solution/preschool_education_industry"
                },
                {
                    title: "精细化工",
                    summary: "Fine chemical industry",
                    bg: card_default_3,
                    url: "/solution/fine_chemical_industry"
                },
                {
                    title: "其他行业",
                    summary: "Other industries",
                    bg: card_default_3,
                    url: "/solution/other_industries"
                }
            ],
        },
    ]
}

// 解决方案详情--制造业
export const IntelligentManufacturing = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "智能制造",
    banner: {
        title: "智能制造",
        summary: "Intelligent manufacturing",
        bg: banner10,
    },
    block_1: {
        title: '行业背景(智能制造)',
        detail: '新一代信息技术与先进制造技术的深度融合，是我国从制造大国向制造强国转变的核心路径，制造强国战略及“1+X”政策体系相继出台，构建完整的智能制造发展政策支撑体系。在宏观经济下行压力下，智能制造及信息化相关技术的发展持续获得国家政策扶持，社会资本也逐渐流向智能制造相关产业。',
        img: intelligent
    },
    block_2: {
        title_1: "智能识别",
        title_2: "实时追踪",
        title_3: "库存管理",
        detail: "通过洞察城市人口消费、数量、质量、流动以及商户资源等数据的变化趋势，结合城市汽车市场发展趋势和购车人群流向，充分发挥宜氪大数据在汽车行业的数据优势，帮助汽车企业挖掘新市场机遇上提供数据服务。"
    },
    block_3: {
        cover: ike_data_06,
        summary: "某汽车集团对于4S门店、中转仓库的库存、流通情况的追踪、审计。",
        detail: [
            {
                title: "需求痛点",
                detail: "某汽车集团业务系统众多，财务部门统一数据协调获取、实施获取难度较大，希望自己能自主进行车辆情况智能点数。"
            },
            {
                title: "解决方案",
                detail: "通过Powe Apps、Power BI、AI车辆识别等模块的快速组合集成，实现了车库库存的实施实时盘点。大大减低的开发成本，把握用户需求快速项目落地。"
            },
        ]
    },
    blockTitle_1: {// 标题
        expand: "expand_18",
        status: true,
        line: false,
        content: '解决方案'
    },
    blockTitle_2: {// 标题
        expand: "expand_18",
        status: true,
        line: false,
        content: '行业案例'
    },
}

// 解决方案详情--幼教行业
export const preschoolEducationIndustry = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "幼教行业",
    banner: {
        title: "幼教行业",
        summary: "Preschool education industry",
        bg: banner10,
    },
    block_1: {
        title: '行业背景(幼教)',
        detail: '教培行业经历了一场巨变，大量的教培行业被迫转型，其中教培行业转型到幼育（三岁以下）不失为一条可行的出路。十九大报告中提出的七项民生保障中，“幼有所育”排在首位。习近平总书记多次对做好托幼工作提出明确要求，“要解决好婴幼儿照护和儿童早期教育服务难以满足需求问题”，同时唯有把幼儿管理好，才能解决好好中产阶级的后顾之忧，对于出生率的提升有重要意义。市场规模方面， 2021 年托育行业市场规模约 980 亿元。人口方面，我国托育适龄人口基础大，2021 年适龄人口约 4472 万人。平均支出方面，我国人均教育文化娱乐支出过往 5 年也保持着年复合 9.77%的增速。',
        img: preschool
    },
    block_2: {
        title_1: "教务管理",
        title_2: "财务管理",
        title_3: "经营管理",
        detail: "宜氪数据通过低代码开发平台助其迅速迭代原有ERP/CRM系统、财务模块。通过高效信息收集系统，并辅之基于统计学数据分析方法，为幼儿做出个性化的后续服务课程，挖掘二次销售机会，同时也助力增加学校和幼儿家长之间的粘性。"
    },
    block_3: {
        cover: ike_data_06,
        summary: "某幼教行业对于园内各个部门的管理，减少冗杂的堆积纸质化管理、追踪、审计。从线上到线下打造一体化教育管理体系。",
        detail: [
            {
                title: "需求痛点",
                detail: "相较之K12行业，托育行业信息化基础比较薄弱，数字化程度不高，当需求量激增的时候，信息化/数字化薄弱限制了其进一步发展。"
            },
            {
                title: "解决方案",
                detail: "宜氪数据萌爱幼教系统一切为了未来，助力打造快乐、健康、安全的教育环境。通过IKE萌爱幼教平台助其迅速迭代原有ERP/CRM系统、财务模块。通过高效信息收集系统，并辅之基于统计学数据分析方法，为幼儿做出个性化的后续服务课程，挖掘二次销售机会，同时也助力增加学校和幼儿家长之间的粘性。"
            },
        ]
    },
    blockTitle_1: {// 标题
        expand: "expand_18",
        status: true,
        line: false,
        content: '解决方案'
    },
    blockTitle_2: {// 标题
        expand: "expand_18",
        status: true,
        line: false,
        content: '行业案例'
    },
}

// 解决方案详情--精细化工
export const fineChemicalIndustry = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "精细化工",
    banner: {
        title: "精细化工",
        summary: "Fine Chemical Industry",
        bg: banner10,
    },
    block_1: {
        title: '行业背景(精细化工)',
        detail: '贸易战不确定性较高背景下，从产业转移看中国化工行业的发展。我们认为在贸易战背景下，面板及半导体行业上游材料的进口替代有望加速，而化纤行业及农药行业长期来看有产业转出风险，但是中短期, 影响不大。面板行业：未来随着大陆 LCD 产能的持续加速扩张，上游材料企业有望持续受益。半导体行业：随着国内晶圆厂不断建成、现地化配套需求不断增加，国内优秀企业在大基金及科创板等支持下进, 口替代份额提升，有望在 2020-2025 年实现收入规模大幅增长。化纤行业：市场可能担心下游纺织业转出带动化纤行业转出。我们认为国外在管理成本、一体化优势、销售渠道等方面均处于劣势，化纤纺丝仍难转出。农药行业：中国制造比较优势明显，承接了跨国公司农药产能的转移，而印度尚不具备承接大量产能转移能力。',
        img: chemical
    },
    block_2: {
        title_1: "平台数据分析",
        title_2: "产品供需管理",
        title_3: "仓储物流管理",
        detail: "宜氪数据为化工贸易企业量身打造数据分析服务。通过物流运输、生产制造、供需管理等模块的数据采集，提供企业数据治理分析解决方案服务，梳理业务逻辑，设计治理模型，助力企业运营效率的改善优化。"
    },
    block_3: {
        cover: ike_data_06,
        summary: "某化工贸易企业数字化建设及数据分析",
        detail: [
            {
                title: "需求痛点",
                detail: "各系统信息标准有不统一现象，或者没有对应关系；系统之间的数据规范不同，造成还需要人工干预或补入信息；较多的非规范流程穿插在整体流程中数据分析：以上痛点造成了数据分析及可视化话的难度大大增加。"
            },
            {
                title: "解决方案",
                detail: "从LMS->SAP->Salesforce->Metadata各个系统的数据调研；从线上到线下、系统内到生产现场，进行整体环节的梳理建立优化分析模型，找出物流瓶颈，为用户建立数据清洗治理环境（数据库）给出优化建议；"
            },
        ]
    },
    blockTitle_1: {// 标题
        expand: "expand_18",
        status: true,
        line: false,
        content: '解决方案'
    },
    blockTitle_2: {// 标题
        expand: "expand_18",
        status: true,
        line: false,
        content: '行业案例'
    },
}

// 通用模板测试数据
export const CommonTemplate_data = {
    layout: "", // fluid
    subPage: false, // 判断是否为子页面
    title: "通用测试页面",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "宜氪数据一站式数据分析平台",
                summary: "打通数据治理全流程，快速满足政企用户各类不同的数据治理场景",
                bg: banner1,
                img: banner_h_r,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// banner横幅文字
            expand: "expand_3",
            status: true,// banner横幅文字, 值为true时,才会展示
            bannerText: "宜氪数据一家为企业推动数字化转型的公司，用数学模型解决实际问题"
        },
        {// 首页轮播图
            expand: "expand_4",
            status: true,
            blockTitle: [
                "Cheerio-自然语言处理及分析",
                true
            ],
            sw_data: [
                {
                    title: "数据采集",
                    itemLIst: [
                        "数据采集",
                        "IoT"
                    ],
                    img: {
                        cover: sw_cover1,
                        bg: sw_bg
                    }
                },
                {
                    title: "数据清洗",
                    itemLIst: [
                        "封箱法",
                        "回归法",
                        "聚类法"
                    ],
                    img: {
                        cover: sw_cover2,
                        bg: sw_bg
                    }
                },
                {
                    title: "自然语言分析",
                    itemLIst: [
                        "词法分析",
                        "语义分析",
                        "篇章分析",
                        "文本分析"
                    ],
                    img: {
                        cover: sw_cover1,
                        bg: sw_bg
                    }
                },
                {
                    title: "数据分析",
                    itemLIst: [
                        "神经网络",
                        "相关性分析",
                        "组合分析",
                        "选择模型"
                    ],
                    img: {
                        cover: sw_cover2,
                        bg: sw_bg
                    }
                },
            ],
        },
        {// 六边形图文组件
            expand: "expand_5",
            status: true,
            blockTitle: [
                "业务分析与敏捷开发",
                true
            ],
            chain_data: {
                firstData: {
                    title: "成本低",
                    summary: "降低学习成本"
                },
                secondData: {
                    title: "效率高",
                    summary: "提升交付效率和协作效率"
                },
                thirdData: {
                    img: chain_png
                },
                fourthData: {
                    title: "市场广",
                    summary: "扩大应用开发劳动力"
                },
                fifthData: {
                    title: "资源多",
                    summary: "缓解低价值需求资源困境"
                },
                sixthData: {
                    title: "兼容好",
                    summary: "与协作系统、组织架构无缝集成"
                },
            },
        },
        {// 解决方案
            expand: "expand_6",
            status: true,
            blockTitle: [
                "不同的行业 共同的选择",
                true
            ],
            card_data: [
                {
                    title: "智能制造",
                    summary: "Intelligent manufacturing",
                    bg: card_default_1
                },
                {
                    title: "幼教早教",
                    summary: "Preschool education early education",
                    bg: card_default_2
                },
                {
                    title: "精细化工",
                    summary: "Fine chemical industry",
                    bg: card_default_3
                }
            ],
        },
        {// 标准图文组件
            expand: "expand_7",
            status: true,
            template_background: about,
            title: "上海宜氪数据科技有限公司",
            summary: "IKE是一家数据智能技术企业，拥有完整的大数据和自然语言AI智能产品线，基于行业知识及数据中台建设经验，并于数字云、云计算、云安全等项目整合经验；提供面向企业数字化转型的行业解决方案，构建了企业级和SaaS服务两大业务体系，涉及数字医疗、新零售、智能制造和房地产等多个领域。致力于用数据智能促进企业智能化转型。",
            background_image: ""
        },
        {// 招聘信息组件
            expand: "expand_8",
            status: true,
            blockTitle: [
                "期待优秀的你 加入我们",
                true
            ],
            card_data: [
                {
                    url: "",
                    title: "数据分析工程师",
                    summary: [
                        "运用数据挖掘/统计学习的理论和方法，深入挖掘和分析用户行为、业务数据",
                        "负责数据清洗相关策略工作，包括对虚假、重复、低质等数据的发现及处理",
                        "负责数据探索、数据特征分析、数据挖掘的验证及建模工作",
                        "编写数据分析报告"
                    ]
                },
                {
                    url: "",
                    title: "Python后端开发工程师",
                    summary: [
                        "本科及以上学历，计算机相关专业",
                        "具有良好的数据结构和算法基础，具备扎实的编程能力",
                        "3年以上python web框架(Django或Flask)开发经验，掌握RESTful API的开发思想，热爱编程、具有良好的代码风格，了解Python的高级特性",
                        "熟悉常用数据库，精通MySQL，Redis等，熟悉数据库优化，了解非关系型数据库例如MongoDB",
                        "具有构建分布式任务系统经验（Celery/RabbitMQ/Redis）优先",
                        "熟悉Linux操作系统，了解docker，能够进行日常服务的测试部署",
                        "了解前端相关技术，React、HTML、JS、CSS等",
                        "良好的自学能力、独立解决问题的能力及沟通与团队协作能力",
                        "独立思考，有产品意识，能提出系统改善和产品优化者优先"
                    ]
                },
                {
                    url: "",
                    title: "自然语言处理工程师",
                    summary: [
                        "利用自然语言处理技术，从非结构化的知识中提取实体和关系，构建知识图谱",
                        "利用自然语言处理技术，从文章、商品描述、评价等信息中进行NER、关系抽取等，优化和完善团队的核心知识图谱",
                        "基于知识图谱和自然语言处理技术，构建语料库，实现自动摘要，情感分析，意图识别，舆情分析等功能"
                    ]
                },
            ],
        },
        {// feedback的数据
            expand: "expand_9",
            status: true,
            blockTitle: [
                "我们可以为您提供此服务",
                true
            ],
        },
        {// 数据科学(数据采集)界面数据
            expand: "expand_10",
            status: true,
            blockTitle: [
                "数据采集",
                true
            ],
            card: [
                {
                    cover: card_default_4,
                    title: "数据采集",
                    summary: "是一种按照一定的规则，自动地抓取万维网信息的程序或者脚本。另外一些不常使用的名字还有蚂蚁、自动索引、模拟程序或者蠕虫。",
                    url: "/data_science/data_collection/resultlist/"
                },
                {
                    cover: card_default_5,
                    title: "IoT",
                    summary: "物联网。通过温湿度传感器、气体传感器、视频传感器等外部硬件设备与系统进行通信，将传感器监测到的数据传至系统中进行采集使用。",
                    url: "/data_science/iot/"
                },
            ],
        },
        {// 数据清洗界面数据
            expand: "expand_11",
            status: true,
            blockTitle: [
                "数据清洗",
                true
            ],
            img_text: {
                feedback: true,
                shadow: true,
                context: [
                    {
                        id: "1",
                        img: content_cover_1,
                        title: "分箱法",
                        sumary:
                            "分箱法是一个经常使用到方法，所谓的分箱法，就是将需要处理的数据根据一定的规则放进箱子里，然后进行测试每一个箱子里的数据，并根据数据中的各个箱子的实际情况进行采取方法处理数据。看到这里很多朋友只是稍微明白了，但是并不知道怎么分箱。如何分箱呢？我们可以按照记录的行数进行分箱，使得每箱有一个相同的记录数。或者我们把每个箱的区间范围设置一个常数，这样我们就能够根据区间的范围进行分箱。其实我们也可以自定义区间进行分箱。这三种方式都是可以的。分好箱号，我们可以求每一个箱的平均值，中位数、或者使用极值来绘制折线图，一般来说，折线图的宽度越大，光滑程度也就越明显。",
                    },
                    {
                        id: "2",
                        img: content_cover_2,
                        title: "回归法",
                        sumary:
                            "回归法就是利用了函数的数据进行绘制图像，然后对图像进行光滑处理。回归法有两种，一种是单线性回归，一种是多线性回归。单线性回归就是找出两个属性的最佳直线，能够从一个属性预测另一个属性。多线性回归就是找到很多个属性，从而将数据拟合到一个多维面，这样就能够消除噪声。",
                    },
                    {
                        id: "3",
                        img: content_cover_3,
                        title: "聚类法",
                        sumary:
                            "聚类法的工作流程是比较简单的，但是操作起来确实复杂的，所谓聚类法就是将抽象的对象进行集合分组，成为不同的集合，找到在集合意外的孤点，这些孤点就是噪声。这样就能够直接发现噪点，然后进行清除即可。",
                    },
                ]
            }
        },
        {// 语义分析界面数据
            expand: "expand_12",
            status: true,
            blockTitle: [
                "语义分析",
                true
            ],
            img_text: {
                switch: false,
                context: [
                    {
                        id: "1",
                        img: content_cover_4,
                        title: "分箱法",
                        sumary:
                            "分箱法是一个经常使用到方法，所谓的分箱法，就是将需要处理的数据根据一定的规则放进箱子里，然后进行测试每一个箱子里的数据，并根据数据中的各个箱子的实际情况进行采取方法处理数据。看到这里很多朋友只是稍微明白了，但是并不知道怎么分箱。如何分箱呢？我们可以按照记录的行数进行分箱，使得每箱有一个相同的记录数。或者我们把每个箱的区间范围设置一个常数，这样我们就能够根据区间的范围进行分箱。其实我们也可以自定义区间进行分箱。这三种方式都是可以的。分好箱号，我们可以求每一个箱的平均值，中位数、或者使用极值来绘制折线图，一般来说，折线图的宽度越大，光滑程度也就越明显。",
                    },
                    {
                        id: "2",
                        img: content_cover_5,
                        title: "回归法",
                        sumary:
                            "回归法就是利用了函数的数据进行绘制图像，然后对图像进行光滑处理。回归法有两种，一种是单线性回归，一种是多线性回归。单线性回归就是找出两个属性的最佳直线，能够从一个属性预测另一个属性。多线性回归就是找到很多个属性，从而将数据拟合到一个多维面，这样就能够消除噪声。",
                    },
                ]
            }
        },
        {// 数据挖掘分析界面数据
            expand: "expand_12",
            status: true,
            blockTitle: [
                "数据挖掘分析",
                true
            ]
        },
    ],
}