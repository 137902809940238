import React from 'react';
import BlockTitle from '../../../../Components/Portal/BlockTitle';
import Card3 from '../../../../Components/Portal/Card3';
import Expand10Css from './index.module.scss';

const Expand10 = (props) => {
    const { item, layout } = props
    return (
        <div className={Expand10Css.Expand10_block_2}>
            <div className={`mdui-container${layout && `-${layout}`}`}>
                <div className='mdui-row'>
                    <div className={Expand10Css.Expand10_title}>{item.blockTitle && <BlockTitle item={item.blockTitle} />}</div>
                    {item.card.map(item => <div className={`mdui-col-sm-6 ${Expand10Css.card}`} key={item.title}><Card3 cover={item.cover} summary={item.summary} title={item.title} url={item.url} /></div>)}
                </div>
            </div>
        </div>
    );
}

export default Expand10;
