export const ItemData = [
    {
        id: 1,
        slug: '/home',
        name: '首页',
        template: 'commonTemplate',
        show: true,
        crumbs: [
            {
                name: '首页',
                slug: '/home'
            },
        ],
        sub: []
    },
    {
        id: 2,
        slug: '/data_science',
        name: '数据科学',
        template: 'commonTemplate',
        show: true,
        crumbs: [
            {
                name: '首页',
                slug: '/home'
            },
            {
                name: '数据科学',
                slug: '/data_science/data_collection'
            },
        ],
        sub: [
            {
                id: 3,
                slug: '/data_science/data_collection',
                name: '数据采集',
                template: 'commonTemplate',
                show: true,
                crumbs: [
                    {
                        name: '首页',
                        slug: '/home'
                    },
                    {
                        name: '数据科学',
                        slug: '/data_science/data_collection'
                    },
                    {
                        name: '数据采集',
                        slug: '/data_science/data_collection'
                    },
                ],
                sub: [
                    {
                        id: 15,
                        slug: '/data_science/data_collection/resultlist',
                        name: '数据采集结果',
                        template: 'dataList',
                        show: false,
                        crumbs: [
                            {
                                name: '首页',
                                slug: '/home'
                            },
                            {
                                name: '数据科学',
                                slug: '/data_science/data_collection'
                            },
                            {
                                name: '数据采集',
                                slug: '/data_science/data_collection'
                            },
                            {
                                name: '数据采集结果',
                                slug: '/data_science/data_collection/resultlist'
                            },
                        ],
                        sub: [

                            {
                                id: 16,
                                slug: '/data_science/data_collection/resultlist/detail',
                                name: '数据采集结果详情',
                                template: 'dataDetail',
                                show: false,
                                crumbs: [
                                    {
                                        name: '首页',
                                        slug: '/home'
                                    },
                                    {
                                        name: '数据科学',
                                        slug: '/data_science/data_collection'
                                    },
                                    {
                                        name: '数据采集',
                                        slug: '/data_science/data_collection'
                                    },
                                    {
                                        name: '数据采集结果',
                                        slug: '/data_science/data_collection/resultlist'
                                    },
                                    {
                                        name: '数据采集结果详情',
                                        slug: '/data_science/data_collection/resultlist/detail'
                                    },
                                ],
                                sub: []
                            },
                        ]
                    },
                    {
                        id: 17,
                        slug: '/data_science/iot',
                        name: 'IoT',
                        template: 'commonTemplate',
                        show: false,
                        crumbs: [
                            {
                                name: '首页',
                                slug: '/home'
                            },
                            {
                                name: '数据科学',
                                slug: '/data_science/data_collection'
                            },
                            {
                                name: '数据采集',
                                slug: '/data_science/data_collection'
                            },
                            {
                                name: 'IoT',
                                slug: '/data_science/iot'
                            },
                        ],
                        sub: []
                    },
                ]
            },
            {
                id: 4,
                slug: '/data_science/data_clear',
                name: '数据清洗',
                template: 'commonTemplate',
                show: true,
                crumbs: [
                    {
                        name: '首页',
                        slug: '/home'
                    },
                    {
                        name: '数据科学',
                        slug: '/data_science/data_collection'
                    },
                    {
                        name: '数据清洗',
                        slug: '/data_science/data_clear'
                    },
                ],
                sub: []
            },
            {
                id: 5,
                slug: '/data_science/natural_language_analysis',
                name: '自然语言分析',
                template: 'lexicalAnalysis',
                show: true,
                crumbs: [
                    {
                        name: '首页',
                        slug: '/home'
                    },
                    {
                        name: '数据科学',
                        slug: '/data_science/data_collection'
                    },
                    {
                        name: '自然语言分析',
                        slug: '/data_science/natural_language_analysis'
                    },
                ],
                sub: [
                    {
                        id: 6,
                        slug: '/data_science/natural_language_analysis/lexical_analysis',
                        name: '词法分析',
                        template: 'commonTemplate',
                        show: true,
                        crumbs: [
                            {
                                name: '首页',
                                slug: '/home'
                            },
                            {
                                name: '数据科学',
                                slug: '/data_science/data_collection'
                            },
                            {
                                name: '自然语言',
                                slug: '/data_science/natural_language_analysis'
                            },
                            {
                                name: '词法分析',
                                slug: '/data_science/natural_language_analysis/lexical_analysis'
                            },
                        ],
                        sub: []
                    },
                    {
                        id: 7,
                        slug: '/data_science/natural_language_analysis/semantic_analysis',
                        name: '语义分析',
                        template: 'commonTemplate',
                        show: true,
                        crumbs: [
                            {
                                name: '首页',
                                slug: '/home'
                            },
                            {
                                name: '数据科学',
                                slug: '/data_science/data_collection'
                            },
                            {
                                name: '自然语言',
                                slug: '/data_science/natural_language_analysis'
                            },
                            {
                                name: '语义分析',
                                slug: '/data_science/natural_language_analysis/semantic_analysis'
                            },
                        ],
                        sub: []
                    },
                    {
                        id: 8,
                        slug: '/data_science/natural_language_analysis/discourse_analysis',
                        name: '篇章分析',
                        template: 'commonTemplate',
                        show: true,
                        crumbs: [
                            {
                                name: '首页',
                                slug: '/home'
                            },
                            {
                                name: '数据科学',
                                slug: '/data_science/data_collection'
                            },
                            {
                                name: '自然语言',
                                slug: '/data_science/natural_language_analysis'
                            },
                            {
                                name: '篇章分析',
                                slug: '/data_science/natural_language_analysis/discourse_analysis'
                            },
                        ],
                        sub: []
                    },
                    {
                        id: 9,
                        slug: '/data_science/natural_language_analysis/text_analysis',
                        name: '文本分析',
                        template: 'commonTemplate',
                        show: true,
                        crumbs: [
                            {
                                name: '首页',
                                slug: '/home'
                            },
                            {
                                name: '数据科学',
                                slug: '/data_science/data_collection'
                            },
                            {
                                name: '自然语言',
                                slug: '/data_science/natural_language_analysis'
                            },
                            {
                                name: '文本分析',
                                slug: '/data_science/natural_language_analysis/text_analysis'
                            },
                        ],
                        sub: []
                    },
                ]
            },
            {
                id: 10,
                slug: '/data_science/data_analysis',
                name: '数据分析',
                template: 'commonTemplate',
                show: true,
                crumbs: [
                    {
                        name: '首页',
                        slug: '/home'
                    },
                    {
                        name: '数据科学',
                        slug: '/data_science/data_collection'
                    },
                    {
                        name: '数据分析',
                        slug: '/data_science/data_analysis'
                    },
                ],
                sub: []
            },
        ]
    },
    {
        id: 11,
        slug: '/agile_development',
        name: '敏捷开发',
        template: 'commonTemplate',
        show: true,
        crumbs: [
            {
                name: '首页',
                slug: ''
            },
            {
                name: '敏捷开发',
                slug: '/agile_development'
            },
        ],
        sub: []
    },
    {
        id: 12,
        slug: '/solution',
        name: '解决方案',
        template: 'commonTemplate',
        show: true,
        crumbs: [
            {
                name: '首页',
                slug: '/home'
            },
            {
                name: '解决方案',
                slug: '/solution'
            },
        ],
        sub: [
            {
                id: 12,
                slug: '/solution/intelligent_manufacturing',
                name: '智能制造',
                template: 'BusinessTemplate',
                show: false,
                crumbs: [
                    {
                        name: '首页',
                        slug: '/home'
                    },
                    {
                        name: '解决方案',
                        slug: '/solution'
                    },
                    {
                        name: '智能制造',
                        slug: '/solution/intelligent_manufacturing'
                    },
                ],
                sub: []
            },
            {
                id: 13,
                slug: '/solution/preschool_education_industry',
                name: '幼教早教',
                template: 'BusinessTemplate',
                show: false,
                crumbs: [
                    {
                        name: '首页',
                        slug: '/home'
                    },
                    {
                        name: '解决方案',
                        slug: '/solution'
                    },
                    {
                        name: '幼教早教',
                        slug: '/solution/preschool_education_industry'
                    },
                ],
                sub: []
            },
            {
                id: 14,
                slug: '/solution/fine_chemical_industry',
                name: '精细化工',
                template: 'BusinessTemplate',
                show: false,
                crumbs: [
                    {
                        name: '首页',
                        slug: '/home'
                    },
                    {
                        name: '解决方案',
                        slug: '/solution'
                    },
                    {
                        name: '精细化工',
                        slug: '/solution/fine_chemical_industry'
                    },
                ],
                sub: []
            },
            {
                id: 15,
                slug: '/solution/other_industries',
                name: '其他行业',
                template: 'BusinessTemplate',
                show: false,
                crumbs: [
                    {
                        name: '首页',
                        slug: '/home'
                    },
                    {
                        name: '解决方案',
                        slug: '/solution'
                    },
                    {
                        name: '其他行业',
                        slug: '/solution/other_industries'
                    },
                ],
                sub: []
            },
        ]
    },
    {
        id: 13,
        slug: '/about',
        name: '关于宜氪',
        template: 'commonTemplate',
        show: true,
        crumbs: [
            {
                name: '首页',
                slug: '/home'
            },
            {
                name: '关于宜氪',
                slug: '/about'
            },
        ],
        sub: []
    },
    {
        id: 14,
        slug: '/join',
        name: '加入宜氪',
        template: 'commonTemplate',
        show: true,
        crumbs: [
            {
                name: '首页',
                slug: '/home'
            },
            {
                name: '加入宜氪',
                slug: '/join'
            },
        ],
        sub: []
    },
    // {
    //     id: 18,
    //     slug: '/common',
    //     name: '通用模板',
    //     template: 'DefaultTemplate',
    //     show: true,
    //     crumbs: [
    //         {
    //             name: '首页',
    //             slug: ''
    //         },
    //         {
    //             name: '通用模板',
    //             slug: '/common'
    //         },
    //     ],
    //     sub: []
    // },
]

export const LanguageList = [
    {
        name: '简体中文(CN)',
        slug: 'zh-CN',
    },
    {
        name: 'English(US)',
        slug: 'en-US'
    },
    {
        name: '中文繁体(HK)',
        slug: 'zh-HK',
    },
]

export const MobileItemData = {
    topbar_switch: false,
    data: [
        {
            id: 1,
            slug: '/admin',
            name: '首页',
            icon: "home",
            template: 'commonTemplate',
            show: true,
            crumbs: [
                {
                    name: '首页',
                    slug: '/admin'
                },
            ],
            sub: []
        },
        {
            id: 2,
            slug: '/admin',
            name: '页面',
            icon: "insert_drive_file",
            template: 'commonTemplate',
            show: true,
            crumbs: [
                {
                    name: '页面',
                    slug: '/admin'
                },
            ],
            sub: [
                {
                    id: 7,
                    slug: '/admin',
                    name: '页面项',
                    template: 'commonTemplate',
                    show: true,
                    crumbs: [
                        {
                            name: '页面项',
                            slug: '/admin'
                        },
                    ],
                    sub: []
                },
                {
                    id: 8,
                    slug: '/admin',
                    name: '模板',
                    template: 'commonTemplate',
                    show: true,
                    crumbs: [
                        {
                            name: '模板',
                            slug: '/admin'
                        },
                    ],
                    sub: []
                },
            ]
        },
        {
            id: 3,
            slug: '/admin',
            name: '附件',
            icon: "photo_album",
            template: 'commonTemplate',
            show: true,
            crumbs: [
                {
                    name: '附件',
                    slug: '/admin'
                },
            ],
            sub: [
                {
                    id: 9,
                    slug: '/admin',
                    name: '图片',
                    template: 'commonTemplate',
                    show: true,
                    crumbs: [
                        {
                            name: '模板',
                            slug: '/admin'
                        },
                    ],
                    sub: []
                },
                {
                    id: 10,
                    slug: '/admin',
                    name: '文档',
                    template: 'commonTemplate',
                    show: true,
                    crumbs: [
                        {
                            name: '模板',
                            slug: '/admin'
                        },
                    ],
                    sub: []
                },
                {
                    id: 11,
                    slug: '/admin',
                    name: '音频',
                    template: 'commonTemplate',
                    show: true,
                    crumbs: [
                        {
                            name: '模板',
                            slug: '/admin'
                        },
                    ],
                    sub: []
                },
                {
                    id: 12,
                    slug: '/admin',
                    name: '视频',
                    template: 'commonTemplate',
                    show: true,
                    crumbs: [
                        {
                            name: '模板',
                            slug: '/admin'
                        },
                    ],
                    sub: []
                },
                {
                    id: 13,
                    slug: '/admin',
                    name: '其它',
                    template: 'commonTemplate',
                    show: true,
                    crumbs: [
                        {
                            name: '模板',
                            slug: '/admin'
                        },
                    ],
                    sub: []
                },
            ]
        },
        {
            id: 4,
            slug: '/admin',
            name: '邮件',
            template: 'commonTemplate',
            show: true,
            crumbs: [
                {
                    name: '首页',
                    slug: '/admin'
                },
            ],
            sub: []
        },
        {
            id: 5,
            slug: '/admin',
            name: '设置',
            icon: "settings",
            template: 'commonTemplate',
            show: true,
            crumbs: [
                {
                    name: '首页',
                    slug: '/admin'
                },
            ],
            sub: [
                {
                    id: 14,
                    slug: '/admin',
                    name: '语言',
                    template: 'commonTemplate',
                    show: true,
                    crumbs: [
                        {
                            name: '模板',
                            slug: '/admin'
                        },
                    ],
                    sub: []
                },
                {
                    id: 15,
                    slug: '/admin',
                    name: '菜单',
                    template: 'commonTemplate',
                    show: true,
                    crumbs: [
                        {
                            name: '模板',
                            slug: '/admin'
                        },
                    ],
                    sub: []
                },
                {
                    id: 16,
                    slug: '/admin',
                    name: '设置',
                    template: 'commonTemplate',
                    show: true,
                    crumbs: [
                        {
                            name: '模板',
                            slug: '/admin'
                        },
                    ],
                    sub: []
                },
            ]
        },
        {
            id: 6,
            slug: '/admin',
            name: '用户',
            icon: "person",
            template: 'commonTemplate',
            show: true,
            crumbs: [
                {
                    name: '首页',
                    slug: '/admin'
                },
            ],
            sub: []
        },
    ]
}