import { createStore, applyMiddleware } from 'redux';
import thunk from "redux-thunk"

const initState = null

const changeScroll = (preSatate = initState, action) => {
    switch (action.type) {
        case 'changeScroll':
            preSatate = action.v
            break;
        default:
            return preSatate

    }
    return preSatate
}

export default createStore(changeScroll, applyMiddleware(thunk))