import React from 'react';
import Expand3Css from './index.module.scss';

const Expand3 = (props) => {
    const { item, layout } = props
    return (
        <div className={Expand3Css.banner_text}>
            <div className={`mdui-container${layout && `-${layout}`}`}>
                <div className='mdui-row'>
                    <div className={`${Expand3Css.banner_content} mdui-valign`}>
                        <div className="mdui-center wow pulse animated">
                            {item.bannerText}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Expand3;
