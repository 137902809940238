import React from 'react';
import BlockTitleCss from './index.module.scss';

const BlockTitle = (props) => {
    /**
     * 标题样式,
     * content: 标题内容文字
     * line: ture / false, 是否开启线条, 未传入时默认为 false
     */

    const { item } = props

    return (
        <div className='mdui-clearfix'>
            <div className={`${BlockTitleCss.block_left} mdui-col-xs-3`}>
                {
                    item.line && <>
                        <div className={`${BlockTitleCss.block_left_round}`}></div>
                        <div className={`${BlockTitleCss.block_left_rectangle}`}></div>
                    </>
                }
            </div>

            <div className={`${BlockTitleCss.block_center} mdui-col-xs-6`}>
                {item.content}
            </div>

            <div className={`${BlockTitleCss.block_right} mdui-col-xs-3`}>
                {
                    item.line && <>
                        <div className={`${BlockTitleCss.block_right_round}`}></div>
                        <div className={`${BlockTitleCss.block_right_rectangle}`}></div>
                    </>
                }
            </div>
        </div>
    );
}

export default BlockTitle;
