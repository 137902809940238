import { Pagination, A11y, EffectFade, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwPagination from './SwPagination';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { nanoid } from 'nanoid';
import SwDubleCss from './index.module.scss';
import SwPaginationCss from './SwPageStyle.module.scss';
import sw_bg1 from '../../Source/Image/sw_bg1.png';
import sw_bg2 from '../../Source/Image/sw_bg2.png';
import { useState, useEffect } from 'react';

const SwDuble = (param) => {

    const { swiper_data } = param
    const [sw, setSw] = useState(null);

    useEffect(() => {
        const pagination = document.querySelector('.bw-swiper-pagination')
        const handleSwPaginationClick = (e) => {
            handleSwClick(e, sw)
        }
        pagination.addEventListener('click', handleSwPaginationClick)
        return () => {
            pagination.removeEventListener('click', handleSwPaginationClick)
        }
    }, [sw])

    const handleSwClick = (e, sw) => {
        const t = e.target;
        const classArr = t.className.split(' ')
        classArr.forEach(item => {
            if (item === SwPaginationCss.sw_page_tag_sub_item) {
                sw.slideTo(t.dataset.index)
            }
        })
    }

    return (
        <div className='mdui-row' style={{ position: 'relative' }}>
            <div className={`swiper-pagination bw-swiper-pagination ${SwDubleCss.sw_pagination} ${SwDubleCss.sw_page_tag}`}></div>
            <Swiper
                modules={[Pagination, A11y, EffectFade, Autoplay]}
                pagination={{
                    el: '.swiper-pagination',
                    type: 'custom',
                    renderCustom: (_swiper, current, total) => SwPagination(_swiper, current, total, setSw)
                }}
                loop={true}
                effect='fade'
                autoplay={true}
            >
                {swiper_data.map(item => {
                    return <SwiperSlide key={nanoid()}>
                        {/* <div className={`mdui-container-fulid ${SwDubleCss.sw}`}> */}
                        <div className={`${SwDubleCss.sw}`}>
                            <div className='mdui-row'>
                                <div className="mdui-col-sm-8 mdui-valign">
                                    <div className={`mdui-center ${SwDubleCss.sw_image_block}`}>
                                        <img className={`mdui-center ${SwDubleCss.sw_image_block_content}`} src={sw_bg1} alt='' />
                                        <div className={`mdui-center ${SwDubleCss.sw_image_border}`}>
                                            <img className={`${SwDubleCss.sw_image_border_content}`} src={item.image.cover} alt='' />
                                        </div>
                                        <img className={`${SwDubleCss.sw_image_border_top_bg}`} src={sw_bg2} alt='' />
                                    </div>
                                </div>
                                <div className={`mdui-col-sm-4 mdui-valign ${SwDubleCss.sw_right_item}`}>
                                    <div className={`${SwDubleCss.sw_right_item_block}`}>
                                        <ul className="mdui-list">
                                            {
                                                item.itemLIst.map(
                                                    item_list =>
                                                        <li key={item_list} className="mdui-list-item mdui-ripple">
                                                            <i className="mdui-icon material-icons" style={{ fontSize: '14px', marginRight: '15px' }}>&#xe3a6;</i>
                                                            {item_list}
                                                        </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <img className={`${SwDubleCss.sw_right_item_bg}`} src={item.image.background_image} alt='' />
                            </div>
                        </div>
                    </SwiperSlide>;
                })}
            </Swiper>
        </div>
    );
}

export default SwDuble;
