import React from 'react';
import BlockTitle from '../../../../Components/Portal/BlockTitle';
import Expand15Css from './index.module.scss'

const Expand15 = (props) => {
    const { layout, item, subPage } = props
    return (
        <div className={`${Expand15Css.Expand15_block_2}`}>
            <div style={{ backgroundColor: '#FFF' }}>{item.blockTitle && <BlockTitle item={item.blockTitle} />}</div>
            <div className={`bw-block  ${subPage === false && "sub_bw_block"}`}>
                <div className={`mdui-container${layout && `-${layout}`}`}>
                    <div className="mdui-row">
                        <img src={item.img} alt="" width="100%" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Expand15;
