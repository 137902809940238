import React from 'react';
import ImgTextContentCss from './index.module.scss'
import FeedBack from '../FeedBack';

const ImageAndText = (param) => {
    const { content, feedback, shadow } = param
    return (
        <>
            {content.map(
                (item, index) =>
                    index % 2 === 0 ?
                        <div key={item.id} className={`mdui-row mdui-hidden-sm-down wow slideInLeft animated ${ImgTextContentCss.card_block}`}>
                            <div className={`mdui-col-sm-6 mdui-col-md-4 ${shadow && 'mdui-shadow-2'} ${ImgTextContentCss.card_block_img}`}>
                                <img src={item.img} alt={item.title} width={`100%`} />
                            </div>
                            <div className={`mdui-col-sm-6 mdui-col-md-8`}>
                                <div className={`mdui-center ${ImgTextContentCss.card_block_text}`}>
                                    <div className={`mdui-card-primary-title`}>{item.title}</div>
                                    <div className={`mdui-card-primary-subtitle`}>{item.sumary}</div>
                                </div>
                            </div>
                        </div>
                        :
                        <div key={item.id} className={`mdui-row mdui-hidden-sm-down wow slideInRight animated ${ImgTextContentCss.card_block}`}>
                            <div className={`mdui-col-sm-6 mdui-col-md-8`}>
                                <div className={`mdui-center ${ImgTextContentCss.card_block_text}`}>
                                    <div className={`mdui-card-primary-title`}>{item.title}</div>
                                    <div className={`mdui-card-primary-subtitle`}>{item.sumary}</div>
                                </div>
                            </div>
                            <div className={`mdui-col-sm-6 mdui-col-md-4 ${shadow === true && 'mdui-shadow-2'} ${ImgTextContentCss.card_block_img}`}>
                                <img src={item.img} alt={item.title} width={`100%`} />
                            </div>
                        </div>
            )}
            {content.map(
                item =>
                    <div key={item.id} className={`mdui-row mdui-hidden-md-up wow slideInUp animated`}>
                        <div className={`mdui-card mdui-shadow-0 ${ImgTextContentCss.card}`}>
                            <div className="mdui-card-media">
                                <img src={item.img} alt={item.title} />
                                <div className="mdui-card-primary">
                                    <div className="mdui-card-primary-title mdui-text-center">{item.title}</div>
                                </div>
                                <div className="mdui-card-content">{item.sumary}</div>
                            </div>
                        </div>
                    </div>
            )}
            {feedback && <div className={`mdui-row ${ImgTextContentCss.card_block}`}><FeedBack /></div>}
        </>
    );
}

export default ImageAndText;