import React, { Fragment, useState } from 'react';
import { useForm } from "react-hook-form";
import { nanoid } from 'nanoid';
import FeedBackCss from './index.module.scss';
import { useTranslation } from 'react-i18next';
import { ProxyName } from '../../../Tools/ProxyOption';
import axios from 'axios';


const FeedBack = () => {
    /**
     * 1. 使用第三方依赖 react-hook-form 校验
     * 
     * 2. form中的 onSubmit={handleSubmit(onSubmit)} 其实是指定一个函数, 在函数中可以获取到该form中的所有值
     * 
     * 3. 每个 input 中需要这样写: {...register("phone", { required: true })} , 其中 "phone" 代表 input 的 name, 后面的 { required: true } 是指是否开启校验, 并且这里还能添加其他校验规则,
     * true则代表开启, 默认不填写则为false, 如果开启校验, {errors.phone && <span>不能为空</span>} 就会生效, errors. 后面的是input中的name值, 可以根据需求绑定, span 中间的是提示信息.
     * 
     * 4. 更详细的校验规则看官网: https://react-hook-form.com/zh/get-started#Applyvalidation
     */

    const { t } = useTranslation()
    const { register, handleSubmit, formState: { errors } } = useForm();
    // const [dialogStatus, setDialogStatus] = useState(false)
    const [dialogStatus, setDialogStatus] = useState({ display: false, title: "", detail: "" })

    const onSubmit = data => {
        const now_t = new Date().getMinutes() * 1
        const old_t = localStorage.getItem("tous")

        if (old_t && now_t - old_t * 1 < 10) {
            setDialogStatus({ display: true, title: t("Feedback.Dialog.RePost.Title"), detail: t("Feedback.Dialog.RePost.Detail") })
            setTimeout(() => {
                setDialogStatus({ display: false, title: t("Feedback.Dialog.RePost.Title"), detail: t("Feedback.Dialog.RePost.Detail") })
            }, 3000)
        } else {
            axios.post(`${ProxyName}/server/send/`, data)
                .then((res) => {
                    if (res.data.status_code === 200) {
                        setDialogStatus({ display: true, title: t("Feedback.Dialog.Success.Title"), detail: t("Feedback.Dialog.Success.Detail") })
                        localStorage.setItem("tous", now_t)
                        setTimeout(() => {
                            setDialogStatus({ display: false, title: t("Feedback.Dialog.RePost.Title"), detail: t("Feedback.Dialog.RePost.Detail") })
                        }, 3000)
                    } else if (res.data.status_code === 400) {
                        setDialogStatus({ display: true, title: t("Feedback.Dialog.Error.Title"), detail: t("Feedback.Dialog.Error.Detail") })
                        localStorage.setItem("tous", now_t - 10)
                        setTimeout(() => {
                            setDialogStatus({ display: false, title: t("Feedback.Dialog.Error.Title"), detail: t("Feedback.Dialog.Error.Detail") })
                        }, 3000)
                    }
                }).catch(err => {
                    console.log(err)
                })
        }
    }

    const entryMsg = () => {
        setDialogStatus({ display: false, title: "''", detail: "" })
    }

    const feedback = [
        {
            name: 'Company',
            detail: t("Feedback.Company.Detail"),
            tips: t("Feedback.Company.Tips"),
        },
        {
            name: 'UserName',
            detail: t("Feedback.UserName.Detail"),
            tips: t("Feedback.UserName.Tips"),
        },
        {
            name: 'Phone',
            detail: t("Feedback.Phone.Detail"),
            tips: t("Feedback.Phone.Tips"),
        },
        {
            name: 'Contact',
            detail: t("Feedback.Contact.Detail"),
            tips: t("Feedback.Contact.Tips"),
        },
        {
            name: 'Content',
            detail: t("Feedback.Content.Detail"),
            tips: t("Feedback.Content.Tips"),
        },
    ]

    feedback.forEach(item => item.err = errors[item.name])


    return (
        // <div className='mdui-row'>
        <div className='mdui-col-xs-12'>
            <form onSubmit={handleSubmit(onSubmit)}>
                {
                    feedback.map(
                        item =>
                            <Fragment key={nanoid()}>
                                {item.err && <span className={`${FeedBackCss.feedback_err_tip} mdui-col-xs-12 mdui-col-sm-8 mdui-col-offset-sm-2`}>{item.tips}</span>}
                                {
                                    item.name === 'Content' ?
                                        <textarea className={`${FeedBackCss.feedback_input} mdui-col-xs-12 mdui-col-sm-8 mdui-col-offset-sm-2`} placeholder={item.detail} rows='10' maxLength='1000' {...register(item.name, { required: true })} />
                                        :
                                        item.name === 'Phone' ?
                                            <input className={`${FeedBackCss.feedback_input} mdui-col-xs-12 mdui-col-sm-8 mdui-col-offset-sm-2`} placeholder={item.detail} {...register(item.name, { required: true, pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/ })} />
                                            :
                                            <input className={`${FeedBackCss.feedback_input} mdui-col-xs-12 mdui-col-sm-8 mdui-col-offset-sm-2`} placeholder={item.detail} {...register(item.name, { required: true })} />
                                }
                            </Fragment>
                    )
                }
                <div className='mdui-col-xs-12 mdui-col-sm-8 mdui-col-offset-sm-2 mdui-valign'>
                    <button className="mdui-btn mdui-ripple bw_theme_blue bw_theme_blue_btn mdui-center" type='submit'>{t("Trans.Consulting")}</button>
                </div>
            </form>
            {/* 对话框 */}
            {
                dialogStatus.display === true &&
                <>
                    <div className="mdui-overlay mdui-overlay-show" style={{ zIndex: "5100" }}></div>
                    <div className="mdui-dialog mdui-dialog-open" id="dialog" style={{ display: 'block', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}>
                        <div className="mdui-dialog-title">{dialogStatus.title}</div>
                        <div className="mdui-dialog-content">{dialogStatus.detail}</div>
                        <div className="mdui-dialog-actions">
                            <button className="mdui-btn mdui-ripple mdui-ripple-blue mdui-hidden-sm-down bw_theme_blue bw_theme_blue_btn" onClick={entryMsg}>{t("Feedback.Dialog.Entry")}</button>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}

export default FeedBack;
