import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { dataScienceListDetail } from '../../../Tools/DemoData';
import { dataScienceListDetailHK } from '../../../Tools/DemoDataHK';
import { dataScienceListDetailEN } from '../../../Tools/DemoDataEN';
import DataScienceListDetailCss from './index.module.scss';
import Banner from '../../../Components/Portal/Banner';
import BreadItem from '../../../Components/Portal/BreadItem';

const DataScienceListDetail = (param) => {
    const { crumbs } = param
    const { t } = useTranslation()

    const lang = localStorage.getItem('i18nextLng')
    let dsl = ""
    lang === 'zh-HK' ? dsl = dataScienceListDetailHK : lang === 'en-US' ? dsl = dataScienceListDetailEN : dsl = dataScienceListDetail
    const { banner, file_data } = dsl

    useEffect(() => {
        const m = (document.title).split(' ')
        document.title = `${banner.title} ${m[m.length - 1]}`
    })

    return (
        <>
            <Banner state={banner} />
            <div className='mdui-container'>
                <div className='mdui-row'>
                    <div className='mdui-col-xs-12'>
                        <BreadItem bread={crumbs} />
                        <div className={`mdui-text-center mdui-col-xs-12 ${DataScienceListDetailCss.title}`}>{file_data.Title}</div>
                        <div className={`mdui-col-xs-12 mdui-typo ${DataScienceListDetailCss.detail}`}>
                            <ul>
                                <li>{t("DataDetail.FileType")}： {file_data.FileType}</li>
                                <li>{t("DataDetail.FileSize")}： {file_data.FileSize}</li>
                                <li>{t("DataDetail.RecommendTheWebsite")}： <a href={file_data.RecommendTheWebsite.url}>{file_data.RecommendTheWebsite.title}</a></li>
                                <li>{t("DataDetail.Authorization")}：{file_data.Authorization}</li>
                            </ul>
                        </div>

                        <div className={`mdui-col-xs-12`}>
                            <fieldset className={DataScienceListDetailCss.detail_explain}>
                                <legend>{t("DataDetail.DownloadInstructions")}</legend>
                                <ul>
                                    <li>{t("DataDetail.summari_1")}</li>
                                    <li>{t("DataDetail.summari_2")}</li>
                                    <li>{t("DataDetail.summari_3")}</li>
                                    <li>{t("DataDetail.summari_4")}</li>
                                </ul>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DataScienceListDetail;
