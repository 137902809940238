import React from 'react';
import BlockTitle from '../../../../Components/Portal/BlockTitle';
import Chain from '../../../../Components/Portal/Chain';
import Expand5Css from './index.module.scss'

const Expand5 = (props) => {
    const { item, layout } = props
    return (
        <div className={Expand5Css.Expand5_block}>
            <div className={`mdui-container${layout && `-${layout}`}`}>
                <div className={Expand5Css.Expand5_title}>{item.blockTitle && <BlockTitle item={item.blockTitle} />}</div>
                {item.chain_data && <Chain chain_data={item.chain_data} />}
            </div>
        </div>
    );
}

export default Expand5;
