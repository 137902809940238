import React from 'react';
import BreadItem from '../../../../Components/Portal/BreadItem';

const Expand2 = (props) => {
    const { layout, crumbs } = props
    return (
        <div className='bw_theme_white'>
            <div className={`mdui-container${layout && `-${layout}`}`}>
                <div className='mdui-row'>
                    <BreadItem bread={crumbs} />
                </div>
            </div>
        </div>
    );
}

export default Expand2;
