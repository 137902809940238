import { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import SidebarCss from './index.module.scss';
import Store from '../../../../Redux/mduiReduser';

const Sidebar = (param) => {
    const [p_item, setP_item] = useState()
    const { itemList } = param
    useEffect(() => {
        setP_item(createSidebar())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        Store.getState().mutation();
    }, [p_item])

    const history = useHistory()
    const jumpLinks = (slug) => {
        return () => {
            history.push(slug)
        }
    }

    const data = itemList.data ? itemList.data : itemList

    const createSidebar = () => {
        return data.map(
            item =>
                item.show && Array.isArray(item.sub) && item.sub.length > 0 && item.sub.some(someItem => someItem.show) ?
                    <div key={item.id} className={`mdui-collapse-item ${SidebarCss.bw_collapse}`}>
                        <div className="mdui-collapse-item-header mdui-list-item mdui-ripple" style={{ backgroundColor: '#f9fcff' }}>
                            <p className='mdui-typo-body-2' style={{ width: '100%' }}>
                                <i className="mdui-icon mdui-list-item-icon material-icons" style={{ marginRight: "10px" }}>{item.icon && item.icon}</i>
                                {item.name}
                                <i className="mdui-collapse-item-arrow mdui-icon material-icons mdui-float-right" style={{ marginTop: '0' }}>keyboard_arrow_down</i>
                            </p>
                        </div>
                        <div className="mdui-collapse-item-body" style={{ backgroundColor: '#fff' }}>
                            {
                                item.sub.map(subToItem =>
                                    subToItem.show && Array.isArray(subToItem.sub) && subToItem.sub.length > 0 && subToItem.sub.some(someItem => someItem.show) ?
                                        createSidebar2(subToItem)
                                        :
                                        <p key={subToItem.id} className='mdui-list-item mdui-ripple' onClick={jumpLinks(subToItem.slug)}>{subToItem.name}</p>
                                )
                            }
                        </div>
                    </div>
                    :
                    <div key={item.id} className={`mdui-collapse-item-header ${SidebarCss.bw_collapse} mdui-list-item mdui-ripple $`} onClick={jumpLinks(item.slug)}>
                        <p className='mdui-typo-body-2'>
                            <i className="mdui-icon mdui-list-item-icon material-icons" style={{ marginRight: "10px" }}>{item.icon && item.icon}</i>
                            {item.name}
                        </p>
                    </div>
        )
    }
    const createSidebar2 = (subItem) => {
        return <Fragment key={subItem.id}>
            <div className="mdui-collapse" mdui-collapse="{accordion: true}">
                <div className="mdui-collapse-item">
                    <div className="mdui-collapse-item-header mdui-list-item mdui-ripple" style={{ backgroundColor: '#f9fcff' }}>
                        <p className='mdui-typo-body-2' style={{ width: '100%' }}>
                            {subItem.name}
                            <i className="mdui-collapse-item-arrow mdui-icon material-icons mdui-float-right" style={{ marginTop: '0' }}>keyboard_arrow_down</i>
                        </p>
                    </div>
                    <div className="mdui-collapse-item-body" style={{ backgroundColor: '#fff' }}>
                        {
                            subItem.sub.map(
                                subItemToSub =>
                                    subItemToSub.show && Array.isArray(subItemToSub.sub) && subItemToSub.sub.length > 0 && subItemToSub.sub.some(someItem => someItem.show) ?
                                        createSidebar2(subItemToSub)
                                        :
                                        <p key={subItemToSub.id} className='mdui-list-item mdui-ripple' onClick={jumpLinks(subItemToSub.slug)}>{subItemToSub.name}</p>
                            )
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    }
    return (
        <div className="mdui-drawer mdui-drawer-close" id="left-drawer" style={{ backgroundColor: '#f9fcff' }}>
            {/* <div className="mdui-drawer mdui-drawer-close" id="left-drawer" style={{ backgroundColor: '#232f44', color: '#FFF' }}> */}
            <div className="mdui-collapse mdui-list" mdui-collapse="{accordion: true}">{p_item}</div>
        </div>
    );
}

export default Sidebar;