import { useTranslation } from 'react-i18next';
import Card2Css from './index.module.scss';

const Card2 = (param) => {
    const { card_data } = param
    const { t } = useTranslation()
    return (
        <div className='mdui-row'>
            {
                card_data.map(
                    (item, index) =>
                        <div key={index} className='mdui-col-md-4'>
                            <div className={`mdui-card ${Card2Css.bw_card} wow fadeInUp animated`}>
                                <ul className={`mdui-list ${Card2Css.bw_card_topbar} bw_theme_blue`}>
                                    <li className="mdui-list-item">
                                        <i className={`mdui-list-item-icon mdui-icon material-icons mdui-text-color-white ${Card2Css.bw_card_topbar_icon}`}>&#xe7fd;</i>
                                        <div className={`mdui-list-item-content ${Card2Css.bw_card_topbar_title}`}>{item.title}</div>
                                    </li>
                                </ul>
                                <div className={`mdui-card-content ${Card2Css.bw_card_content}`}>
                                    <p className={Card2Css.bw_card_msg_title}>{t("Trans.Responsibility")}</p>
                                    <ol>{item.summary.map((msg, index) => <li key={index}>{msg}</li>)}</ol>
                                    {
                                        item.detail.length > 0 &&
                                        <>
                                            <p className={Card2Css.bw_card_msg_title}>{t("Trans.JobSpecification")}</p>
                                            <ol>{item.detail.map((msg, index) => <li key={index}>{msg}</li>)}</ol>
                                        </>
                                    }
                                </div>
                                <div className="mdui-card-actions">
                                    <a href={item.url} className="mdui-btn mdui-ripple mdui-float-right bw_theme_blue bw_theme_blue_btn">{t("Trans.Detail")}</a>
                                </div>
                            </div>
                        </div>
                )
            }
        </div>
    );
}

export default Card2;
