import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import DemoCss from './index.module.scss';
import axios from 'axios';
import { ProxyName } from '../../../Tools/ProxyOption';

const Demo = (param) => {
    const { active_model, demo_model_data } = param
    const { t } = useTranslation()
    const { register, handleSubmit } = useForm();
    // const { register, handleSubmit, formState: { errors } } = useForm();
    const [postToModel, setPostToModel] = useState('text')
    const [demoModel, setDemoModel] = useState({ title: demo_model_data[0].title, req_path: demo_model_data[0].req_path, describe: demo_model_data[0].describe, model: demo_model_data[0].model })
    const [result, setResult] = useState({ demo_type: "", data: "" })

    const onSubmit = data => {

        let new_form = new FormData()
        postToModel === "document" && new_form.append('file', data.fileDemo[0])

        const post_data = postToModel === "text" ? {
            method: 'post',
            url: `${ProxyName}${demoModel.req_path}`,
            data: data.textDemo
        } : {
            method: 'post',
            url: `${ProxyName}${demoModel.req_path}`,
            headers: {
                'Content-Type': 'multipart/form-data',
                "Access-Control-Allow-Origin": "*",
            },
            withCredentials: true,
            data: new_form
        }

        axios(post_data).then((res) => {
            res.data.status_code === 200 && setResult({ demo_type: res.data.demo_type, data: res.data.data })
        }).catch(err => {
            console.log(err)
        })
    }

    const changePostType = (keyword) => {
        return () => {
            setPostToModel(keyword)
        }
    }

    const changeDemoModel = (title, req_path, describe, model) => {
        return () => {
            setDemoModel({ title, req_path, describe, model })
        }
    }

    return (
        <>
            {active_model === true && <div className='mdui-row'>
                <div className={`mdui-col-xs-12 ${DemoCss.custom_item}`}>
                    {
                        demo_model_data.map(
                            item =>
                                <div key={nanoid()} className={`mdui-col-xs-3 mdui-valign mdui-ripple`}>
                                    <div className={`mdui-center ${DemoCss.custom_item_btn} ${demoModel.title === item.title && 'bw_theme_active'}`} onClick={changeDemoModel(item.title, item.req_path, item.describe, item.model)}>
                                        <p className={`mdui-text-center`}><i className={`mdui-icon material-icons iconfont ${item.icon}`}></i></p>
                                        <p className={`mdui-text-center`}>{item.title}</p>
                                    </div>
                                </div>
                        )
                    }
                </div>
                <div className={`mdui-col-xs-12 ${DemoCss.custom_item}`} style={{ padding: "0 24px" }}>
                    <p className={DemoCss.custom_item_title}><span>■</span>{demoModel.describe.title}</p>
                    <p className={DemoCss.custom_item_summary}>{demoModel.describe.summary}</p>
                </div>
            </div>}

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`mdui-col-md-7`} style={{ marginBottom: '15px' }}>
                    <div className={DemoCss.demo}>
                        <div className={DemoCss.demo_top}>
                            <div className="mdui-btn-group">
                                {
                                    demoModel.model.map(
                                        item =>
                                            <button key={nanoid()} type="button" className={`mdui-btn mdui-ripple ${item === postToModel ? 'mdui-btn-active' : ''}`} onClick={changePostType(item)}>
                                                {t("Demo.Document")}
                                            </button>
                                    )
                                }
                            </div>
                        </div>
                        <div className={DemoCss.demo_center}>
                            {
                                postToModel === 'text' ?
                                    <textarea name="" className={`${DemoCss.demo_textarea}`} {...register('textDemo', { required: true })}></textarea>
                                    :
                                    <input type="file" {...register('fileDemo', { required: true })} />
                            }
                        </div>
                        <div className={DemoCss.demo_footer}>
                            <button className={`mdui-btn mdui-ripple bw_theme bw_theme_blue ${DemoCss.demo_btn}`} type='submit'>{t("Demo.Analysis")}</button>
                            <button className={`mdui-btn mdui-ripple bw_theme bw_theme_blue ${DemoCss.demo_btn}`}>{t("Demo.Clear")}</button>
                        </div>
                    </div>
                </div>
            </form>
            <div className={`mdui-col-md-5`}>
                <div className={DemoCss.demo}>
                    <div className={DemoCss.demo_top}>
                        <div className="mdui-btn-group">
                            <button type="button" className="mdui-btn">
                                {t("Demo.Result")}
                            </button>
                        </div>
                    </div>
                    <div className={`${DemoCss.demo_center} ${DemoCss.demo_center_2}`} style={{ padding: "16px", boxSizing: "border-box", overflow: "auto" }}>
                        {
                            result.demo_type === "abstract_of_the_article" | result.demo_type === "keyword_extraction" ? result.data :
                                result.demo_type === "lexical_analysis" ? result.data.map(item => <ul key={nanoid()}><li><strong>{item[0]}:</strong>&nbsp;&nbsp;{item[1]}</li></ul>) :
                                    result.demo_type === "text_classification" &&
                                    <>
                                        {result.data.lv1_tag_list.map(lv1 => <ul key={nanoid()}><li><strong>{lv1.tag}:</strong>&nbsp;&nbsp;{lv1.score}</li></ul>)}
                                        {result.data.lv2_tag_list.map(lv2 => <ul key={nanoid()}><li><strong>{lv2.tag}:</strong>&nbsp;&nbsp;{lv2.score}</li></ul>)}
                                    </>
                        }
                    </div>
                </div>
            </div>
            <div className={`mdui-col-xs-12 ${DemoCss.none_block}`}></div>
        </>
    );
}

export default Demo;
