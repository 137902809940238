import React from 'react'
import StandardGraphic from '../../../../../Components/StandardGraphic'

export default function CL_7(props) {
    const { res_data } = props

    const style = {
        backgroundImage: `url(${res_data.background_image})`
    }
    return (
        <>
            <div className="full_background_image" style={style}></div>
            <StandardGraphic title={res_data.title} details={res_data.details} />
        </>
    )
}
