import React, { Fragment } from 'react';
import { nanoid } from 'nanoid';
import BreadItemCss from './index.module.scss';

const BreadItem = (param) => {
    const { bread } = param
    return (
        <div className={`${BreadItemCss.bread} mdui-typo`}>
            <ul className={BreadItemCss.bread_ul}>
                {
                    bread.map(item =>
                        <Fragment key={nanoid()}>
                            <li><a href={item.slug}>{item.name}</a></li>
                            <li>/</li>
                        </Fragment>
                    )
                }
            </ul>
        </div>
    );
}

export default BreadItem;
