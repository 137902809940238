import React from 'react';
import StandardGraphicCss from './index.module.scss';

const StandardGraphic = (props) => {
    const { title, summary } = props
    return (
        <div className={`mdui-container ${StandardGraphicCss.banner}`}>
            <div className={`mdui-text-center ${StandardGraphicCss.banner_title}`}>{title}</div>
            <div className={`${StandardGraphicCss.banner_summary}`}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{summary}
            </div>
        </div>
    );
}

export default StandardGraphic;
