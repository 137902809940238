import React, { useState, useEffect } from 'react'
import Card1Css from './index.module.scss';
import { useHistory } from 'react-router-dom'

const PanelCard1 = (param) => {
    const { res_data } = param

    const [panelCard, setPanelCard] = useState(null)
    useEffect(
        () => {
            setTimeout(() => {
                setPanelCard(res_data)
            }, 0)
        }, []
    )

    let history = useHistory();

    const goToDetail = (url) => {
        return () => {
            history.push(url);
        }
    }

    return (
        <div className='mdui-row'>
            {
                panelCard && panelCard.map(
                    item =>
                        <div key={item.title} className='mdui-col-md-4'>
                            <div className={`mdui-card mdui-hoverable ${Card1Css.bw_card} wow fadeInUp animated`}>
                                <div className={` ${Card1Css.bw_card_media} mdui-card-media`}>
                                    <img src={item.bg} alt='' />
                                    <div className={`mdui-card-media-covered bw_theme_blur_dark bw_blur ${Card1Css.card_title}`} onClick={goToDetail(item.url)}>
                                        <div className="mdui-card-primary">
                                            <div className="mdui-card-primary-title">{item.title}</div>
                                            <div className="mdui-card-primary-subtitle">{item.summary}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                )
            }
        </div>
    );
}

export default PanelCard1;
