import React from 'react';
import FooterCss from './index.module.scss';
import gh from '../../../Source/Image/gh.png';
import qrcode from '../../../Source/Image/qrcode.png';

const Footer = () => {

    const footer_data_l = [
        {
            title: {
                name: '首页',
                url: '/'
            },
            content: [
                {
                    name: '产品介绍',
                    url: '/'
                },
                {
                    name: '快速开发',
                    url: '/'
                },
                {
                    name: '服务领域',
                    url: '/solution'
                },
                {
                    name: '关于我们',
                    url: '/about'
                },
                {
                    name: '招聘信息',
                    url: '/join'
                },
                {
                    name: '留言',
                    url: '/data_science/iot'
                },
            ]
        },
        {
            title: {
                name: '数据科学',
                url: '/data_science/data_collection'
            },
            content: [
                {
                    name: '数据采集',
                    url: '/data_science/data_collection'
                },
                {
                    name: '数据清洗',
                    url: '/data_science/data_clear'
                },
                {
                    name: '自然语言分析',
                    url: '/data_science/natural_language_analysis/lexical_analysis'
                },
                {
                    name: '数据分析',
                    url: '/data_science/data_analysis'
                },
            ]
        },
        {
            title: {
                name: '敏捷开发',
                url: '/agile_development'
            },
            content: [
                {
                    name: '低代码',
                    url: '/'
                },
                {
                    name: '核心优势',
                    url: '/'
                },
                {
                    name: '低代码+RPA+AI',
                    url: '/'
                },
            ]
        },
        {
            title: {
                name: '解决方案',
                url: '/solution'
            },
            content: [
                {
                    name: '制造业',
                    url: '/solution/intelligent_manufacturing'
                },
                {
                    name: '幼教行业',
                    url: '/solution/preschool_education_industry'
                },
                {
                    name: '化工贸易',
                    url: '/solution/fine_chemical_industry'
                },
            ]
        },
        {
            title: {
                name: '关于宜氪',
                url: '/about'
            },
            content: [
                {
                    name: '公司简介',
                    url: '/about'
                },
                {
                    name: '价值观',
                    url: '/about'
                },
                {
                    name: '核心理念',
                    url: '/about'
                },
            ]
        },
        {
            title: {
                name: '加入我们',
                url: '/join'
            },
            content: [
                {
                    name: '招聘信息',
                    url: '/join'
                },
            ]
        },
    ]

    const footer_data_r = {
        name: '上海宜氪数据科技有限公司',
        num: '400-169-0911',
        email: 'sales@ike-data.com',
        address: '上海市浦东新区纳贤路 800 号 1 幢 301-16 & 17 室',
        qrcode: qrcode,
        beian: '上海宜氪数据科技有限公司  版权所有  备案号：沪ICP备2020035994号-1'
    }

    const backToTop = () => {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
    }

    const gotoBeian = () => {
        console.log(12345);
        window.open('https://www.beian.gov.cn/portal/index.do')
    }

    return (
        <div className={`mdui-container-fluid bw-block-small`} style={{ backgroundColor: '#242424', color: '#fff' }}>
            <div className='mdui-container'>
                <div className="mdui-row">
                    <div className={`mdui-col-md-8 mdui-hidden-sm-down	 ${FooterCss.footer}`}>
                        {
                            footer_data_l.map(
                                item =>
                                    <div key={item.title.name} className="mdui-col-md-2">
                                        <div className="mdui-list">
                                            <a href={item.title.url} className={`mdui-list-item mdui-ripple ${FooterCss.footer_title}`}>{item.title.name}</a>
                                            {
                                                item.content.map(
                                                    item_content => <a key={item_content.name} href={item_content.url} className="mdui-list-item mdui-ripple">{item_content.name}</a>
                                                )
                                            }
                                        </div>
                                    </div>
                            )
                        }
                    </div>
                    <div className={`mdui-col-md-4 ${FooterCss.footer}`}>
                        <div className="mdui-list">
                            <li className={`mdui-list-item mdui-ripple ${FooterCss.footer_title}`}>
                                <div className='mdui-hidden-sm-down'>{footer_data_r.name}</div>
                                <div className='mdui-text-center mdui-hidden-md-up' style={{ width: '100%' }}>{footer_data_r.name}</div>
                            </li>
                            <li className="mdui-list-item mdui-ripple">
                                <div className='mdui-hidden-sm-down'>电话 :&nbsp;{footer_data_r.num}</div>
                                <div className='mdui-text-center mdui-hidden-md-up' style={{ width: '100%' }}>电话 :&nbsp;{footer_data_r.num}</div>
                            </li>
                            <li className="mdui-list-item mdui-ripple">
                                <div className='mdui-hidden-sm-down'>邮箱 :&nbsp;{footer_data_r.email}</div>
                                <div className='mdui-text-center mdui-hidden-md-up' style={{ width: '100%' }}>邮箱 :&nbsp;{footer_data_r.email}</div>
                            </li>
                            <li className="mdui-list-item mdui-ripple">
                                <div className='mdui-hidden-sm-down'>地址 :&nbsp;{footer_data_r.address}</div>
                                <div className='mdui-text-center mdui-hidden-md-up' style={{ width: '100%' }}>地址 :&nbsp;{footer_data_r.address}</div>
                            </li>
                            <li className="mdui-list-item mdui-ripple mdui-hidden-sm-down">
                                <figure>
                                    <img src={footer_data_r.qrcode} alt="" width="144px" />
                                    <figcaption className="mdui-text-center">关注公众号</figcaption>
                                </figure>
                            </li>
                        </div>
                    </div>
                    <div className={`mdui-col-xs-12 mdui-hidden-sm-down ${FooterCss.beian}`} onClick={gotoBeian}>
                        <img src={gh} alt='' width='12px' />
                        {footer_data_r.beian}
                    </div>
                    <div className={`mdui-col-xs-12 mdui-text-center mdui-hidden-md-up ${FooterCss.beian}`} onClick={gotoBeian}>
                        <img src={gh} alt='' width='12px' />
                        {footer_data_r.beian}
                    </div>
                    <button className="mdui-fab mdui-fab-fixed mdui-color-theme-accent mdui-ripple" onClick={backToTop} style={{ zIndex: '100' }}>
                        <i className="mdui-icon material-icons">&#xe5d8;</i>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Footer;
