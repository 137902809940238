import React from 'react';
import BlockTitle from '../../../../Components/Portal/BlockTitle';

const Expand12 = (props) => {
    const { item, layout } = props
    return (
        <div className="bw-block">
            <div className={`mdui-container${layout && `-${layout}`}`}>
                {
                    item.blockTitle && <BlockTitle content={item.blockTitle[0]} line={item.blockTitle[1]} />
                }
            </div>
        </div>
    );
}

export default Expand12;
