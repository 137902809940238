import React, { useState, useEffect } from 'react'
import { nanoid } from 'nanoid'
import HelmetToHead from '../../../Components/HelmetToHead'
import { HomeData } from '../../../Tools/ReDemoData'
import NotFound from '../../../Components/Portal/NotFound'
import CL1 from './ComponentLibrary/CL1'
import CL2 from './ComponentLibrary/CL2'
import CL3 from './ComponentLibrary/CL3'
import CL4 from './ComponentLibrary/CL4'
import CL5 from './ComponentLibrary/CL5'
import CL6 from './ComponentLibrary/CL6'
import CL7 from './ComponentLibrary/CL7'
import CL8 from './ComponentLibrary/CL8'

export default function DefaultTemplate(props) {
    const [componentLibrary, setComponentLibrary] = useState(null)
    useEffect(
        () => {
            setTimeout(() => {
                setComponentLibrary(HomeData)
            }, 0)
        }, []
    )
    return (
        <>
            {<HelmetToHead title={HomeData.title} description={HomeData.summary} />}
            {
                componentLibrary &&
                componentLibrary.components.map(item =>
                    item.template === "home_banner" ? <CL1 key={nanoid()} res_data={item.data} /> :
                        item.template === "slogan" ? <CL2 key={nanoid()} res_data={item.title} /> :
                            item.template === "common_title" ? <CL3 key={nanoid()} res_data={item.data} /> :
                                item.template === "slide_tab" ? <CL4 key={nanoid()} common_title={item.common_title} slide_data={item.slide_data} /> :
                                    item.template === "chain" ? <CL5 key={nanoid()} common_title={item.common_title} chain_data={item.chain_data} /> :
                                        item.template === "panel_card_1" ? <CL6 key={nanoid()} common_title={item.common_title} panel_card={item.panel_card} /> :
                                            item.template === "summary_banner" ? <CL7 key={nanoid()} res_data={item.data} /> :
                                                item.template === "panel_card_2" ? <CL8 key={nanoid()} common_title={item.common_title} panel_card={item.panel_card} /> :
                                                    <NotFound />
                )
            }
        </>
    )
}
