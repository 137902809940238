import banner1 from "../Source/Image/banner1.png";
import banner_h_r from "../Source/Image/banner_h_r.png";
import sw_bg from "../Source/Image/sw_bg.png";
import sw_cover1 from "../Source/Image/sw_cover1.png";
import sw_cover2 from "../Source/Image/sw_cover2.png";
import chain from "../Source/Image/chain.png";
import card_default_1 from "../Source/Image/card_default_1.png";
import card_default_2 from "../Source/Image/card_default_2.png";
import card_default_3 from "../Source/Image/card_default_3.png";
import about from "../Source/Image/about.png";

export const HomeData = {
    title: "宜氪数据一站式数据分析平台",
    summary: "宜氪数据一家为企业推动数字化转型的公司，用数学模型解决实际问题",
    components: [
        {
            template: "home_banner",
            data: {
                backgrand_image: banner1,
                top_cover: banner_h_r,
                title: "宜氪数据一站式数据分析平台",
                summary: "打通数据治理全流程，快速满足政企用户各类不同的数据治理场景",
                jump_to: "/"
            }
        },
        {
            template: "slogan",
            title: "宜氪数据一家为企业推动数字化转型的公司，用数学模型解决实际问题"
        },
        {
            template: "slide_tab",
            common_title: {
                line: true,
                detail: "Cheerio-自然语言处理及分析"
            },
            slide_data: [
                {
                    tab_title: "数据科学",
                    itemLIst: [
                        "数据采集",
                        "IoT"
                    ],
                    image: {
                        cover: sw_cover1,
                        background_image: sw_bg
                    }
                },
                {
                    tab_title: "数据清洗",
                    itemLIst: [
                        "封箱法",
                        "回归法",
                        "聚类法"
                    ],
                    image: {
                        cover: sw_cover2,
                        background_image: sw_bg
                    }
                },
                {
                    tab_title: "自然语言",
                    itemLIst: [
                        "词法分析",
                        "语义分析",
                        "篇章分析",
                        "文本分析"
                    ],
                    image: {
                        cover: sw_cover1,
                        background_image: sw_bg
                    }
                },
                {
                    tab_title: "数据分析",
                    itemLIst: [
                        "神经网络",
                        "相关性分析",
                        "组合分析",
                        "选择模型"
                    ],
                    image: {
                        cover: sw_cover2,
                        background_image: sw_bg
                    }
                }
            ]
        },
        {
            template: "chain",
            common_title: {
                line: true,
                detail: "业务分析与敏捷开发"
            },
            chain_data: {
                firstData: {
                    title: "成本低",
                    summary: "降低学习成本"
                },
                secondData: {
                    title: "效率高",
                    summary: "提升交付效率和协作效率"
                },
                thirdData: {
                    img: chain
                },
                fourthData: {
                    title: "市场广",
                    summary: "扩大应用开发劳动力"
                },
                fifthData: {
                    title: "资源多",
                    summary: "缓解低价值需求资源困境"
                },
                sixthData: {
                    title: "兼容好",
                    summary: "与协作系统、组织架构无缝集成"
                },
            }
        },
        {
            template: "panel_card_1",
            common_title: {
                line: true,
                detail: "不同的行业 共同的选择"
            },
            panel_card: [
                {
                    title: "智能制造",
                    summary: "Intelligent manufacturing",
                    bg: card_default_1
                },
                {
                    title: "幼教早教",
                    summary: "Preschool education early education",
                    bg: card_default_2
                },
                {
                    title: "精细化工",
                    summary: "Fine chemical industry",
                    bg: card_default_3
                }
            ]
        },
        {
            template: "summary_banner",
            data: {
                background_image: about,
                title: "上海宜氪数据科技有限公司",
                details: "IKE是一家数据智能技术企业，拥有完整的大数据和自然语言AI智能产品线，基于行业知识及数据中台建设经验，并于数字云、云计算、云安全等项目整合经验；提供面向企业数字化转型的行业解决方案，构建了企业级和SaaS服务两大业务体系，涉及数字医疗、新零售、智能制造和房地产等多个领域。致力于用数据智能促进企业智能化转型。"
            }
        }
    ]
}