import React from 'react'
import Expand_17Css from './index.module.scss'

export default function Expand_17(props) {
    return (
        <div className='mdui-container'>
            <div className='mdui-row'>
                <div className={`mdui-col-sm-4 mdui-text-center ${Expand_17Css.Expand_17}`}><div className={`${Expand_17Css.Expand_17_title}`}><span>◆</span> 教务管理</div></div>
                <div className={`mdui-col-sm-4 mdui-text-center ${Expand_17Css.Expand_17}`}><div className={`${Expand_17Css.Expand_17_title}`}><span>◆</span> 财务管理</div></div>
                <div className={`mdui-col-sm-4 mdui-text-center ${Expand_17Css.Expand_17}`}><div className={`${Expand_17Css.Expand_17_title}`}><span>◆</span> 经营管理</div></div>
                <div className={`mdui-col-xs-12 ${Expand_17Css.Expand_17_detail} mdui-valign`}>
                    <div className='mdui-center'>
                        宜氪数据通过低代码开发平台助其迅速迭代原有ERP/CRM系统、财务模块。通过高效信息收集系统，并辅之基于统计学数据分析方法，为幼儿做出个性化的后续服务课程，挖掘二次销售机会，同时也助力增加学校和幼儿家长之间的粘性。
                    </div>
                </div>
            </div>
        </div>
    )
}
