import React, {
    useEffect,
    // useState,
    Fragment
} from 'react';
import { useLocation } from 'react-router-dom';
import { nanoid } from 'nanoid';
import NotFound from '../../../Components/Portal/NotFound';
import BlockTitle from '../../../Components/Portal/BlockTitle';
import Expand1 from './Expand/Expand_1';
import Expand2 from './Expand/Expand_2';
import Expand3 from './Expand/Expand_3';
import Expand4 from './Expand/Expand_4';
import Expand5 from './Expand/Expand_5';
import Expand6 from './Expand/Expand_6';
import Expand7 from './Expand/Expand_7';
import Expand8 from './Expand/Expand_8';
import Expand9 from './Expand/Expand_9';
import Expand10 from './Expand/Expand_10';
import Expand11 from './Expand/Expand_11';
import Expand12 from './Expand/Expand_12';
import Expand13 from './Expand/Expand_13';
import Expand14 from './Expand/Expand_14';
import Expand15 from './Expand/Expand_15';
import Expand16 from './Expand/Expand_16';
import Expand17 from './Expand/Expand_17';
import {
    homeState,
    data_clear,
    semantic_analysis,
    data_analysis,
    dataScience,
    iot,
    agile_development,
    lexical_analysis,
    discourse_analysis,
    text_analysis,
    about_us,
    join_us,
    solution,
    preschoolEducationIndustry
} from '../../../Tools/DemoData';
import {
    homeStateHK,
    data_clearHK,
    semantic_analysisHK,
    data_analysisHK,
    dataScienceHK,
    iotHK,
    agile_developmentHK,
    lexical_analysisHK,
    discourse_analysisHK,
    text_analysisHK,
    about_usHK,
    join_usHK,
    solutionHK,
    preschoolEducationIndustryHK
} from '../../../Tools/DemoDataHK';
import {
    homeStateEN,
    data_clearEN,
    semantic_analysisEN,
    data_analysisEN,
    dataScienceEN,
    iotEN,
    agile_developmentEN,
    lexical_analysisEN,
    discourse_analysisEN,
    text_analysisEN,
    about_usEN,
    join_usEN,
    solutionEN,
    preschoolEducationIndustryEN
} from '../../../Tools/DemoDataEN';

// 注意Axios请求时,若请求不到数据, 查看异步请求结果

const CommonTemplate = (props) => {

    const backToTop = () => {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
    }

    backToTop()

    const { crumbs } = props
    const { pathname } = useLocation()
    // const [commonData, SetCommonData] = useState(false)
    const lang = localStorage.getItem('i18nextLng')

    let state = ''

    lang === 'zh-HK' ?
        state =
        pathname === "/home" ? homeStateHK :
            pathname === "/data_science/data_clear" ? data_clearHK :
                pathname === "/data_science/natural_language_analysis/semantic_analysis" ? semantic_analysisHK :
                    pathname === "/data_science/data_analysis" ? data_analysisHK :
                        pathname === "/data_science/data_collection" ? dataScienceHK :
                            pathname === "/data_science/iot" ? iotHK :
                                pathname === "/agile_development" ? agile_developmentHK :
                                    pathname === "/data_science/natural_language_analysis/lexical_analysis" ? lexical_analysisHK :
                                        pathname === "/data_science/natural_language_analysis/discourse_analysis" ? discourse_analysisHK :
                                            pathname === "/data_science/natural_language_analysis/text_analysis" ? text_analysisHK :
                                                pathname === "/about" ? about_usHK :
                                                    pathname === "/join" ? join_usHK :
                                                        pathname === "/solution" ? solutionHK :
                                                            pathname === "/solution/preschool_education_industry" ? preschoolEducationIndustryHK :
                                                                false
        :
        lang === 'en-US' ?
            state =
            pathname === "/home" ? homeStateEN :
                pathname === "/data_science/data_clear" ? data_clearEN :
                    pathname === "/data_science/natural_language_analysis/semantic_analysis" ? semantic_analysisEN :
                        pathname === "/data_science/data_analysis" ? data_analysisEN :
                            pathname === "/data_science/data_collection" ? dataScienceEN :
                                pathname === "/data_science/iot" ? iotEN :
                                    pathname === "/agile_development" ? agile_developmentEN :
                                        pathname === "/data_science/natural_language_analysis/lexical_analysis" ? lexical_analysisEN :
                                            pathname === "/data_science/natural_language_analysis/discourse_analysis" ? discourse_analysisEN :
                                                pathname === "/data_science/natural_language_analysis/text_analysis" ? text_analysisEN :
                                                    pathname === "/about" ? about_usEN :
                                                        pathname === "/join" ? join_usEN :
                                                            pathname === "/solution" ? solutionEN :
                                                                pathname === "/solution/preschool_education_industry" ? preschoolEducationIndustryEN :
                                                                    false
            :
            state =
            pathname === "/home" ? homeState :
                pathname === "/data_science/data_clear" ? data_clear :
                    pathname === "/data_science/natural_language_analysis/semantic_analysis" ? semantic_analysis :
                        pathname === "/data_science/data_analysis" ? data_analysis :
                            pathname === "/data_science/data_collection" ? dataScience :
                                pathname === "/data_science/iot" ? iot :
                                    pathname === "/agile_development" ? agile_development :
                                        pathname === "/data_science/natural_language_analysis/lexical_analysis" ? lexical_analysis :
                                            pathname === "/data_science/natural_language_analysis/discourse_analysis" ? discourse_analysis :
                                                pathname === "/data_science/natural_language_analysis/text_analysis" ? text_analysis :
                                                    pathname === "/about" ? about_us :
                                                        pathname === "/join" ? join_us :
                                                            pathname === "/solution" ? solution :
                                                                pathname === "/solution/preschool_education_industry" ? preschoolEducationIndustry :
                                                                    false


useEffect(() => {
    const old_title = (document.title).split(' ')
    document.title = `${state.title ? state.title : ''} ${old_title[old_title.length - 1]}`
})

return (
    <>
        {
            state ?
                state.model_item.map(item =>
                    item.status === true ?
                        item.expand === "expand_1" ? <Expand1 item={item} key={nanoid()} /> :
                            item.expand === "expand_2" && state.subPage === true ? <Expand2 layout={state.layout} crumbs={crumbs} key={nanoid()} /> :
                                item.expand === "expand_3" ? <Expand3 layout={state.layout} item={item} key={nanoid()} /> :
                                    item.expand === "expand_4" ? <Expand4 layout={state.layout} subPage={state.subPage} item={item} key={nanoid()} /> :
                                        item.expand === "expand_5" ? <Expand5 layout={state.layout} item={item} key={nanoid()} /> :
                                            item.expand === "expand_6" ? <Expand6 layout={state.layout} subPage={state.subPage} item={item} key={nanoid()} /> :
                                                item.expand === "expand_7" ? <Expand7 layout={state.layout} item={item} key={nanoid()} /> :
                                                    item.expand === "expand_8" ? <Expand8 layout={state.layout} subPage={state.subPage} item={item} key={nanoid()} /> :
                                                        item.expand === "expand_9" ? <Expand9 layout={state.layout} item={item} key={nanoid()} /> :
                                                            item.expand === "expand_10" ? <Expand10 layout={state.layout} item={item} key={nanoid()} /> :
                                                                item.expand === "expand_11" ? <Expand11 layout={state.layout} item={item} key={nanoid()} /> :
                                                                    item.expand === "expand_12" ? <Expand12 layout={state.layout} item={item} key={nanoid()} /> :
                                                                        item.expand === "expand_13" ? <Expand13 layout={state.layout} item={item} key={nanoid()} /> :
                                                                            item.expand === "expand_14" ? <Expand14 layout={state.layout} item={item} key={nanoid()} /> :
                                                                                item.expand === "expand_15" ? <Expand15 layout={state.layout} subPage={state.subPage} item={item} key={nanoid()} /> :
                                                                                    item.expand === "expand_16" ? <Expand16 layout={state.layout} subPage={state.subPage} item={item} key={nanoid()} /> :
                                                                                        item.expand === "expand_17" ? <Expand17 layout={state.layout} subPage={state.subPage} item={item} key={nanoid()} /> :
                                                                                            item.expand === "expand_18" && <BlockTitle item={item} key={nanoid()} /> :
                        <Fragment key={nanoid()}></Fragment>) :
                <NotFound />
        }
    </>
);
}

export default CommonTemplate;
