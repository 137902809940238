import React, { useEffect } from 'react';
import { nanoid } from 'nanoid';
import { useTranslation } from 'react-i18next';
import Banner from '../../../Components/Portal/Banner';
import BreadItem from '../../../Components/Portal/BreadItem';
import { dataScienceList } from '../../../Tools/DemoData';
import { dataScienceListHK } from '../../../Tools/DemoDataHK';
import { dataScienceListEN } from '../../../Tools/DemoDataEN';
import DataScienceListCss from './index.module.scss';

const DataScienceList = (param) => {
    const { crumbs } = param
    const lang = localStorage.getItem('i18nextLng')

    let dsl = ''
    lang === 'zh-HK' ? dsl = dataScienceListHK : lang === 'en-US' ? dsl = dataScienceListEN : dsl = dataScienceList

    const { title, banner, dataItem, cat } = dsl
    const { t } = useTranslation()

    useEffect(() => {
        const m = (document.title).split(' ')
        document.title = `${title} ${m[m.length - 1]}`
    })

    return (
        <>
            <Banner state={banner} />
            <div className='mdui-container'>
                <div className='mdui-row'>
                    <div className='mdui-col-xs-12'>
                        <BreadItem bread={crumbs} />
                    </div>
                </div>
                <div className='mdui-row'>
                    <div className='mdui-col-md-4 mdui-typo'>
                        <div className={`${DataScienceListCss.header} bw_theme_blue bw_theme_blue_btn`}>
                            {t("Trans.Cat")}
                        </div>
                        {
                            cat.map(
                                item =>
                                    <div key={nanoid()} className={`mdui-col-xs-12 ${DataScienceListCss.header_contentList}`}>
                                        <a href={item.url} >{item.name}</a>
                                    </div>
                            )
                        }
                    </div>
                    <div className='mdui-col-md-8'>
                        <div className={`mdui-col-xs-12 mdui-typo`}>
                            <div className='mdui-row bw_theme_blue bw_theme_blue_btn'>
                                <div className={`mdui-col-xs-8 ${DataScienceListCss.header}`}>
                                    {t("Trans.DataTitle")}
                                </div>
                                <div className={`mdui-col-xs-4 ${DataScienceListCss.header}`}>
                                    {t("Trans.Time")}
                                </div>
                            </div>
                            {
                                dataItem.map(
                                    item =>
                                        <div key={item.id} className={`mdui-row ${DataScienceListCss.header_contentList}`}>
                                            <div className='mdui-col-xs-8 mdui-typo'>
                                                <a href={item.url}>{item.title}</a>
                                            </div>
                                            <div className='mdui-col-xs-4'>
                                                {item.time}
                                            </div>
                                        </div>
                                )
                            }
                        </div>
                        <div className='mdui-xs-col-12'>
                            <div className={`mdui-btn-group ${DataScienceListCss.pageBtn}`}>
                                <button type="button" className="mdui-btn">
                                    {t("Trans.PreviousPage")}
                                </button>
                                <button type="button" className="mdui-btn mdui-btn-active">
                                    1
                                </button>
                                <button type="button" className="mdui-btn">
                                    2
                                </button>
                                <button type="button" className="mdui-btn">
                                    {t("Trans.NextPage")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default DataScienceList;
