import banner5 from "../Source/Image/banner5.png";
import banner1 from "../Source/Image/banner1.png";
import banner2 from "../Source/Image/banner2.png";
import banner3 from "../Source/Image/banner3.png";
import banner6 from "../Source/Image/banner6.png";
import banner7 from "../Source/Image/banner7.png";
import banner8 from "../Source/Image/banner8.png";
import banner9 from "../Source/Image/banner9.png";
import banner10 from "../Source/Image/banner10.png";
import about from "../Source/Image/about.png";
import banner_h_r from "../Source/Image/banner_h_r.png";
import sw_cover1 from "../Source/Image/sw_cover1.png";
import sw_bg from "../Source/Image/sw_bg.png";
import sw_cover2 from "../Source/Image/sw_cover2.png";
import chain_png from "../Source/Image/chain.png";
import content_cover_1 from "../Source/Image/content_cover_1.png";
import content_cover_2 from "../Source/Image/content_cover_2.png";
import content_cover_3 from "../Source/Image/content_cover_3.png";
import content_cover_4 from "../Source/Image/content_cover_4.png";
import content_cover_5 from "../Source/Image/content_cover_5.png";
import card_default_1 from "../Source/Image/card_default_1.png";
import card_default_2 from "../Source/Image/card_default_2.png";
import card_default_3 from "../Source/Image/card_default_3.png";
import card_default_4 from "../Source/Image/card_default_4.png";
import card_default_5 from "../Source/Image/card_default_5.png";
import ike_data_01 from "../Source/Image/ike_data_01.png";
import ike_data_02 from "../Source/Image/ike_data_02.png";
import ike_data_03 from "../Source/Image/ike_data_03.png";
import ike_data_04 from "../Source/Image/ike_data_04.png";
import ike_data_05 from "../Source/Image/ike_data_05.png";
import ike_data_06 from "../Source/Image/ike_data_06.png";
import preschool from "../Source/Image/preschool.png";
import chemical from "../Source/Image/chemical.png";
import intelligent from "../Source/Image/intelligent.png";

// 首页
export const homeStateHK = {
    layout: "", // fluid
    subPage: false, // 判断是否为子页面
    title: "宜氪數據",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "宜氪數據一站式數據分析平台",
                summary: "打通數據治理全流程，快速滿足政企用戶各類不同的數據治理場景",
                bg: banner1,
                img: banner_h_r,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// banner横幅文字
            expand: "expand_3",
            status: true,// banner横幅文字, 值为true时,才会展示
            bannerText: "宜氪數據一家為企業推動數字化轉型的公司，用數學模型解決實際問題"
        },
        {// 首页轮播图
            expand: "expand_4",
            status: true,
            blockTitle: {
                line: true,
                content: 'Cheerio-自然語言處理及分析',
                wide: false,
            },
            sw_data: [
                {
                    title: "數據採集",
                    itemLIst: [
                        "數據採集",
                        "IoT"
                    ],
                    img: {
                        cover: sw_cover1,
                        bg: sw_bg
                    }
                },
                {
                    title: "數據清洗",
                    itemLIst: [
                        "封類法",
                        "回歸法",
                        "聚類法"
                    ],
                    img: {
                        cover: sw_cover2,
                        bg: sw_bg
                    }
                },
                {
                    title: "自然語言分析",
                    itemLIst: [
                        "詞法分析",
                        "語義分析",
                        "篇章分析",
                        "文本分析"
                    ],
                    img: {
                        cover: sw_cover1,
                        bg: sw_bg
                    }
                },
                {
                    title: "數據分析",
                    itemLIst: [
                        "神經網絡",
                        "相關性分析",
                        "組合分析",
                        "選擇模型"
                    ],
                    img: {
                        cover: sw_cover2,
                        bg: sw_bg
                    }
                },
            ],
        },
        {// 六边形图文组件
            expand: "expand_5",
            status: true,
            blockTitle: {
                line: true,
                content: '業務分析與敏捷開發',
                wide: false,
            },
            // blockTitle: [
            //     "業務分析與敏捷開發",
            //     true
            // ],
            chain_data: {
                firstData: {
                    title: "成本低",
                    summary: "降低學習成本"
                },
                secondData: {
                    title: "效率高",
                    summary: "提升交付效率和協作效率"
                },
                thirdData: {
                    img: chain_png
                },
                fourthData: {
                    title: "市場廣",
                    summary: "擴大應用開發勞動力"
                },
                fifthData: {
                    title: "資源多",
                    summary: "緩解低價值需求資源困境"
                },
                sixthData: {
                    title: "兼容好",
                    summary: "與協作系統、組織架構無縫集成"
                },
            },
        },
        {// 解决方案
            expand: "expand_6",
            status: true,
            blockTitle: {
                line: true,
                content: '不同的行業 共同的選擇',
                wide: false,
            },
            card_data: [
                {
                    title: "智能製造",
                    summary: "Intelligent manufacturing",
                    bg: card_default_1
                },
                {
                    title: "幼教早教",
                    summary: "Preschool education early education",
                    bg: card_default_2
                },
                {
                    title: "精細化工",
                    summary: "Fine chemical industry",
                    bg: card_default_3
                }
            ],
        },
        {// 标准图文组件
            expand: "expand_7",
            status: true,
            template_background: about,
            title: "上海宜氪數據科技有限公司",
            summary: "IKE是一家數據智能技術企業，擁有完整的大數據和自然語言AI智能產品線，基於行業知識及數據中台建設經驗，並於數字雲、雲計算、雲安全等項目整合經驗；提供面向企業數字化轉型的行業解決方案，構建了企業級和SaaS服務兩大業務體系，涉及數字醫療、新零售、智能製造和房地產等多個領域。致力於用數據智能促進企業智能化轉型。",
            background_image: ""
        },
        {// 招聘信息组件
            expand: "expand_8",
            status: true,
            blockTitle: {
                line: true,
                content: '期待優秀的你 加入我們',
                wide: false,
            },
            card_data: [
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/134972928.html?s=gsxq_zwlb_gsxqlb&t=17",
                    title: "數據分析工程師",
                    summary: [
                        "運用數據挖掘/統計學習的理論和方法，深入挖掘和分析用戶行為、業務數據",
                        "負責數據清洗相關策略工作，包括對虛假、重復、低質等數據的發現及處理",
                        "負責數據探索、數據特徵分析、數據挖掘的驗證及建模工作",
                        "編寫數據分析報告"
                    ],
                    detail: [
                        "本科及以上學歷，統計學、計算機科學等相關專業背景",
                        "兩年以上汽車等製造業數據分析工作經驗考慮",
                        "瞭解數據分析的主要流程，關聯分析、分類預測、協同過濾、聚類分析、回歸分析、時間序列分析等常用分析方法",
                        "熟悉Python語言的編寫，能夠用python數據建模和相關算法能力",
                        "熟悉目前流行數據庫，熟練使用SQL語句等",
                        "具備責任心和良好的團隊協作精神，樂於溝通交流和分享，充滿激情，樂於接受挑戰"
                    ]
                },
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/136524178.html?s=sou_sou_soulb&t=0_0",
                    title: "Python後端開發工程師",
                    summary: [
                        "本科及以上學歷，計算機相關專業",
                        "具有良好的數據結構和算法基礎，具備扎實的編程能力",
                        "3年以上python web框架(Django或Flask)開發經驗，掌握RESTful API的開發思想，熱愛編程、具有良好的代碼風格，瞭解Python的高級特性",
                        "熟悉常用數據庫，精通MySQL，Redis等，熟悉數據庫優化，瞭解非關係型數據庫例如MongoDB",
                        "具有構建分布式任務系統經驗（Celery/RabbitMQ/Redis）優先",
                        "熟悉Linux操作系統，瞭解docker，能夠進行日常服務的測試部署",
                        "瞭解前端相關技術，React、HTML、JS、CSS等",
                        "良好的自學能力、獨立解決問題的能力及溝通與團隊協作能力",
                        "獨立思考，有產品意識，能提出系統改善和產品優化者優先"
                    ],
                    detail: []
                },
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/129315562.html?s=gsxq_zwlb_gsxqlb&t=17",
                    title: "自然語言處理工程師",
                    summary: [
                        "利用自然語言處理技術，從非結構化的知識中提取實體和關係，構建知識圖譜",
                        "利用自然語言處理技術，從文章、商品描述、評價等信息中進行NER、關係抽取等，優化和完善團隊的核心知識圖譜",
                        "基於知識圖譜和自然語言處理技術，構建語料庫，實現自動摘要，情感分析，意圖識別，輿情分析等功能"
                    ],
                    detail: []
                },
            ],
        },
        {// feedback的数据
            expand: "expand_9",
            status: true,
            blockTitle: {
                line: true,
                content: '我們可以為您提供此服務',
                wide: true,
            },
        }
    ],
}

// 数据清洗
export const data_clearHK = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "數據清洗",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "數據清洗",
                summary: "大數據離不開數據分析，數據分析離不開數據，海量的數據中有很多是我們我們需要的數據，也有很多我們不需要的數據。正如世界上沒有完全純淨的東西，數據也會存在雜質，這就需要我們對數據進行清洗才能保證數據的可靠性。",
                bg: banner5,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: '數據清洗'
        },
        {// 数据清洗界面数据
            expand: "expand_11",
            status: true,
            img_text: {
                feedback: true,
                shadow: true,
                context: [
                    {
                        id: "1",
                        img: content_cover_1,
                        title: "分箱法",
                        sumary:
                            "分箱法是一個經常使用到方法，所謂的分箱法，就是將需要處理的數據根據一定的規則放進箱子里，然後進行測試每一個箱子里的數據，並根據數據中的各個箱子的實際情況進行採取方法處理數據。看到這裡很多朋友只是稍微明白了，但是並不知道怎麼分箱。如何分箱呢？我們可以按照記錄的行數進行分箱，使得每箱有一個相同的記錄數。或者我們把每個箱的區間範圍設置一個常數，這樣我們就能夠根據區間的範圍進行分箱。其實我們也可以自定義區間進行分箱。這三種方式都是可以的。分好箱號，我們可以求每一個箱的平均值，中位數、或者使用極值來繪制折線圖，一般來說，折線圖的寬度越大，光滑程度也就越明顯。",
                    },
                    {
                        id: "2",
                        img: content_cover_2,
                        title: "回歸法",
                        sumary:
                            "回歸法就是利用了函數的數據進行繪製圖像，然後對圖像進行光滑處理。回歸法有兩種，一種是單線性回歸，一種是多線性回歸。單線性回歸就是找出兩個屬性的最佳直線，能夠從一個屬性預測另一個屬性。多線性回歸就是找到很多個屬性，從而將數據擬合到一個多維面，這樣就能夠消除噪聲。",
                    },
                    {
                        id: "3",
                        img: content_cover_3,
                        title: "聚類法",
                        sumary:
                            "聚類法的工作流程是比較簡單的，但是操作起來確實複雜的，所謂聚類法就是將抽象的對象進行集合分組，成為不同的集合，找到在集合意外的孤點，這些孤點就是噪聲。這樣就能夠直接發現噪點，然後進行清除即可。",
                    },
                ]
            }
        },
    ],
}

// 语义分析
export const semantic_analysisHK = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "語義分析",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "語義分析",
                summary: "分析句子各個語言單位之間的語義關聯，並將語義關聯以依存結構呈現。",
                bg: banner5,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: '语义分析'
        },
        {// 语义分析界面数据
            expand: "expand_11",
            status: true,
            img_text: {
                feedback: false,
                shadow: true,
                context: [
                    {
                        id: "1",
                        img: content_cover_4,
                        title: "分箱法",
                        sumary:
                            "分箱法是一個經常使用到方法，所謂的分箱法，就是將需要處理的數據根據一定的規則放進箱子里，然後進行測試每一個箱子里的數據，並根據數據中的各個箱子的實際情況進行採取方法處理數據。看到這裡很多朋友只是稍微明白了，但是並不知道怎麼分箱。如何分箱呢？我們可以按照記錄的行數進行分箱，使得每箱有一個相同的記錄數。或者我們把每個箱的區間範圍設置一個常數，這樣我們就能夠根據區間的範圍進行分箱。其實我們也可以自定義區間進行分箱。這三種方式都是可以的。分好箱號，我們可以求每一個箱的平均值，中位數、或者使用極值來繪制折線圖，一般來說，折線圖的寬度越大，光滑程度也就越明顯。",
                    },
                    {
                        id: "2",
                        img: content_cover_5,
                        title: "回归法",
                        sumary:
                            "回歸法就是利用了函數的數據進行繪製圖像，然後對圖像進行光滑處理。回歸法有兩種，一種是單線性回歸，一種是多線性回歸。單線性回歸就是找出兩個屬性的最佳直線，能夠從一個屬性預測另一個屬性。多線性回歸就是找到很多個屬性，從而將數據擬合到一個多維面，這樣就能夠消除噪聲。",
                    },
                ]
            }
        },
    ],
}

// 数据挖掘分析
export const data_analysisHK = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "數據挖掘分析",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "數據挖掘分析",
                summary: "利用最先進的數據挖掘技術，為企業捕捉全網信息，基於海量數據和精准算法，就網站流量走勢、用戶行為、產品銷量等進行準確預測。",
                bg: banner5,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: '数据挖掘分析'
        },
    ],
}

// 数据科学(数据采集)
export const dataScienceHK = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "數據採集",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "數據採集",
                summary: "大數據離不開數據分析，數據分析離不開數據，海量的數據中有很多是我們我們需要的數據，也有很多我們不需要的數據。正如世界上沒有完全純淨的東西，數據也會存在雜質，這就需要我們對數據進行清洗才能保證數據的可靠性。",
                bg: banner2,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 数据科学(数据采集)界面数据
            expand: "expand_10",
            status: true,
            blockTitle:
            {// 标题
                line: false,
                content: '數據採集'
            },
            card: [
                {
                    cover: card_default_4,
                    title: "數據採集",
                    summary: "是一種按照一定的規則，自動地抓取萬維網信息的程序或者腳本。另外一些不常使用的名字還有螞蟻、自動索引、模擬程序或者蠕蟲。",
                    url: "/data_science/data_collection/resultlist"
                },
                {
                    cover: card_default_5,
                    title: "IoT",
                    summary: "物聯網通過溫濕度傳感器、氣體傳感器、視頻傳感器等外部硬件設備與系統進行通信，將傳感器監測到的數據傳至系統中進行採集使用。",
                    url: "/data_science/iot"
                },
            ],
        },
    ],
}

// 数据采集结果
export const dataScienceListHK = {
    title: "數據採集結果",
    banner: {
        title: "數據採集",
        summary: "全網海量數據資源向你敞開，探索數據的無限可能",
        bg: banner3,
    },
    cat: [
        {
            name: "財經",
            url: "/cat1/"
        },
        {
            name: "汽車之家",
            url: "/cat1/"
        }
    ],
    dataItem: [
        {
            id: 1,
            title: "HJ 1128-2020 核動力廠核事故環境應急監測技術規範",
            time: "2022-01-17",
            url: "/data_science/data_collection/resultlist/detail/"
        },
        {
            id: 2,
            title: "HJ 1128-2020 核動力廠核事故環境應急監測技術規範",
            time: "2022-01-17",
            url: "/data_science/data_collection/resultlist/detail/"
        },
        {
            id: 3,
            title: "HJ 1128-2020 核動力廠核事故環境應急監測技術規範",
            time: "2022-01-17",
            url: "/data_science/data_collection/resultlist/detail/"
        },
        {
            id: 4,
            title: "HJ 1128-2020 核動力廠核事故環境應急監測技術規範",
            time: "2022-01-17",
            url: "/data_science/data_collection/resultlist/detail/"
        },
        {
            id: 5,
            title: "HJ 1128-2020 核動力廠核事故環境應急監測技術規範",
            time: "2022-01-17",
            url: "/data_science/data_collection/resultlist/detail/"
        },
        {
            id: 6,
            title: "HJ 1128-2020 核動力廠核事故環境應急監測技術規範",
            time: "2022-01-17",
            url: "/data_science/data_collection/resultlist/detail/"
        },
    ]
}

// 数据采集结果详情
export const dataScienceListDetailHK = {
    title: "數據採集結果詳情",
    banner: {
        title: "數據採集",
        summary: "全網海量數據資源向你敞開，探索數據的無限可能",
        bg: banner3,
    },
    file_data: {
        Title: "HJ 1128-2020 核動力廠核事故環境應急監測技術規範",
        FileType: "Excel 格式",
        FileSize: "236.00KB",
        RecommendTheWebsite: {
            url: "www.ike-data.com",
            title: "www.ike-data.com"
        },
        Authorization: "免費下載"
    }
}

// IoT（物聯網）
export const iotHK = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "IoT（物聯網）",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "IoT（物聯網）",
                summary: "共同營造健康的互聯網生態環境",
                bg: banner3,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// feedback的数据
            expand: "expand_9",
            status: true,
            blockTitle:
            {// 标题
                line: false,
                content: '我们可以为您提供此服务'
            },
        }
    ],
}

// 敏捷开发
export const agile_developmentHK = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "敏捷開發",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "低代碼+AI如何為企業數字化修路",
                summary: "低門檻高效率，行業軟件客戶化開發的新選擇",
                bg: banner5,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: '低代碼為何而生'
        },
        {// 数据清洗界面数据
            expand: "expand_11",
            status: true,
            img_text: {
                feedback: false,
                shadow: true,
                context: [
                    {
                        id: "1",
                        img: ike_data_02,
                        title: "",
                        sumary: "低代碼開發平台（LCDP）本身也是一種軟件，它為開發者提供了一個創建應用軟件的開發環境。與傳統代碼IDE不同的是，低代碼開發平台提供的是更高維和易用的可視化IDE。大多數情況下，開發者並不需要使用傳統的手寫代碼方式進行編程，而是可以通過圖形化拖拽、參數配置等更高效的方式完成開發工作。簡單來說，低代碼開發麵向每個人，讓人們不管是否懂編程、不管是何職業，都能快速的設計出一個管理應用。隨著高級編程語言不斷發展成熟，以及國內外計算機人才的培養規模逐漸擴大，2010-2015年稱得上是傳統軟件和SaaS軟件興起的時代，編程人員發現：軟件的功能大同小異，重復度很高，導致很大部分的軟件開發成本都浪費在重復的功能編程上。隨著這些概念的日漸成熟，國外軟件廠商就陸續發佈出低代碼或零代碼開發平台，探索並證明瞭這類產品成功的可能性。基於外國的成功初探，中國市場也掀起了「低代碼/零代碼」的熱潮，並在近兩年逐步形成完整的產品生態體系。",
                    },
                ]
            }
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: '低代码+RPA+AI'
        },
        {// 大尺寸图块
            expand: "expand_15",
            status: true,
            img: ike_data_03
        },
    ],
}

// 关于宜氪
export const about_usHK = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "關於我們",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "關於我們",
                summary: "About us",
                bg: banner7,
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 数据清洗界面数据
            expand: "expand_11",
            status: true,
            img_text: {
                feedback: false,
                shadow: false,
                context: [
                    {
                        id: "1",
                        img: ike_data_04,
                        title: "公司介紹",
                        sumary: "IKE是一家數據智能技術企業，擁有完整的大數據和自然語言AI智能產品線，基於行業知識及數據中台建設經驗，並於數字雲、雲計算、雲安全等項目整合經驗；提供面向企業數字化轉型的行業解決方案，構建了企業級和SaaS服務兩大業務體系，涉及數字醫療、新零售、智能製造和房地產等多個領域。致力於用數據智能促進企業智能化轉型。-2015年稱得上是傳統軟件和SaaS軟件興起的時代，編程人員發現：軟件的功能大同小異，重復度很高，導致很大部分的軟件開發成本都浪費在重復的功能編程上。隨著這些概念的日漸成熟，國外軟件廠商就陸續發佈出低代碼或零代碼開發平台，探索並證明瞭這類產品成功的可能性。基於外國的成功初探，中國市場也掀起了「低代碼/零代碼」的熱潮，並在近兩年逐步形成完整的產品生態體系。",
                    },
                ]
            }
        },
        {// 大尺寸图块
            expand: "expand_15",
            status: true,
            img: ike_data_05,
            blockTitle:
            {// 标题
                expand: "expand_18",
                status: true,
                line: false,
                content: '核心價值觀'
            },
        },
        {// 标准图文组件
            expand: "expand_7",
            status: true,
            template_background: about,
            title: "我們的戰略思想",
            summary: "使命：聚焦大數據時代客戶的需求和挑戰，提供自主研發的一流軟件和服務，持續為客戶創造最大價值 核心價值觀：公司的核心價值觀是我們的信念，是我們前進的內在動力，也是對用戶及員工的承諾 願景:讓信息創造價值",
            background_image: banner8
        },
    ],
}

// 词法分析
export const lexical_analysisHK = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "詞法分析",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "詞法分析",
                summary: "基於大數據和用戶行為，提供分詞、詞性標注、命名實體識別，定位基本語言元素，全面支撐機器對基礎文本的理解與分析。",
                bg: banner6,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: '功能介紹'
        },
        {// 列表图文
            expand: "expand_13",
            status: true,
            instructions: [
                {
                    title: "分詞",
                    summary: "指的是將漢字序列切分成詞序列。漢語中，詞是承載語義的最基本的單元。分詞是信息檢索、文本分類、情感分析等多項中文自然語言處理任務的基礎",
                },
                {
                    title: "詞性標注",
                    summary: "是指為自然語言文本中的每個詞彙賦予一個詞性的過程",
                },
                {
                    title: "命名實體識別",
                    summary: "是在句子的詞序列中定位並識別人名、地名、機構名等實體",
                }
            ],
            cover: ike_data_01,
        },
        {// demo展示组件
            expand: "expand_14",
            status: true,
            active_model: false,
            post_to: [
                {
                    req_path: '/demo/lexical_analysis/',
                    model: [
                        'text',
                        'document'
                    ]
                }
            ]
        },
    ]
}

// 篇章分析
export const discourse_analysisHK = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "篇章分析",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "篇章分析",
                summary: "語義角色標注 (Semantic Role Labeling, SRL) 是一種淺層的語義分析技術，標注句子中某些短語為給定謂詞的論元 (語義角色) ，如施事者、受事者、經驗者、時間和地點等。",
                bg: banner6,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        // {// 标题
        //     expand: "expand_18",
        //     status: true,
        //     line: false,
        //     content: '功能介紹'
        // },
        {// demo展示组件
            expand: "expand_14",
            status: true,
            active_model: true,
            blockTitle: {// 标题
                expand: "expand_18",
                status: true,
                line: false,
                content: '功能介紹'
            },
            post_to: [
                {
                    icon: "icon-fl1",
                    title: "文本分類",
                    req_path: "/demo/text_classification/",
                    describe: {
                        title: "文本分類",
                        summary: "自動對漢語文本中的拼寫、語法、標點、敏感詞等多種問題進行糾錯校對，提示錯誤位置並返回修改建議。"
                    },
                    model: [
                        'text'
                    ]
                },
                {
                    icon: "icon-tq",
                    title: "關鍵詞提取",
                    req_path: "/demo/keyword_extraction/",
                    describe: {
                        title: "關鍵詞提取",
                        summary: "自動對漢語文本中的拼寫、語法、標點、敏感詞等多種問題進行糾錯校對，提示錯誤位置並返回修改建議。"
                    },
                    model: [
                        'text'
                    ]
                },
                {
                    icon: "icon-emo",
                    title: "情感分析",
                    req_path: "/demo/sentiment_analysis/",
                    describe: {
                        title: "情感分析",
                        summary: "自動對漢語文本中的拼寫、語法、標點、敏感詞等多種問題進行糾錯校對，提示錯誤位置並返回修改建議。"
                    },
                    model: [
                        'text'
                    ]
                },
                {
                    icon: "icon-cy",
                    title: "文章摘要",
                    req_path: "/demo/abstract_of_the_article/",
                    describe: {
                        title: "文章摘要",
                        summary: "自動對漢語文本中的拼寫、語法、標點、敏感詞等多種問題進行糾錯校對，提示錯誤位置並返回修改建議。"
                    },
                    model: [
                        'text'
                    ]
                },
            ],
        },
    ]
}

// 文本分析
export const text_analysisHK = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "文本分析",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "文本分析",
                summary: "語義角色標注 (Semantic Role Labeling, SRL) 是一種淺層的語義分析技術，標注句子中某些短語為給定謂詞的論元 (語義角色) ，如施事者、受事者、經驗者、時間和地點等。",
                bg: banner6,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: '功能介紹'
        },
        {// demo展示组件
            expand: "expand_14",
            status: true,
            active_model: true,
            post_to: [
                {
                    icon: "icon-xsd",
                    title: "文本相似度",
                    req_path: "/",
                    describe: {
                        title: "文本相似度",
                        summary: "自動對漢語文本中的拼寫、語法、標點、敏感詞等多種問題進行糾錯校對，提示錯誤位置並返回修改建議。"
                    },
                    model: [
                        'text'
                    ]
                },
                {
                    icon: "icon-xc",
                    title: "新詞發現",
                    req_path: "/",
                    describe: {
                        title: "新詞發現",
                        summary: "自動對漢語文本中的拼寫、語法、標點、敏感詞等多種問題進行糾錯校對，提示錯誤位置並返回修改建議。"
                    },
                    model: [
                        'text'
                    ]
                },
                {
                    icon: "icon-jc",
                    title: "文本糾錯",
                    req_path: "/",
                    describe: {
                        title: "文本糾錯",
                        summary: "自動對漢語文本中的拼寫、語法、標點、敏感詞等多種問題進行糾錯校對，提示錯誤位置並返回修改建議。"
                    },
                    model: [
                        'text'
                    ]
                },
                {
                    icon: "icon-cz",
                    title: "詞組相似度",
                    req_path: "/",
                    describe: {
                        title: "詞組相似度",
                        summary: "自動對漢語文本中的拼寫、語法、標點、敏感詞等多種問題進行糾錯校對，提示錯誤位置並返回修改建議。"
                    },
                    model: [
                        'text'
                    ]
                },
            ]
        },
    ]
}

// 加入我们
export const join_usHK = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "加入宜氪",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "加入我們",
                summary: "Join us",
                bg: banner9,
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: '期待優秀的你 加入我們'
        },
        {// 招聘信息组件
            expand: "expand_8",
            status: true,
            card_data: [
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/134972928.html?s=gsxq_zwlb_gsxqlb&t=17",
                    title: "數據分析工程師",
                    summary: [
                        "運用數據挖掘/統計學習的理論和方法，深入挖掘和分析用戶行為、業務數據",
                        "負責數據清洗相關策略工作，包括對虛假、重復、低質等數據的發現及處理",
                        "負責數據探索、數據特徵分析、數據挖掘的驗證及建模工作",
                        "編寫數據分析報告"
                    ],
                    detail: [
                        "本科及以上學歷，統計學、計算機科學等相關專業背景",
                        "兩年以上汽車等製造業數據分析工作經驗考慮",
                        "瞭解數據分析的主要流程，關聯分析、分類預測、協同過濾、聚類分析、回歸分析、時間序列分析等常用分析方法",
                        "熟悉Python語言的編寫，能夠用python數據建模和相關算法能力",
                        "熟悉目前流行數據庫，熟練使用SQL語句等",
                        "具備責任心和良好的團隊協作精神，樂於溝通交流和分享，充滿激情，樂於接受挑戰"
                    ]
                },
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/136524178.html?s=sou_sou_soulb&t=0_0",
                    title: "Python後端開發工程師",
                    summary: [
                        "本科及以上學歷，計算機相關專業",
                        "具有良好的數據結構和算法基礎，具備扎實的編程能力",
                        "3年以上python web框架(Django或Flask)開發經驗，掌握RESTful API的開發思想，熱愛編程、具有良好的代碼風格，瞭解Python的高級特性",
                        "熟悉常用數據庫，精通MySQL，Redis等，熟悉數據庫優化，瞭解非關係型數據庫例如MongoDB",
                        "具有構建分布式任務系統經驗（Celery/RabbitMQ/Redis）優先",
                        "熟悉Linux操作系統，瞭解docker，能夠進行日常服務的測試部署",
                        "瞭解前端相關技術，React、HTML、JS、CSS等",
                        "良好的自學能力、獨立解決問題的能力及溝通與團隊協作能力",
                        "獨立思考，有產品意識，能提出系統改善和產品優化者優先"
                    ],
                    detail: []
                },
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/129315562.html?s=gsxq_zwlb_gsxqlb&t=17",
                    title: "自然語言處理工程師",
                    summary: [
                        "利用自然語言處理技術，從非結構化的知識中提取實體和關係，構建知識圖譜",
                        "利用自然語言處理技術，從文章、商品描述、評價等信息中進行NER、關係抽取等，優化和完善團隊的核心知識圖譜",
                        "基於知識圖譜和自然語言處理技術，構建語料庫，實現自動摘要，情感分析，意圖識別，輿情分析等功能"
                    ],
                    detail: []
                },
                // {
                //     url: "https://jobs.51job.com/shanghai-pdxq/131962084.html?s=gsxq_zwlb_gsxqlb&t=17",
                //     title: "數據營銷客戶經理",
                //     summary: [
                //         "負責公司數據業務、雲架構售前宣講及客戶答疑",
                //         "參與客戶 / 合作夥伴數據可視化 / 定制化開發項目",
                //         "參與客戶 / 合作夥伴數據上雲、雲遷移、雲桌面等項目"
                //     ],
                //     detail: []
                // },
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/133148000.html?s=gsxq_zwlb_gsxqlb&t=17",
                    title: "數據庫工程師（DBA）",
                    summary: [
                        "數據庫、數據倉庫倉支持",
                        "對數據分析業務有關的內容進行數據庫開發支持",
                        "負責DB相關運行環境出現問題排查、故障處理"
                    ],
                    detail: []
                },
            ],
        },
    ]
}

// 解决方案
export const solutionHK = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "解決方案",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "解決方案",
                summary: "Solution",
                bg: banner10,
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 解决方案
            expand: "expand_6",
            status: true,
            blockTitle: {// 标题
                status: true,
                line: false,
                content: '服務領域'
            },
            card_data: [
                {
                    title: "智能製造",
                    summary: "Intelligent manufacturing",
                    bg: card_default_1,
                    url: "/solution/intelligent_manufacturing"
                },
                {
                    title: "幼教早教",
                    summary: "Preschool education early education",
                    bg: card_default_2,
                    url: "/solution/preschool_education_industry"
                },
                {
                    title: "精細化工",
                    summary: "Fine chemical industry",
                    bg: card_default_3,
                    url: "/solution/fine_chemical_industry"
                },
                {
                    title: "其他行業",
                    summary: "Other industries",
                    bg: card_default_3,
                    url: "/solution/other_industries"
                }
            ],
        },
    ]
}

// 解决方案详情--制造业
export const IntelligentManufacturingHK = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "智能製造",
    banner: {
        title: "智能製造",
        summary: "Intelligent manufacturing",
        bg: banner10,
    },
    block_1: {
        title: '行業背景(智能製造)',
        detail: '新一代信息技術與先進製造技術的深度融合，是我國從製造大國向製造強國轉變的核心路徑，製造強國戰略及「1+X」政策體系相繼出台，構建完整的智能製造發展政策支撐體系。在宏觀經濟下行壓力下，智能製造及信息化相關技術的發展持續獲得國家政策扶持，社會資本也逐漸流向智能製造相關產業。',
        img: intelligent
    },
    block_2: {
        title_1: "智能識別",
        title_2: "實時追蹤",
        title_3: "庫存管理",
        detail: "通過洞察城市人口消費、數量、質量、流動以及商戶資源等數據的變化趨勢，結合城市汽車市場發展趨勢和購車人群流向，充分發揮宜氪大數據在汽車行業的數據優勢，幫助汽車企業挖掘新市場機遇上提供數據服務。"
    },
    block_3: {
        cover: ike_data_06,
        summary: "某汽車集團對於4S門店、中轉倉庫的庫存、流通情況的追蹤、審計。",
        detail: [
            {
                title: "需求痛點",
                detail: "某汽車集團業務系統眾多，財務部門統一數據協調獲取、實施獲取難度較大，希望自己能自主進行車輛情況智能點數。"
            },
            {
                title: "解決方案",
                detail: "通過Powe Apps、Power BI、AI車輛識別等模塊的快速組合集成，實現了車庫庫存的實施實時盤點。大大減低的開發成本，把握用戶需求快速項目落地。"
            },
        ]
    },
    blockTitle_1: {// 标题
        expand: "expand_18",
        status: true,
        line: false,
        content: '解决方案'
    },
    blockTitle_2: {// 标题
        expand: "expand_18",
        status: true,
        line: false,
        content: '行业案例'
    },
}

// 解决方案详情--幼教行业
export const preschoolEducationIndustryHK = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "幼教行業",
    banner: {
        title: "幼教行業",
        summary: "Preschool education industry",
        bg: banner10,
    },
    block_1: {
        title: '行業背景(幼教)',
        detail: '教培行业经历了一场巨变，大量的教培行业被迫转型，其中教培行业转型到幼育（三岁以下）不失为一条可行的出路。十九大报告中提出的七项民生保障中，“幼有所育”排在首位。习近平总书记多次对做好托幼工作提出明确要求，“要解决好婴幼儿照护和儿童早期教育服务难以满足需求问题”，同时唯有把幼儿管理好，才能解决好好中产阶级的后顾之忧，对于出生率的提升有重要意义。市场规模方面， 2021 年托育行业市场规模约 980 亿元。人口方面，我国托育适龄人口基础大，2021 年适龄人口约 4472 万人。平均支出方面，我国人均教育文化娱乐支出过往 5 年也保持着年复合 9.77%的增速。',
        img: preschool
    },
    block_2: {
        title_1: "教務管理",
        title_2: "財務管理",
        title_3: "經營管理",
        detail: "宜氪數據通過低代碼開發平台助其迅速迭代原有ERP/CRM系統、財務模塊。通過高效信息收集系統，並輔之基於統計學數據分析方法，為幼兒做出個性化的後續服務課程，挖掘二次銷售機會，同時也助力增加學校和幼兒家長之間的粘性。"
    },
    block_3: {
        cover: ike_data_06,
        summary: "某幼教行業對於園內各個部門的管理，減少冗雜的堆積紙質化管理、追蹤、審計。從線上到線下打造一體化教育管理體系。",
        detail: [
            {
                title: "需求痛點",
                detail: "相較之K12行業，托育行業信息化基礎比較薄弱，數字化程度不高，當需求量激增的時候，信息化/數字化薄弱限制了其進一步發展。"
            },
            {
                title: "解決方案",
                detail: "宜氪數據萌愛幼教系統一切為了未來，助力打造快樂、健康、安全的教育環境。通過IKE萌愛幼教平台助其迅速迭代原有ERP/CRM系統、財務模塊。通過高效信息收集系統，並輔之基於統計學數據分析方法，為幼兒做出個性化的後續服務課程，挖掘二次銷售機會，同時也助力增加學校和幼兒家長之間的粘性。"
            },
        ]
    },
    blockTitle_1: {// 标题
        expand: "expand_18",
        status: true,
        line: false,
        content: '解决方案'
    },
    blockTitle_2: {// 标题
        expand: "expand_18",
        status: true,
        line: false,
        content: '行业案例'
    },
}

// 解决方案详情--精细化工
export const fineChemicalIndustryHK = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "精細化工",
    banner: {
        title: "精細化工",
        summary: "Fine Chemical Industry",
        bg: banner10,
    },
    block_1: {
        title: '行業背景(精細化工)',
        detail: '貿易戰不確定性較高背景下，從產業轉移看中國化工行業的發展。我們認為在貿易戰背景下，面板及半導體行業上游材料的進口替代有望加速，而化纖行業及農藥行業長期來看有產業轉出風險，但是中短期, 影響不大。面板行業：未來隨著大陸 LCD 產能的持續加速擴張，上游材料企業有望持續受益。半導體行業：隨著國內晶圓廠不斷建成、現地化配套需求不斷增加，國內優秀企業在大基金及科創板等支持下進, 口替代份額提升，有望在 2020-2025 年實現收入規模大幅增長。化纖行業：市場可能擔心下游紡織業轉出帶動化纖行業轉出。我們認為國外在管理成本、一體化優勢、銷售渠道等方面均處於劣勢，化纖紡絲仍難轉出。農藥行業：中國製造比較優勢明顯，承接了跨國公司農藥產能的轉移，而印度尚不具備承接大量產能轉移能力。',
        img: chemical
    },
    block_2: {
        title_1: "平台數據分析",
        title_2: "產品供需管理",
        title_3: "倉儲物流管理",
        detail: "宜氪數據為化工貿易企業量身打造數據分析服務。通過物流運輸、生產製造、供需管理等模塊的數據採集，提供企業數據治理分析解決方案服務，梳理業務邏輯，設計治理模型，助力企業運營效率的改善優化。"
    },
    block_3: {
        cover: ike_data_06,
        summary: "某化工貿易企業數字化建設及數據分析",
        detail: [
            {
                title: "需求痛點",
                detail: "各系統信息標準有不統一現象，或者沒有對應關係；系統之間的數據規範不同，造成還需要人工干預或補入信息；較多的非規範流程穿插在整體流程中數據分析：以上痛點造成了數據分析及可視化話的難度大大增加。"
            },
            {
                title: "解決方案",
                detail: "從LMS->SAP->Salesforce->Metadata各個系統的數據調研；從線上到線下、系統內到生產現場，進行整體環節的梳理建立優化分析模型，找出物流瓶頸，為用戶建立數據清洗治理環境（數據庫）給出優化建議；"
            },
        ]
    },
    blockTitle_1: {// 标题
        expand: "expand_18",
        status: true,
        line: false,
        content: '解決方案'
    },
    blockTitle_2: {// 标题
        expand: "expand_18",
        status: true,
        line: false,
        content: '行業案例'
    },
}

// 通用模板测试数据
export const CommonTemplate_dataHK = {
    layout: "", // fluid
    subPage: false, // 判断是否为子页面
    title: "通用測試頁面",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "宜氪數據一站式數據分析平台",
                summary: "打通數據治理全流程，快速滿足政企用戶各類不同的數據治理場景",
                bg: banner1,
                img: banner_h_r,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// banner横幅文字
            expand: "expand_3",
            status: true,// banner横幅文字, 值为true时,才会展示
            bannerText: "宜氪數據一家為企業推動數字化轉型的公司，用數學模型解決實際問題"
        },
        {// 首页轮播图
            expand: "expand_4",
            status: true,
            blockTitle: [
                "Cheerio-自然語言處理及分析",
                true
            ],
            sw_data: [
                {
                    title: "數據採集",
                    itemLIst: [
                        "數據採集",
                        "IoT"
                    ],
                    img: {
                        cover: sw_cover1,
                        bg: sw_bg
                    }
                },
                {
                    title: "數據清洗",
                    itemLIst: [
                        "分箱法",
                        "回歸法",
                        "聚類法"
                    ],
                    img: {
                        cover: sw_cover2,
                        bg: sw_bg
                    }
                },
                {
                    title: "自然語言分析",
                    itemLIst: [
                        "詞法分析",
                        "語義分析",
                        "篇章分析",
                        "文本分析"
                    ],
                    img: {
                        cover: sw_cover1,
                        bg: sw_bg
                    }
                },
                {
                    title: "數據分析",
                    itemLIst: [
                        "神經網絡",
                        "相關性分析",
                        "組合分析",
                        "選擇模型"
                    ],
                    img: {
                        cover: sw_cover2,
                        bg: sw_bg
                    }
                },
            ],
        },
        {// 六边形图文组件
            expand: "expand_5",
            status: true,
            blockTitle: [
                "業務分析與敏捷開發",
                true
            ],
            chain_data: {
                firstData: {
                    title: "成本低",
                    summary: "降低學習成本"
                },
                secondData: {
                    title: "效率高",
                    summary: "提升交付效率和協作效率"
                },
                thirdData: {
                    img: chain_png
                },
                fourthData: {
                    title: "市場廣",
                    summary: "擴大應用開發勞動力"
                },
                fifthData: {
                    title: "資源多",
                    summary: "緩解低價值需求資源困境"
                },
                sixthData: {
                    title: "兼容好",
                    summary: "與協作系統、組織架構無縫集成"
                },
            },
        },
        {// 解决方案
            expand: "expand_6",
            status: true,
            blockTitle: [
                "不同的行業 共同的選擇",
                true
            ],
            card_data: [
                {
                    title: "智能製造",
                    summary: "Intelligent manufacturing",
                    bg: card_default_1
                },
                {
                    title: "幼教早教",
                    summary: "Preschool education early education",
                    bg: card_default_2
                },
                {
                    title: "精細化工",
                    summary: "Fine chemical industry",
                    bg: card_default_3
                }
            ],
        },
        {// 标准图文组件
            expand: "expand_7",
            status: true,
            template_background: about,
            title: "上海宜氪數據科技有限公司",
            summary: "IKE是一家數據智能技術企業，擁有完整的大數據和自然語言AI智能產品線，基於行業知識及數據中台建設經驗，並於數字雲、雲計算、雲安全等項目整合經驗；提供面向企業數字化轉型的行業解決方案，構建了企業級和SaaS服務兩大業務體系，涉及數字醫療、新零售、智能製造和房地產等多個領域。致力於用數據智能促進企業智能化轉型。",
            background_image: ""
        },
        {// 招聘信息组件
            expand: "expand_8",
            status: true,
            blockTitle: [
                "期待優秀的你 加入我們",
                true
            ],
            card_data: [
                {
                    url: "",
                    title: "數據分析工程師",
                    summary: [
                        "運用數據挖掘/統計學習的理論和方法，深入挖掘和分析用戶行為、業務數據",
                        "負責數據清洗相關策略工作，包括對虛假、重復、低質等數據的發現及處理",
                        "負責數據探索、數據特徵分析、數據挖掘的驗證及建模工作",
                        "編寫數據分析報告"
                    ]
                },
                {
                    url: "",
                    title: "Python後端開發工程師",
                    summary: [
                        "本科及以上學歷，計算機相關專業",
                        "具有良好的數據結構和算法基礎，具備扎實的編程能力",
                        "3年以上python web框架(Django或Flask)開發經驗，掌握RESTful API的開發思想，熱愛編程、具有良好的代碼風格，瞭解Python的高級特性",
                        "熟悉常用數據庫，精通MySQL，Redis等，熟悉數據庫優化，瞭解非關係型數據庫例如MongoDB",
                        "具有構建分布式任務系統經驗（Celery/RabbitMQ/Redis）優先",
                        "熟悉Linux操作系統，瞭解docker，能夠進行日常服務的測試部署",
                        "瞭解前端相關技術，React、HTML、JS、CSS等",
                        "良好的自學能力、獨立解決問題的能力及溝通與團隊協作能力",
                        "獨立思考，有產品意識，能提出系統改善和產品優化者優先"
                    ]
                },
                {
                    url: "",
                    title: "自然語言處理工程師",
                    summary: [
                        "利用自然語言處理技術，從非結構化的知識中提取實體和關係，構建知識圖譜",
                        "利用自然語言處理技術，從文章、商品描述、評價等信息中進行NER、關係抽取等，優化和完善團隊的核心知識圖譜",
                        "基於知識圖譜和自然語言處理技術，構建語料庫，實現自動摘要，情感分析，意圖識別，輿情分析等功能"
                    ]
                },
            ],
        },
        {// feedback的数据
            expand: "expand_9",
            status: true,
            blockTitle: [
                "我們可以為您提供此服務",
                true
            ],
        },
        {// 数据科学(数据采集)界面数据
            expand: "expand_10",
            status: true,
            blockTitle: [
                "數據採集",
                true
            ],
            card: [
                {
                    cover: card_default_4,
                    title: "數據採集",
                    summary: "是一種按照一定的規則，自動地抓取萬維網信息的程序或者腳本。另外一些不常使用的名字還有螞蟻、自動索引、模擬程序或者蠕蟲。",
                    url: "/data_science/data_collection/resultlist/"
                },
                {
                    cover: card_default_5,
                    title: "IoT",
                    summary: "物聯網。通過溫濕度傳感器、氣體傳感器、視頻傳感器等外部硬件設備與系統進行通信，將傳感器監測到的數據傳至系統中進行採集使用。",
                    url: "/data_science/iot/"
                },
            ],
        },
        {// 数据清洗界面数据
            expand: "expand_11",
            status: true,
            blockTitle: [
                "數據清洗",
                true
            ],
            img_text: {
                feedback: true,
                shadow: true,
                context: [
                    {
                        id: "1",
                        img: content_cover_1,
                        title: "分箱法",
                        sumary:
                            "分箱法是一個經常使用到方法，所謂的分箱法，就是將需要處理的數據根據一定的規則放進箱子里，然後進行測試每一個箱子里的數據，並根據數據中的各個箱子的實際情況進行採取方法處理數據。看到這裡很多朋友只是稍微明白了，但是並不知道怎麼分箱。如何分箱呢？我們可以按照記錄的行數進行分箱，使得每箱有一個相同的記錄數。或者我們把每個箱的區間範圍設置一個常數，這樣我們就能夠根據區間的範圍進行分箱。其實我們也可以自定義區間進行分箱。這三種方式都是可以的。分好箱號，我們可以求每一個箱的平均值，中位數、或者使用極值來繪制折線圖，一般來說，折線圖的寬度越大，光滑程度也就越明顯。",
                    },
                    {
                        id: "2",
                        img: content_cover_2,
                        title: "回歸法",
                        sumary:
                            "回歸法就是利用了函數的數據進行繪製圖像，然後對圖像進行光滑處理。回歸法有兩種，一種是單線性回歸，一種是多線性回歸。單線性回歸就是找出兩個屬性的最佳直線，能夠從一個屬性預測另一個屬性。多線性回歸就是找到很多個屬性，從而將數據擬合到一個多維面，這樣就能夠消除噪聲。",
                    },
                    {
                        id: "3",
                        img: content_cover_3,
                        title: "聚類法",
                        sumary:
                            "聚類法的工作流程是比較簡單的，但是操作起來確實複雜的，所謂聚類法就是將抽象的對象進行集合分組，成為不同的集合，找到在集合意外的孤點，這些孤點就是噪聲。這樣就能夠直接發現噪點，然後進行清除即可。",
                    },
                ]
            }
        },
        {// 语义分析界面数据
            expand: "expand_12",
            status: true,
            blockTitle: [
                "语义分析",
                true
            ],
            img_text: {
                switch: false,
                context: [
                    {
                        id: "1",
                        img: content_cover_4,
                        title: "分箱法",
                        sumary:
                            "分箱法是一個經常使用到方法，所謂的分箱法，就是將需要處理的數據根據一定的規則放進箱子里，然後進行測試每一個箱子里的數據，並根據數據中的各個箱子的實際情況進行採取方法處理數據。看到這裡很多朋友只是稍微明白了，但是並不知道怎麼分箱。如何分箱呢？我們可以按照記錄的行數進行分箱，使得每箱有一個相同的記錄數。或者我們把每個箱的區間範圍設置一個常數，這樣我們就能夠根據區間的範圍進行分箱。其實我們也可以自定義區間進行分箱。這三種方式都是可以的。分好箱號，我們可以求每一個箱的平均值，中位數、或者使用極值來繪制折線圖，一般來說，折線圖的寬度越大，光滑程度也就越明顯。",
                    },
                    {
                        id: "2",
                        img: content_cover_5,
                        title: "回歸法",
                        sumary:
                            "回歸法就是利用了函數的數據進行繪製圖像，然後對圖像進行光滑處理。回歸法有兩種，一種是單線性回歸，一種是多線性回歸。單線性回歸就是找出兩個屬性的最佳直線，能夠從一個屬性預測另一個屬性。多線性回歸就是找到很多個屬性，從而將數據擬合到一個多維面，這樣就能夠消除噪聲。",
                    },
                ]
            }
        },
        {// 数据挖掘分析界面数据
            expand: "expand_12",
            status: true,
            blockTitle: [
                "數據挖掘分析",
                true
            ]
        },
    ],
}