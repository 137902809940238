import React from 'react'
import CommonTitle from '../../../../../Components/CommonTitle'
import PanelCard2 from '../../../../../Components/PanelCard2'

export default function CL8(props) {
    const { common_title, panel_card } = props
    return (
        <>
            <div className={`mdui-container-filud common`}>
                <div className={`mdui-container common_block`}>
                    <CommonTitle res_data={common_title} />
                </div>
            </div>
            <div className={`mdui-container-filud common`}>
                <div className={`mdui-container common_block`}>
                    <PanelCard2 card_data={panel_card} />
                </div>
            </div>
        </>
    )
}
