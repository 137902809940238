import React from 'react'
import Expand_16Css from './index.module.scss'

export default function Expand_16(props) {
  const { item } = props
  return (
    <div className='mdui-container'>
      <div className={`mdui-col-md-12 mdui-hidden-md-up ${Expand_16Css.ectopicCard_block}`}>
        <div className={`mdui-text-center ${Expand_16Css.ectopicCard_title}`}>{item.title}</div>
      </div>
      <div className={`mdui-row ${Expand_16Css.ectopicCard}`}>
        <div className={`mdui-col-md-4 ${Expand_16Css.ectopicCard_block}`}>
          <img className={Expand_16Css.ectopicCard_cover} src={item.img} alt={item.title} />
        </div>
        <div className={`mdui-col-md-8 ${Expand_16Css.ectopicCard_block}`}>
          <div className={`mdui-hidden-sm-down ${Expand_16Css.ectopicCard_title}`}>{item.title}</div>
          <div className={Expand_16Css.ectopicCard_detail}>{item.detail}</div>
        </div>
      </div>
    </div>
  )
}
