import { useState, useCallback, useEffect, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import Sidebar from './Sidebar';
import TopbarCss from './index.module.scss';
import Store from '../../../Redux/changeScroll';
import { LanguageList, ItemData } from '../../../Tools/RouterOptions';
import { useTranslation } from 'react-i18next'

const Topbar = () => {
    const [scrollPosition, setScrollPosition] = useState(false)
    const onRescrollPosition = useCallback(() => { setScrollPosition(Store.getState()) }, [])

    useEffect(() => {
        window.addEventListener('scroll', onRescrollPosition)
        return () => {
            window.removeEventListener('scroll', onRescrollPosition)
        }
    })

    const itemList = () => {
        return ItemData.map((item) =>
            item.show && Array.isArray(item.sub) && item.sub.length > 0 && item.sub.some(someItem => someItem.show) ?
                <Fragment key={item.id}>
                    <button className={`mdui-btn mdui-ripple mdui-ripple-blue mdui-hidden-sm-down`} mdui-menu={`{target: '#${item.id}'}`}>{item.name}</button>
                    <ul className={`mdui-menu mdui-menu-cascade ${TopbarCss.bw_menu_cascade}`} id={item.id}>
                        {
                            item.sub.map(subItem =>
                                subItem.show && Array.isArray(subItem.sub) && subItem.sub.length > 0 && subItem.sub.some(someItem => someItem.show) ?
                                    <li key={subItem.id} className="mdui-menu-item">
                                        <a href="#!" className={`mdui-ripple mdui-ripple mdui-ripple-blue ${TopbarCss.sub_more}`} onClick={(e) => { e.preventDefault() }} title={subItem.name}>{subItem.name}<span className={`mdui-menu-item-more ${TopbarCss.sub_more_icon}`}></span></a>
                                        <ul className={`mdui-menu mdui-menu-cascade ${TopbarCss.bw_menu_cascade}`}>
                                            {
                                                subItem.sub.map(subToSubItem =>
                                                    subToSubItem.show && Array.isArray(subToSubItem.sub) && subToSubItem.sub.length > 0 && subToSubItem.sub.some(someItem => someItem.show) ?
                                                        <li key={subToSubItem.id} className="mdui-menu-item">
                                                            <a href="#!" className={`mdui-ripple mdui-ripple mdui-ripple-blue ${TopbarCss.sub_more}`} onClick={(e) => { e.preventDefault() }} title={subToSubItem.name}>{subToSubItem.name}<span className={`mdui-menu-item-more ${TopbarCss.sub_more_icon}`}></span></a>
                                                            {itemList2(subToSubItem)}
                                                        </li>
                                                        :
                                                        <li key={subToSubItem.id} className={`mdui-menu-item`}>
                                                            <NavLink to={subToSubItem.slug} key={subToSubItem.id} activeClassName='bw_theme_blue bw_theme_blue_btn' className={`mdui-btn mdui-ripple mdui-ripple-blue mdui-hidden-sm-down`}>{subToSubItem.name}</NavLink>
                                                        </li>
                                                )
                                            }
                                        </ul>
                                    </li>
                                    :
                                    <li key={subItem.id} className={`mdui-menu-item`}>
                                        <NavLink to={subItem.slug} activeClassName='bw_theme_blue bw_theme_blue_btn' className={`mdui-btn mdui-ripple mdui-ripple-blue mdui-hidden-sm-down`}>{subItem.name}</NavLink>
                                    </li>
                            )
                        }
                    </ul>
                </Fragment>
                :
                <NavLink to={item.slug} key={item.id} activeClassName='bw_theme_blue bw_theme_blue_btn' className={`mdui-btn mdui-ripple mdui-ripple-blue mdui-hidden-sm-down`}>{item.name}</NavLink>
        )
    }

    const itemList2 = (subItem) => {
        return <ul className={`mdui-menu mdui-menu-cascade ${TopbarCss.bw_menu_cascade}`}>
            {
                Array.isArray(subItem.sub) && subItem.sub.length > 0 && subItem.sub.some(someItem => someItem.show) ?
                    subItem.sub.map(item =>
                        item.show && item.sub.length > 0 && item.sub.some(someItem => someItem.show) ?
                            <li key={item.id} className="mdui-menu-item">
                                <a href="#!" className={`mdui-ripple mdui-ripple mdui-ripple-blue ${TopbarCss.sub_more}`} onClick={(e) => { e.preventDefault() }} title={item.name}>{item.name}<span className={`mdui-menu-item-more ${TopbarCss.sub_more_icon}`}></span></a>
                                {itemList2(subItem.sub)}
                            </li>
                            :
                            <li key={item.id} className={`mdui-menu-item`}>
                                <NavLink to={item.slug} key={item.id} activeClassName='bw_theme_blue bw_theme_blue_btn' className={`mdui-btn mdui-ripple mdui-ripple-blue mdui-hidden-sm-down`}>{item.name}</NavLink>
                            </li>
                    )
                    :
                    <li key={subItem.id} className={`mdui-menu-item`}>
                        <NavLink to={subItem.slug} key={subItem.id} activeClassName='bw_theme_blue bw_theme_blue_btn' className={`mdui-btn mdui-ripple mdui-ripple-blue mdui-hidden-sm-down`}>{subItem.name}</NavLink>
                    </li>
            }
        </ul>
    }

    const { t } = useTranslation()
    const changeLanguage = (language_code) => {
        return () => {
            localStorage.setItem('i18nextLng', language_code)
            window.location.reload()
        }
    }

    return (
        <>
            <div className={`mdui-appbar mdui-appbar-fixed bw_blur ${scrollPosition ? 'bw_theme_blur_white' : 'bw_theme_blur_dark'} bw_theme_hover`}>
                <div className='mdui-container'>
                    <div className="mdui-toolbar">
                        <button className="mdui-btn mdui-btn-icon mdui-hidden-md-up" mdui-drawer="{target: '#left-drawer'}">
                            <i className="mdui-icon material-icons">menu</i>
                        </button>
                        <div className="mdui-typo-headline">
                            <i className={`iconfont icon-yike-02 ${TopbarCss.ike_logo} ${scrollPosition && TopbarCss.bw_logo}`}></i>
                        </div>
                        <div className="mdui-toolbar-spacer"></div>
                        {itemList()}{/* PC端菜单列表 */}
                        <button className={`mdui-btn mdui-ripple mdui-ripple-blue`} mdui-menu={`{target: '#switch-language'}`}>
                            <i className="mdui-icon material-icons">&#xe894;</i>&nbsp;&nbsp;{t("NowLanguage")}
                        </button>
                        <ul className={`mdui-menu mdui-menu-cascade ${TopbarCss.bw_menu_cascade}`} id='switch-language'>
                            {
                                LanguageList.map(item =>
                                    <li key={item.slug} className={`mdui-menu-item`}>
                                        <a href="#!" className="mdui-ripple" title={item.name} onClick={changeLanguage(item.slug)}>
                                            {item.name}
                                        </a>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
            {/* <div className='bw_theme_blur_dark' style={{ width: '100%', height: '54px' }}></div> */}
            <Sidebar itemList={ItemData} />
        </>
    );
}

export default Topbar;
