import React from 'react';
import ImageAndText from '../../../../Components/Portal/ImageAndText';
import Expand11Css from './index.module.scss'

const Expand11 = (props) => {
    const { item, layout } = props
    return (
        <div className={`${Expand11Css.Expand11_block_2}`}>
            <div className={`mdui-container${layout && `-${layout}`}`}>
                {
                    item.img_text &&
                    <ImageAndText content={item.img_text.context} feedback={item.img_text.feedback} shadow={item.img_text.shadow} />
                }
            </div>
        </div>
    );
}

export default Expand11;
