import React from 'react'
import CommonTitle from '../../../../../Components/CommonTitle'
import PanelCard1 from '../../../../../Components/PanelCard1'

export default function CL_6(props) {
    const { common_title, panel_card } = props
    return (
        <>
            <div className={`mdui-container-filud common`}>
                <div className={`mdui-container common_block`}>
                    <CommonTitle res_data={common_title} />
                </div>
            </div>
            <div className={`mdui-container-filud common`}>
                <div className={`mdui-container common_block`}>
                    <PanelCard1 res_data={panel_card} />
                </div>
            </div>
        </>
    )
}
