import React from 'react';
import { Link } from 'react-router-dom';

const Card3 = (param) => {

    const { cover, title, summary, url } = param

    return (
        <div className="mdui-card">
            <div className="mdui-card-media">
                <img src={cover} alt='' />
                <div className="mdui-card-media-covered bw_blur bw_theme_blur_dark">
                    <div className="mdui-card-primary">
                        <div className="mdui-card-primary-title">{title}</div>
                    </div>
                </div>
            </div>
            <div className="mdui-card-content">{summary}</div>
            <div className="mdui-card-actions">
                <Link to={url} className="mdui-btn mdui-ripple bw_theme_blue bw_theme_blue_btn mdui-float-right">点击查看</Link>
            </div>
        </div>
    );
}

export default Card3;
