import SwPaginationCss from './SwPageStyle.module.scss';

const SwPagination = (swiper, current, _total, setSw) => {
    const sw_item = [
        '数据科学',
        '数据清洗',
        '自然语言',
        '数据分析'
    ]
    const arr = sw_item.map((item, index) => `<div class="mdui-ripple ${SwPaginationCss.sw_page_tag_sub_item} ${current === index + 1 && `${SwPaginationCss.sw_page_active}`}" data-index=${index + 1}>${item}</div>`)
    setSw(swiper)

    return (
        `${arr.join('')}`
    );
}

export default SwPagination;
