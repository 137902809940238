import React from 'react';
import SloganCss from './index.module.scss';

const Slogan = (props) => {

    const { res_data } = props

    return (
        <div className={SloganCss.banner_text}>
            <div className={`${res_data.layout ? `mdui-container-${res_data.layout}` : "mdui-container"}`}>
                <div className='mdui-row'>
                    <div className={`${SloganCss.banner_content} mdui-valign`}>
                        <div className="mdui-center wow pulse animated">
                            {res_data}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Slogan;
