import React from 'react';
import Banner from '../../../../Components/Portal/Banner';

const Expand1 = (props) => {
    const { item } = props
    return (
        <Banner state={item.banner} />
    );
}

export default Expand1;
