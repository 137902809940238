import { Switch, Route, Redirect } from 'react-router-dom';
import Topbar from '../../Components/Portal/Topbar';
import Footer from '../../Components/Portal/Footer';
import DataScienceList from './DataScienceList';
import DataScienceListDetail from './DataScienceListDetail';
import { ItemData } from '../../Tools/RouterOptions';
import CommonTemplate from './CommonTemplate';
import BusinessTemplate from './BusinessTemplate';
import DefaultTemplate from './DefaultTemplate';
import NotFound from '../../Components/Portal/NotFound';

const Portal = () => {

    const switch_idt = (idt, crumbs) => {
        switch (idt) {
            case 'dataList':
                return () => <DataScienceList crumbs={crumbs} />
            case 'dataDetail':
                return () => <DataScienceListDetail crumbs={crumbs} />
            case 'commonTemplate':
                return () => <CommonTemplate crumbs={crumbs} />
            case 'BusinessTemplate':
                return () => <BusinessTemplate crumbs={crumbs} />
            case 'DefaultTemplate':
                return () => <DefaultTemplate crumbs={crumbs} />
            default:
                return NotFound
        }
    }

    let arr = []

    const idt = (ItemDataTree) => {
        ItemDataTree.forEach(item => {
            if (Array.isArray(item.sub) && item.sub.length > 0) {
                idt(item.sub)
            }
            arr.push(<Route key={item.id} exact path={item.slug} component={switch_idt(item.template, item.crumbs)} />)
        }
        )
    }

    idt(ItemData)

    return (
        <>
            <Topbar />
            <Switch>
                {arr}
                <Route exact path='/404/' component={NotFound} />
                <Redirect exact from="/" to="/home" />
                <Redirect exact from="/data_science" to="/data_science/data_collection" />
                <Redirect to="/404" />
            </Switch>
            <Footer />
        </>
    );
}

export default Portal;
