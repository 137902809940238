import React from 'react';
import BlockTitle from '../../../../Components/Portal/BlockTitle';
import Demo from '../../../../Components/Portal/Demo';
import Expand14Css from './index.module.scss'

const Expand14 = (props) => {
    const { item, layout } = props
    return (
        <>
            <div className={`mdui-container${layout && `-${layout}`}`}>
                <div className="mdui-row">
                    <div className={Expand14Css.Expand14_title}>{item.blockTitle && <BlockTitle item={item.blockTitle} />}</div>
                    <Demo active_model={item.active_model} demo_model_data={item.post_to} />
                </div>
            </div>
        </>
    );
}

export default Expand14;
