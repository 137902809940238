import React from 'react';
import BlockTitle from '../../../../Components/Portal/BlockTitle';
import FeedBack from '../../../../Components/Portal/FeedBack';
import Expand9Css from './index.module.scss'

const Expand9 = (props) => {
    const { item, layout } = props
    return (
        <div className={Expand9Css.Expand9_block}>
            <div className={`mdui-container${layout && `-${layout}`}`}>
                <div className='mdui-row'>
                    <div className={Expand9Css.Expand9_title}>{item.blockTitle && <BlockTitle item={item.blockTitle} />}</div>
                    <FeedBack />
                </div>
            </div>
        </div>
    );
}

export default Expand9;
