import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from "i18next";
import enUsTrans from "./en-us.json";
import zhCnTrans from "./zh-cn.json";
import zhHkTrans from "./zh-hk.json";
import { initReactI18next } from 'react-i18next';

const res = {
    'zh-CN': {
        translation: zhCnTrans,
    },
    'zh-HK': {
        translation: zhHkTrans,
    },
    'en-US': {
        translation: enUsTrans,
    },
}

i18n.use(LanguageDetector) //嗅探当前浏览器语言
    .use(initReactI18next) //init i18next
    .init({
        //引入资源文件
        resources: res,
        debug: false,
        interpolation: {
            escapeValue: false, // 不需要react的默认转义
        },
    })

export default i18n;