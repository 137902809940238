import React from 'react';
import BlockTitle from '../../../../Components/Portal/BlockTitle';
import Card2 from '../../../../Components/Portal/Card2';
import Expand8Css from './index.module.scss'

const Expand8 = (props) => {
    const { item, layout, subPage } = props
    return (
        <div className={`${subPage === true && Expand8Css.Expand8_block_2} ${subPage === false && `bw_theme_odd_block_blue ${Expand8Css.Expand8_block}`}`}>
            <div className={`mdui-container${layout && `-${layout}`}`}>
                <div className={Expand8Css.Expand8_title}>{item.blockTitle && <BlockTitle item={item.blockTitle} />}</div>
                {item.card_data && <Card2 card_data={item.card_data} />}
            </div>
        </div>
    );
}

export default Expand8;
