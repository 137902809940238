import React from 'react';
import BlockTitle from '../../../../Components/Portal/BlockTitle';
import SwDuble from '../../../../Components/Portal/SwDuble';
import Expand4Css from './index.module.scss'

const Expand4 = (props) => {
    const { item, layout, subPage } = props
    return (
        <div className={`${Expand4Css.Expand4_block} ${subPage === false && "bw_theme_odd_block_blue"}`}>
            <div className={`mdui-container${layout && `-${layout}`}`}>
                <div className='mdui-row'>
                    <div className={Expand4Css.Expand4_title}>{item.blockTitle && <BlockTitle item={item.blockTitle} />}</div>
                    {item.sw_data && <SwDuble swiper_data={item.sw_data} />}
                </div>
            </div>
        </div>
    );
}

export default Expand4;
