import banner5 from "../Source/Image/banner5.png";
import banner1 from "../Source/Image/banner1.png";
import banner2 from "../Source/Image/banner2.png";
import banner3 from "../Source/Image/banner3.png";
import banner6 from "../Source/Image/banner6.png";
import banner7 from "../Source/Image/banner7.png";
import banner8 from "../Source/Image/banner8.png";
import banner9 from "../Source/Image/banner9.png";
import banner10 from "../Source/Image/banner10.png";
import about from "../Source/Image/about.png";
import banner_h_r from "../Source/Image/banner_h_r.png";
import sw_cover1 from "../Source/Image/sw_cover1.png";
import sw_bg from "../Source/Image/sw_bg.png";
import sw_cover2 from "../Source/Image/sw_cover2.png";
import chain_png from "../Source/Image/chain.png";
import content_cover_1 from "../Source/Image/content_cover_1.png";
import content_cover_2 from "../Source/Image/content_cover_2.png";
import content_cover_3 from "../Source/Image/content_cover_3.png";
import content_cover_4 from "../Source/Image/content_cover_4.png";
import content_cover_5 from "../Source/Image/content_cover_5.png";
import card_default_1 from "../Source/Image/card_default_1.png";
import card_default_2 from "../Source/Image/card_default_2.png";
import card_default_3 from "../Source/Image/card_default_3.png";
import card_default_4 from "../Source/Image/card_default_4.png";
import card_default_5 from "../Source/Image/card_default_5.png";
import ike_data_01 from "../Source/Image/ike_data_01.png";
import ike_data_02 from "../Source/Image/ike_data_02.png";
import ike_data_03 from "../Source/Image/ike_data_03.png";
import ike_data_04 from "../Source/Image/ike_data_04.png";
import ike_data_05 from "../Source/Image/ike_data_05.png";
import ike_data_06 from "../Source/Image/ike_data_06.png";
import preschool from "../Source/Image/preschool.png";
import chemical from "../Source/Image/chemical.png";
import intelligent from "../Source/Image/intelligent.png";

// 首页
export const homeStateEN = {
    layout: "", // fluid
    subPage: false, // 判断是否为子页面
    title: "IKE DATA",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "IKE data one-stop data analysis platform",
                summary: "Opens up the whole process of data governance, quickly meeting various data governance scenarios of government and enterprise users",
                bg: banner1,
                img: banner_h_r,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// banner横幅文字
            expand: "expand_3",
            status: true,// banner横幅文字, 值为true时,才会展示
            bannerText: "IKE Data, a company that promotes digital transformation for companies, uses mathematical models to solve real-world problems"
        },
        {// 首页轮播图
            expand: "expand_4",
            status: true,
            blockTitle: {
                line: true,
                content: 'Cheerio-Natural language processing and analysis',
                wide: false,
            },
            sw_data: [
                {
                    title: "Data collection",
                    itemLIst: [
                        "Data collection",
                        "IoT"
                    ],
                    img: {
                        cover: sw_cover1,
                        bg: sw_bg
                    }
                },
                {
                    title: "Data cleaning",
                    itemLIst: [
                        "Box method",
                        "Regression method",
                        "Clustering method"
                    ],
                    img: {
                        cover: sw_cover2,
                        bg: sw_bg
                    }
                },
                {
                    title: "Natural language parsin",
                    itemLIst: [
                        "Lexical analysis",
                        "Semantic analysis",
                        "Discourse Analysis",
                        "Text analysis"
                    ],
                    img: {
                        cover: sw_cover1,
                        bg: sw_bg
                    }
                },
                {
                    title: "Data mining",
                    itemLIst: [
                        "Neural network",
                        "Correlation analysis",
                        "Combinatorial analysis",
                        "Selection model"
                    ],
                    img: {
                        cover: sw_cover2,
                        bg: sw_bg
                    }
                },
            ],
        },
        {// 六边形图文组件
            expand: "expand_5",
            status: true,
            blockTitle: {
                line: true,
                content: 'Business analysis and Agile development',
                wide: false,
            },
            // blockTitle: [
            //     "业务分析与敏捷开发",
            //     true
            // ],
            chain_data: {
                firstData: {
                    title: "Low cost",
                    summary: "Reduce the cost of learning"
                },
                secondData: {
                    title: "High efficiency",
                    summary: "Improve delivery efficiency and assistance efficiency"
                },
                thirdData: {
                    img: chain_png
                },
                fourthData: {
                    title: "Broad market",
                    summary: "Expand application development workforce"
                },
                fifthData: {
                    title: "Abundant resources",
                    summary: "Alleviate the resource dilemma of low value demand"
                },
                sixthData: {
                    title: "Compatibility",
                    summary: "Seamless integration with collaboration systems and organizational structures"
                },
            },
        },
        {// 解决方案
            expand: "expand_6",
            status: true,
            blockTitle: {
                line: true,
                content: 'Common choice of different industries',
                wide: false,
            },
            card_data: [
                {
                    title: "Intelligent manufacturing",
                    summary: "Intelligent manufacturing",
                    bg: card_default_1
                },
                {
                    title: "Preschool education early education",
                    summary: "Preschool education early education",
                    bg: card_default_2
                },
                {
                    title: "Fine chemical industry",
                    summary: "Fine chemical industry",
                    bg: card_default_3
                }
            ],
        },
        {// 标准图文组件
            expand: "expand_7",
            status: true,
            template_background: about,
            title: "Shanghai  IKE Data Technology Co.,Ltd",
            summary: "IKE is a data intelligence technology enterprise, with a complete product line of big data and natural language AI intelligence. Based on industry knowledge and data construction experience, IKE has integration experience in digital cloud, cloud computing, cloud security and other projects. It provides industrial solutions for enterprise digital transformation, and has built two business systems of enterprise and SaaS services, involving digital healthcare, new retail, intelligent manufacturing and real estate, etc. Committed to using data intelligence to promote intelligent transformation of enterprises.",
            background_image: ""
        },
        {// 招聘信息组件
            expand: "expand_8",
            status: true,
            blockTitle: {
                line: true,
                content: 'We look forward to your joining us',
                wide: false,
            },
            card_data: [
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/134972928.html?s=gsxq_zwlb_gsxqlb&t=17",
                    title: "Data Analysis Engineer",
                    summary: [
                        "In-depth mining and analysis of user behavior and business data by using data mining/statistical learning theories and methods",
                        "Responsible for data cleaning related strategies, including the discovery and processing of false, duplicate and low-quality data",
                        "Responsible for data exploration, data feature analysis, data mining validation and modeling",
                        " Prepare data analysis reports"
                    ],
                    detail: [
                        "Bachelor degree or above, major in statistics, computer science or related field",
                        "At least 2 years of data analysis experience in automotive manufacturing industry is considered",
                        "Understand the main process of data analysis, association analysis, classification prediction, collaborative filtering, cluster analysis, regression analysis, time series analysis and other common analysis methods",
                        "Familiar with writing Python, able to use Python data modeling and related algorithms",
                        "Familiar with current popular database, proficient in SQL statement, etc",
                        "Responsible and good team player, willing to communicate and share, passionate and willing to take challenges"
                    ]
                },
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/136524178.html?s=sou_sou_soulb&t=0_0",
                    title: "Python backend developer",
                    summary: [
                        "Bachelor degree or above, major in computer science",
                        "Good data structure and algorithm foundation, solid programming skills",
                        "More than 3 years python Web framework development experience (Django or Flask), master RESTful API development ideas, love programming, good code style, understand advanced features of Python",
                        "Familiar with common database, proficient in MySQL, Redis, etc., familiar with database optimization, understand non-relational database such as MongoDB",
                        "Experience in building distributed task systems (Celery/RabbitMQ/Redis) is preferred",
                        "Familiar with Linux operating system and Docker, able to test and deploy daily services",
                        "Understand front-end technologies like React, HTML, JS, CSS, etc",
                        "Good at self-study, independent problem solving, communication and team work",
                        "Independent thinking, product awareness, can propose system improvement and product optimization preferred"
                    ],
                    detail: []
                },
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/129315562.html?s=gsxq_zwlb_gsxqlb&t=17",
                    title: "Natural language processing engineer",
                    summary: [
                        "Using natural language processing techniques to extract entities and relationships from unstructured knowledge and build knowledge maps",
                        "Optimize and improve the team's core knowledge map by using natural language processing technology to extract NER and relationship from articles, product descriptions, reviews and other information",
                        "Build a corpus based on knowledge mapping and natural language processing technology to realize automatic summarization, sentiment analysis, intention recognition, public opinion analysis and other functions"
                    ],
                    detail: []
                },
            ],
        },
        {// feedback的数据
            expand: "expand_9",
            status: true,
            blockTitle: {
                line: true,
                content: 'We can provide this service for you',
                wide: true,
            },
        }
    ],
}

// 数据清洗
export const data_clearEN = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "Data collection",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "Data collection",
                summary: "Big data collection refers to the process of obtaining data from sensors and smart devices, enterprise online systems, enterprise offline systems, social networks and Internet platforms",
                bg: banner5,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: 'Data cleaning'
        },
        {// 数据清洗界面数据
            expand: "expand_11",
            status: true,
            img_text: {
                feedback: true,
                shadow: true,
                context: [
                    {
                        id: "1",
                        img: content_cover_1,
                        title: "Points box method",
                        sumary:
                            "Box-sorting method is a frequently used method. The so-called box-sorting method is to put the data to be processed into boxes according to certain rules, and then test the data in each box, and take methods to process the data according to the actual situation of each box in the data. See here many friends just a little understand, but do not know how to divide boxes. How do you divide cases? We can divide boxes according to the number of recorded rows, so that each box has the same number of records. Or we can set the range of each container to a constant, so that we can divide containers according to the range of the range. In fact, we can also customize the interval for container sorting. All three are ok. By sorting the carton number, we can calculate the average, median or extreme value of each carton to draw a line chart. Generally speaking, the wider the line chart is, the more obvious the smoothness is.",
                    },
                    {
                        id: "2",
                        img: content_cover_2,
                        title: "Regression method",
                        sumary:
                            "Regression method is to use the data of the function to draw the image, and then smooth the image. There are two kinds of regression method, one is single linear regression, one is multi linear regression. Monolinear regression is to find the best line for two attributes, which can predict one attribute from the other. Multiple linear regression is to find many attributes to fit the data to a multidimensional surface, so that noise can be eliminated.",
                    },
                    {
                        id: "3",
                        img: content_cover_3,
                        title: "Clustering method",
                        sumary:
                            "The working process of clustering method is relatively simple, but the operation is really complicated. The so-called clustering method is to group abstract objects into different sets and find unexpected solitary points in the set, which are noise. In this way, the noise can be found directly and then removed.",
                    },
                ]
            }
        },
    ],
}

// 语义分析
export const semantic_analysisEN = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "Semantic analysis",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "Semantic analysis",
                summary: "Analyzes the semantic relationships between the linguistic units of a sentence and presents the semantic relationships as dependency structures.",
                bg: banner5,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: 'Semantic analysis'
        },
        {// 语义分析界面数据
            expand: "expand_11",
            status: true,
            img_text: {
                feedback: false,
                shadow: true,
                context: [
                    {
                        id: "1",
                        img: content_cover_4,
                        title: "Points box method",
                        sumary:
                            "Box-sorting method is a frequently used method. The so-called box-sorting method is to put the data to be processed into boxes according to certain rules, and then test the data in each box, and take methods to process the data according to the actual situation of each box in the data. See here many friends just a little understand, but do not know how to divide boxes. How do you divide cases? We can divide boxes according to the number of recorded rows, so that each box has the same number of records. Or we can set the range of each container to a constant, so that we can divide containers according to the range of the range. In fact, we can also customize the interval for container sorting. All three are ok. By sorting the carton number, we can calculate the average, median or extreme value of each carton to draw a line chart. Generally speaking, the wider the line chart is, the more obvious the smoothness is.",
                    },
                    {
                        id: "2",
                        img: content_cover_5,
                        title: "Regression method",
                        sumary:
                            "Regression method is to use the data of the function to draw the image, and then smooth the image. There are two kinds of regression method, one is single linear regression, one is multi linear regression. Monolinear regression is to find the best line for two attributes, which can predict one attribute from the other. Multiple linear regression is to find many attributes to fit the data to a multidimensional surface, so that noise can be eliminated.",
                    },
                ]
            }
        },
    ],
}

// 数据挖掘分析
export const data_analysisEN = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "Data mining",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "Data mining",
                summary: "The use of the most advanced data mining technology, for enterprises to capture the entire network information, based on massive data and accurate algorithm, the website traffic trend, user behavior, product sales and other accurate prediction.",
                bg: banner5,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: '数据挖掘分析'
        },
    ],
}

// 数据科学(数据采集)
export const dataScienceEN = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "Data collection",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "Data collection",
                summary: "Big data cannot be separated from data analysis, and data analysis cannot be separated from data. There are a lot of data that we need, but also a lot of data that we do not need. Just as there is no pure thing in the world, there will be impurities in the data, which requires us to clean the data to ensure the reliability of the data.",
                bg: banner2,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 数据科学(数据采集)界面数据
            expand: "expand_10",
            status: true,
            blockTitle:
            {// 标题
                line: false,
                content: 'Data collection'
            },
            card: [
                {
                    cover: card_default_4,
                    title: "Data collection",
                    summary: "Data collection is a program or script that automatically or manually extracts information from the World Wide Web and business system according to certain rules, and collects a large amount of data that needs to be summarized. It is the necessary preparation work for data analysis.",
                    url: "/data_science/data_collection/resultlist"
                },
                {
                    cover: card_default_5,
                    title: "IoT",
                    summary: " IoT，Communicate with the system through external hardware devices such as temperature and humidity sensors, gas sensors and video sensors, and transmit the data monitored by the sensors to the system for collection and use; Data obtained by IoT devices is one of the important means of data collection.",
                    url: "/data_science/iot"
                },
            ],
        },
    ],
}

// 数据采集结果
export const dataScienceListEN = {
    title: "Data collection",
    banner: {
        title: "Data collection",
        summary: "The massive data resources of the whole Internet are open to you to explore the infinite possibilities of data",
        bg: banner3,
    },
    cat: [
        {
            name: "Finance and economics",
            url: "/cat1/"
        },
        {
            name: "The home of the car",
            url: "/cat1/"
        }
    ],
    dataItem: [
        {
            id: 1,
            title: "HJ 1128-2020 Technical specification for environmental emergency monitoring of nuclear accidents in nuclear power plants",
            time: "2022-01-17",
            url: "/data_science/data_collection/resultlist/detail/"
        },
        {
            id: 2,
            title: "HJ 1128-2020 Technical specification for environmental emergency monitoring of nuclear accidents in nuclear power plants",
            time: "2022-01-17",
            url: "/data_science/data_collection/resultlist/detail/"
        },
        {
            id: 3,
            title: "HJ 1128-2020 Technical specification for environmental emergency monitoring of nuclear accidents in nuclear power plants",
            time: "2022-01-17",
            url: "/data_science/data_collection/resultlist/detail/"
        },
        {
            id: 4,
            title: "HJ 1128-2020 Technical specification for environmental emergency monitoring of nuclear accidents in nuclear power plants",
            time: "2022-01-17",
            url: "/data_science/data_collection/resultlist/detail/"
        },
        {
            id: 5,
            title: "HJ 1128-2020 Technical specification for environmental emergency monitoring of nuclear accidents in nuclear power plants",
            time: "2022-01-17",
            url: "/data_science/data_collection/resultlist/detail/"
        },
        {
            id: 6,
            title: "HJ 1128-2020 Technical specification for environmental emergency monitoring of nuclear accidents in nuclear power plants",
            time: "2022-01-17",
            url: "/data_science/data_collection/resultlist/detail/"
        },
    ]
}

// 数据采集结果详情
export const dataScienceListDetailEN = {
    title: "Details of data collection results",
    banner: {
        title: "Data collection",
        summary: "The massive data resources of the whole Internet are open to you to explore the infinite possibilities of data",
        bg: banner3,
    },
    file_data: {
        Title: "HJ 1128-2020 Technical specification for environmental emergency monitoring of nuclear accidents in nuclear power plants",
        FileType: "Excel 格式",
        FileSize: "236.00KB",
        RecommendTheWebsite: {
            url: "www.ike-data.com",
            title: "www.ike-data.com"
        },
        Authorization: "As a free download"
    }
}

// IoT（物联网）
export const iotEN = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "IoT（物联网）",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "IoT（物联网）",
                summary: "Jointly foster a healthy Internet ecosystem",
                bg: banner3,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// feedback的数据
            expand: "expand_9",
            status: true,
            blockTitle:
            {// 标题
                line: false,
                content: 'We can provide this service for you'
            },
        }
    ],
}

// 敏捷开发
export const agile_developmentEN = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "Agile Business Development",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "How does low code +AI build roads for enterprise digitization",
                summary: "Low threshold and high efficiency, the new choice of industry software customization development",
                bg: banner5,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: 'Why does low code come about'
        },
        {// 数据清洗界面数据
            expand: "expand_11",
            status: true,
            img_text: {
                feedback: false,
                shadow: true,
                context: [
                    {
                        id: "1",
                        img: ike_data_02,
                        title: "",
                        sumary: "The Low Code Development Platform (LCDP) is itself a type of software that provides a development environment for developers to create applications. Unlike traditional code ides, low-code development platforms provide visual ides that are higher and easier to use. Most of the time, developers don't need to use traditional hand-written code to program, but can use graphical drag-and-drop, parameter configuration, and more efficient ways to do development. In short, low code development is for everyone, allowing people to quickly design a management application regardless of their programming skills and occupation. Growing mature with a high-level programming language, and the domestic and foreign computer talent cultivation scale expands gradually, from 2010 to 2015 is a traditional software and the rise of SaaS software, programming researchers found that: the function of the software the same, the duplication is very high, leading to a large part of the software development cost is wasted on the function of repeat programming. With the maturity of these concepts, foreign software manufacturers have released low-code or zero-code development platforms, and explored and proved the possibility of success of such products. Based on the success of foreign exploration, the Chinese market also set off a 'low code/zero code' upsurge, and gradually formed a complete product ecosystem in the past two years.",
                    },
                ]
            }
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: '低代码+RPA+AI'
        },
        {// 大尺寸图块
            expand: "expand_15",
            status: true,
            img: ike_data_03
        },
    ],
}

// 关于宜氪
export const about_usEN = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "About us",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "About us",
                summary: "About us",
                bg: banner7,
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 数据清洗界面数据
            expand: "expand_11",
            status: true,
            img_text: {
                feedback: false,
                shadow: false,
                context: [
                    {
                        id: "1",
                        img: ike_data_04,
                        title: "Company introduction",
                        sumary: "IKE is a data intelligence technology enterprise, with a complete product line of big data and natural language AI intelligence. Based on industry knowledge and data construction experience, IKE has integration experience in digital cloud, cloud computing, cloud security and other projects. It provides industrial solutions for enterprise digital transformation, and has built two business systems of enterprise and SaaS services, involving digital healthcare, new retail, intelligent manufacturing and real estate, etc. Committed to using data intelligence to promote intelligent transformation of enterprises.",
                    },
                ]
            }
        },
        {// 大尺寸图块
            expand: "expand_15",
            status: true,
            img: ike_data_05,
            blockTitle:
            {// 标题
                expand: "expand_18",
                status: true,
                line: false,
                content: 'Core values'
            },
        },
        {// 标准图文组件
            expand: "expand_7",
            status: true,
            template_background: about,
            title: "Our strategic thinking",
            summary: "Mission: Focus on the needs and challenges of customers in the era of big data, provide independently developed first-class software and services, and continue to create maximum value for customers Core value: the company's core value is our belief, is our internal motivation to move forward, but also the commitment to users and employees Vision: Let information create value",
            background_image: banner8
        },
    ],
}

// 词法分析
export const lexical_analysisEN = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "词法分析",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "Lexical analysis",
                summary: "Lexical analysis is based on big data and user behavior, providing word segmentation, part-of-speech tagging, named entity recognition, positioning basic language elements, and comprehensively supporting machine understanding and analysis of basic texts.",
                bg: banner6,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: 'Function Introduction'
        },
        {// 列表图文
            expand: "expand_13",
            status: true,
            instructions: [
                {
                    title: "PParticiples",
                    summary: "It refers to the cutting of Chinese character sequences into word sequences. In Chinese, word is the most basic unit that carries meaning. Word segmentation is the basis of many Chinese natural language processing tasks such as information retrieval, text classification and sentiment analysis",
                },
                {
                    title: "The part of speech tagging",
                    summary: "The process of assigning a part of speech to each word in a natural language text",
                },
                {
                    title: "Named entity recognition",
                    summary: "It is to locate and identify entities such as people's names, place names and organizations in the word sequence of a sentence",
                }
            ],
            cover: ike_data_01,
        },
        {// demo展示组件
            expand: "expand_14",
            status: true,
            active_model: false,
            post_to: [
                {
                    req_path: '/demo/lexical_analysis/',
                    model: [
                        'text',
                        'document'
                    ]
                }
            ]
        },
    ]
}

// 篇章分析
export const discourse_analysisEN = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "Discourse analysis",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "Discourse analysis",
                summary: "Discourse analysis is an organized and meaningful whole of natural language text composed of more than one sentence or utterance. An essay, a conversation, etc., can be considered a chapter. The sentences (or paragraphs) that make up a text are connected with each other in form and coherent in meaning.",
                bg: banner6,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        // {// 标题
        //     expand: "expand_18",
        //     status: true,
        //     line: false,
        //     content: 'Functional experience'
        // },
        {// demo展示组件
            expand: "expand_14",
            status: true,
            active_model: true,
            blockTitle: {// 标题
                expand: "expand_18",
                status: true,
                line: false,
                content: 'Functional experience'
            },
            post_to: [
                {
                    icon: "icon-fl1",
                    title: "Text categorization",
                    req_path: "/demo/text_classification/",
                    describe: {
                        title: "Text categorization",
                        summary: "ext categorization is a research topic that combines pattern recognition and natural language processing. The traditional text classification is based on the text content and studies how to automatically divide the text into political, economic, military, sports, entertainment and other types."
                    },
                    model: [
                        'text'
                    ]
                },
                {
                    icon: "icon-tq",
                    title: "Keywords extraction",
                    req_path: "/demo/keyword_extraction/",
                    describe: {
                        title: "Keywords extraction",
                        summary: "Keywords extraction is a branch of text mining, and it is the basic work of text mining research, such as text retrieval, document comparison, abstract generation, document classification and clustering. From the perspective of algorithm, there are two main types of keyword extraction algorithms: unsupervised keyword extraction method and supervised keyword extraction method."
                    },
                    model: [
                        'text'
                    ]
                },
                {
                    icon: "icon-emo",
                    title: "Sentiment analysis",
                    req_path: "/demo/sentiment_analysis/",
                    describe: {
                        title: "Sentiment analysis",
                        summary: "Sentiment analysis is text bias analysis, where the computer determines whether people's opinions or comments are positive, negative or neutral."
                    },
                    model: [
                        'text'
                    ]
                },
                {
                    icon: "icon-cy",
                    title: "Automatic summarization",
                    req_path: "/demo/abstract_of_the_article/",
                    describe: {
                        title: "Automatic summarization",
                        summary: "Automatic summarization is the use of computer automatic text analysis, content and the technology of the automatically generated."
                    },
                    model: [
                        'text'
                    ]
                },
            ],
        },
    ]
}

// 文本分析
export const text_analysisEN = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "Text analysis",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "Text analysis",
                summary: "Semantic Role Labeling (SRL) is a shallow Semantic analysis technique that labels certain phrases in sentences as arguments (Semantic roles) for a given predicate, such as agent, receiver, experiencer, time and place, etc.",
                bg: banner6,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: 'Functional experience'
        },
        {// demo展示组件
            expand: "expand_14",
            status: true,
            active_model: true,
            post_to: [
                {
                    icon: "icon-xsd",
                    title: "Text similarity",
                    req_path: "/",
                    describe: {
                        title: "Text similarity",
                        summary: "Text similarity refers to measure the similarity degree of two texts. There are many aspects to evaluate the similarity degree: pure literal similarity, semantic similarity and style similarity."
                    },
                    model: [
                        'text'
                    ]
                },
                {
                    icon: "icon-xc",
                    title: "Neologism discovery",
                    req_path: "/",
                    describe: {
                        title: "Neologism discovery",
                        summary: "Neologism discovery is one of the basic tasks of NLP, which mainly hopes to determine which character fragments in a batch of corpus may be a new word through unsupervised exploration of some linguistic features (mainly statistical features)."
                    },
                    model: [
                        'text'
                    ]
                },
                {
                    icon: "icon-jc",
                    title: "Text error correction",
                    req_path: "/",
                    describe: {
                        title: "Text error correction",
                        summary: "Automatically correct spelling, grammar, punctuation, sensitive words and other problems in Chinese text, indicating the error location and return suggestions for modification"
                    },
                    model: [
                        'text'
                    ]
                },
                {
                    icon: "icon-cz",
                    title: "Phrase similarity",
                    req_path: "/",
                    describe: {
                        title: "Phrase similarity",
                        summary: "Word similarity can be generally divided into two methods, one is calculated based on Ontology or Taxonomy, the other is calculated based on large-scale corpus."
                    },
                    model: [
                        'text'
                    ]
                },
            ]
        },
    ]
}

// 加入我们
export const join_usEN = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "Jion us",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "Jion us",
                summary: "Join us",
                bg: banner9,
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 标题
            expand: "expand_18",
            status: true,
            line: false,
            content: 'We look forward to your joining us'
        },
        {// 招聘信息组件
            expand: "expand_8",
            status: true,
            card_data: [
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/134972928.html?s=gsxq_zwlb_gsxqlb&t=17",
                    title: "Data Analysis Engineer",
                    summary: [
                        "Use data mining/statistical learning theories and methods to deeply mine and analyze user behavior and business data",
                        "Responsible for data cleaning related policies, including the discovery and processing of fake, duplicate and low quality data",
                        "Responsible for data exploration, data feature analysis, data mining validation and modeling",
                        "Prepare data analysis report"
                    ],
                    detail: [
                        "Bachelor degree or above, major in statistics, computer science or related field",
                        "At least 2 years of data analysis experience in automotive manufacturing industry is considered.",
                        "Understand the main process of data analysis, association analysis, classification prediction, collaborative filtering, cluster analysis, regression analysis, time series analysis and other common analysis methods",
                        "Familiar with writing Python, able to use Python data modeling and related algorithms",
                        "Familiar with current popular database, proficient in SQL statement, etc.",
                        "Responsible and good team player, willing to communicate and share, passionate and willing to take challenges"
                    ]
                },
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/136524178.html?s=sou_sou_soulb&t=0_0",
                    title: "Python backend developer",
                    summary: [
                        "Bachelor degree or above, major in computer science or related",
                        "Good data structure and algorithm foundation, solid programming skills",
                        "More than 3 years python Web framework development experience (Django or Flask), master RESTful API development ideas, love programming, good code style, understand advanced features of Python ",
                        "Familiar with common database, proficient in MySQL, Redis, etc., familiar with database optimization, understand non-relational database such as MongoDB",
                        "Experience in building distributed task systems (Celery/RabbitMQ/Redis) is preferred",
                        "Familiar with Linux operating system and Docker, able to test and deploy daily services",
                        "Understand front-end technologies like React, HTML, JS, CSS, etc.",
                        "Good at self-study, independent problem solving, communication and team work",
                        "Independent thinking, product awareness, can propose system improvement and product optimization preferred"
                    ],
                    detail: []
                },
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/129315562.html?s=gsxq_zwlb_gsxqlb&t=17",
                    title: "Natural language processing engineer",
                    summary: [
                        "Using natural language processing techniques to extract entities and relationships from unstructured knowledge to build knowledge maps",
                        "Optimize and improve the team's core knowledge map by using natural language processing technology to extract NER and relationship from articles, product descriptions, reviews and other information",
                        "Build a corpus based on knowledge mapping and natural language processing technology to realize automatic summarization, sentiment analysis, intention recognition, public opinion analysis and other functions"
                    ],
                    detail: []
                },
                // {
                //     url: "https://jobs.51job.com/shanghai-pdxq/131962084.html?s=gsxq_zwlb_gsxqlb&t=17",
                //     title: "Account manager, Data marketing",
                //     summary: [
                //         "Responsible for pre-sales publicity of company data business, cloud architecture and customer q&A",
                //         "Participate in customer/partner data visualization/customization development projects",
                //         "Participate in customer/partner data cloud, cloud migration, cloud desktop and other projects"
                //     ],
                //     detail: []
                // },
                {
                    url: "https://jobs.51job.com/shanghai-pdxq/133148000.html?s=gsxq_zwlb_gsxqlb&t=17",
                    title: "Database Engineer (DBA)",
                    summary: [
                        "Database, data warehouse warehouse support ",
                        "Database development support for data analysis business related content",
                        "Responsible for troubleshooting and troubleshooting of DB related operating environment"
                    ],
                    detail: []
                },
            ],
        },
    ]
}

// 解决方案
export const solutionEN = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "Solution",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "Solution",
                summary: "Solution",
                bg: banner10,
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// 解决方案
            expand: "expand_6",
            status: true,
            blockTitle: {// 标题
                status: true,
                line: false,
                content: 'The service secto'
            },
            card_data: [
                {
                    title: "Intelligent manufacturing",
                    summary: "Intelligent manufacturing",
                    bg: card_default_1,
                    url: "/solution/intelligent_manufacturing"
                },
                {
                    title: "Preschool education early education",
                    summary: "Preschool education early education",
                    bg: card_default_2,
                    url: "/solution/preschool_education_industry"
                },
                {
                    title: "Fine chemical industry",
                    summary: "Fine chemical industry",
                    bg: card_default_3,
                    url: "/solution/fine_chemical_industry"
                },
                {
                    title: "Other industries",
                    summary: "Other industries",
                    bg: card_default_3,
                    url: "/solution/other_industries"
                }
            ],
        },
    ]
}

// 解决方案详情--制造业
export const IntelligentManufacturingEN = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "Intelligent manufacturing",
    banner: {
        title: "Intelligent manufacturing",
        summary: "Intelligent manufacturing",
        bg: banner10,
    },
    block_1: {
        title: 'Industry background(Intelligent manufacturing)',
        detail: "The deep integration of the new generation of information technology and advanced manufacturing technology is the core path of China's transformation from a manufacturing power to a manufacturing power. The manufacturing power strategy and the '1+X' policy system have been introduced to build a complete intelligent manufacturing development policy support system. Under the downward pressure of macro economy, the development of intelligent manufacturing and information technology continues to receive national policy support, and social capital is gradually flowing to intelligent manufacturing related industries.",
        img: intelligent
    },
    block_2: {
        title_1: "Intelligent identification",
        title_2: "Real-time tracking",
        title_3: "Inventory management",
        detail: "Implementation content: By observing the changing trend of urban population consumption, quantity, quality, flow, merchant resources and other data, combined with the development trend of urban automobile market and the flow of car buyers, we can give full play to the data advantages of Yikr big data in the automobile industry, and help automobile enterprises to provide data services in mining new market opportunities."
    },
    block_3: {
        cover: ike_data_06,
        summary: "Track and audit the inventory and circulation of 4S stores and transit warehouses of an auto group.",
        detail: [
            {
                title: "Demand",
                detail: "An automobile group has many business systems, and it is difficult for the financial department to coordinate and obtain unified data and implement the acquisition. Therefore, it is hoped that the financial department can carry out the intelligent counting of vehicle situation independently."
            },
            {
                title: "Solution",
                detail: "Through the rapid combination and integration of modules such as Powe Apps, Power BI and AI vehicle recognition, the implementation of real-time inventory of garage inventory is realized. Greatly reduce the development cost, grasp user demand fast project landing."
            },
        ]
    },
    blockTitle_1: {// 标题
        expand: "expand_18",
        status: true,
        line: false,
        content: 'Solution'
    },
    blockTitle_2: {// 标题
        expand: "expand_18",
        status: true,
        line: false,
        content: '行业案例'
    },
}

// 解决方案详情--幼教行业
export const preschoolEducationIndustryEN = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "Preschool education early education",
    banner: {
        title: "Preschool education early education",
        summary: "Preschool education industry",
        bg: banner10,
    },
    block_1: {
        title: 'Industry background(Preschool education early education)',
        detail: "The education and training industry has undergone a great change, and a large number of education and training industries have been forced to transform, among which the transformation of the education and training industry to the young (under three years old) is a feasible way out. In the report to the 19th National Congress of the Communist Party of China (CPC), 'children and education' topped the list of seven livelihood guarantees. General Secretary Xi Jinping has repeatedly put forward clear requirements for child care, 'to solve the problem that infant care and early childhood education services can not meet the demand', and only by managing children well can we solve the worries of the middle class, which is of great significance to the increase of the birth rate. In terms of market size, the market size of childcare industry in 2021 is about 98 billion yuan. In terms of population, China has a large child-care population, with a population of about 44.72 million in 2021. In terms of average expenditure, China's per capita expenditure on education, culture and entertainment has also maintained a compound annual growth rate of 9.77% in the past five years.",
        img: preschool
    },
    block_2: {
        title_1: "Educational administration management",
        title_2: "Financial management",
        title_3: "Operation management",
        detail: "IKE Data helped it quickly iterate the original ERP/CRM system and financial module through the low-code development platform. Through the efficient information collection system, supplemented by statistical data analysis methods, personalized follow-up service courses are made for children, and secondary sales opportunities are explored, while also helping to increase the stickiness between the school and children's parents."
    },
    block_3: {
        cover: ike_data_06,
        summary: "A preschool education industry for the management of various departments in the park, reduce the jumbled accumulation of paper management, tracking, audit. Create an integrated education management system from online to offline.",
        detail: [
            {
                title: "Demand ",
                detail: "Compared with THE K12 industry, the informatization foundation of the childcare industry is weak and the degree of digitalization is not high. When the demand surges, the informatization/digitalization weakness limits its further development."
            },
            {
                title: "Solution",
                detail: "IKE Data MOE love preschool education system all for the future, to help create a happy, healthy and safe education environment. Help them quickly iterate the original ERP/CRM system and financial module through IKE Mengai preschool education platform. Through the efficient information collection system, supplemented by statistical data analysis methods, personalized follow-up service courses are made for children, and secondary sales opportunities are explored, while also helping to increase the stickiness between the school and children's parents."
            },
        ]
    },
    blockTitle_1: {// 标题
        expand: "expand_18",
        status: true,
        line: false,
        content: '解决方案'
    },
    blockTitle_2: {// 标题
        expand: "expand_18",
        status: true,
        line: false,
        content: '行业案例'
    },
}

// 解决方案详情--精细化工
export const fineChemicalIndustryEN = {
    layout: "", // fluid
    subPage: true, // 判断是否为子页面
    title: "Fine chemical industry",
    banner: {
        title: "Fine chemical industry",
        summary: "Fine Chemical Industry",
        bg: banner10,
    },
    block_1: {
        title: 'Industry background(Fine chemical industry)',
        detail: "Under the background of high uncertainty of trade war, the development of China's chemical industry is viewed from the perspective of industrial transfer. We believe that in the context of trade war, the import substitution of upstream materials in panel and semiconductor industry is expected to accelerate, while the chemical fiber industry and pesticide industry have the risk of industrial transfer in the long term, but in the short and medium term Not much. Panel industry: Upstream materials companies are expected to continue to benefit from the continued acceleration of LCD capacity expansion in the mainland. Semiconductor industry: with the continuous construction of domestic fabs and the increasing demand for on-site supporting facilities, outstanding domestic enterprises are advancing with the support of large funds and science and technology innovation board It is expected to achieve significant growth in revenue scale in 2020-2025. Chemical fiber industry: The market may be concerned about the downstream textile industry to drive the chemical fiber industry to move out. We believe that foreign countries are at a disadvantage in management costs, integration advantages, sales channels and other aspects, and chemical fiber spinning is still difficult to transfer out. Pesticide industry: Chinese manufacturers have obvious comparative advantages and have undertaken the transfer of pesticide production capacity from multinational companies, while India does not have the capacity to undertake a large amount of production capacity transfer.",
        img: chemical
    },
    block_2: {
        title_1: "Platform data analysis",
        title_2: "Product supply and demand management",
        title_3: "Warehousing and logistics management",
        detail: "IKE Data provides tailored data analysis services for chemical trade enterprises. Through data collection of logistics and transportation, production and manufacturing, supply and demand management and other modules, we provide enterprise data governance analysis solution services, sort out business logic, design governance model, and help improve and optimize enterprise operation efficiency."
    },
    block_3: {
        cover: ike_data_06,
        summary: "Digital construction and data analysis of a chemical trade enterprise",
        detail: [
            {
                title: "Demand",
                detail: "Information standards of different systems are not unified or there is no corresponding relationship; Data specifications are different between systems, resulting in the need for manual intervention or information filling; More non-standard processes are interspersed in the overall process. Data analysis: The above pain points have greatly increased the difficulty of data analysis and visualization."},
            {
                title: "Solution",
                detail: "From LMS->SAP->Salesforce->Metadata each system data survey; From online to offline, in the system to the production site, sorting out the whole process to establish optimization analysis model, find out logistics bottlenecks, and give optimization suggestions for users to establish data cleaning and management environment (database);"
            },
        ]
    },
    blockTitle_1: {// 标题
        expand: "expand_18",
        status: true,
        line: false,
        content: 'Solution'
    },
    blockTitle_2: {// 标题
        expand: "expand_18",
        status: true,
        line: false,
        content: 'Business case'
    },
}

// 通用模板测试数据
export const CommonTemplate_dataEN = {
    layout: "", // fluid
    subPage: false, // 判断是否为子页面
    title: "Common test page",
    model_item: [
        {// banner的数据
            expand: "expand_1",
            status: true,   // 为ture时才会展示
            banner: {
                title: "IKE data one-stop data analysis platform",
                summary: "Opens up the whole process of data governance, quickly meeting various data governance scenarios of government and enterprise users",
                bg: banner1,
                img: banner_h_r,
                url: "/"
            }
        },
        {// 面包屑导航
            expand: "expand_2",
            status: true, // 面包屑导航, 值为true且子页面的值也为true时,才会展示
        },
        {// banner横幅文字
            expand: "expand_3",
            status: true,// banner横幅文字, 值为true时,才会展示
            bannerText: "IKE Data, a company that promotes digital transformation for companies, uses mathematical models to solve real-world problems"
        },
        {// 首页轮播图
            expand: "expand_4",
            status: true,
            blockTitle: [
                "Cheerio-Natural language processing and analysis",
                true
            ],
            sw_data: [
                {
                    title: "Data collection",
                    itemLIst: [
                        "Data collection",
                        "IoT"
                    ],
                    img: {
                        cover: sw_cover1,
                        bg: sw_bg
                    }
                },
                {
                    title: "Data cleaning",
                    itemLIst: [
                        "Box method",
                        "Regression method",
                        "Clustering method"
                    ],
                    img: {
                        cover: sw_cover2,
                        bg: sw_bg
                    }
                },
                {
                    title: "Natural language parsin",
                    itemLIst: [
                        "Lexical analysis",
                        "Semantic analysis",
                        "Discourse Analysis",
                        "Text analysis"
                    ],
                    img: {
                        cover: sw_cover1,
                        bg: sw_bg
                    }
                },
                {
                    title: "Data analysis",
                    itemLIst: [
                        "Neural network",
                        "Correlation analysis",
                        "Combinatorial analysis",
                        "Selection model"
                    ],
                    img: {
                        cover: sw_cover2,
                        bg: sw_bg
                    }
                },
            ],
        },
        {// 六边形图文组件
            expand: "expand_5",
            status: true,
            blockTitle: [
                "Business analysis and Agile development",
                true
            ],
            chain_data: {
                firstData: {
                    title: "Low cost",
                    summary: "Reduce the cost of learning"
                },
                secondData: {
                    title: "High efficiency",
                    summary: "Improve delivery efficiency and assistance efficiency"
                },
                thirdData: {
                    img: chain_png
                },
                fourthData: {
                    title: "Broad market",
                    summary: "Expand application development workforce"
                },
                fifthData: {
                    title: "Abundant resources",
                    summary: "Alleviate the resource dilemma of low value demand境"
                },
                sixthData: {
                    title: "Compatibility",
                    summary: "Seamless integration with collaboration systems and organizational structures"
                },
            },
        },
        {// 解决方案
            expand: "expand_6",
            status: true,
            blockTitle: [
                "Common choice of different industries",
                true
            ],
            card_data: [
                {
                    title: "Intelligent manufacturing",
                    summary: "Intelligent manufacturing",
                    bg: card_default_1
                },
                {
                    title: "Preschool education early education",
                    summary: "Preschool education early education",
                    bg: card_default_2
                },
                {
                    title: "Fine chemical industry",
                    summary: "Fine chemical industry",
                    bg: card_default_3
                }
            ],
        },
        {// 标准图文组件
            expand: "expand_7",
            status: true,
            template_background: about,
            title: "Shanghai IKE Data Technology Co.,Ltd",
            summary: "IKE is a data intelligence technology enterprise, with a complete product line of big data and natural language AI intelligence. Based on industry knowledge and data construction experience, IKE has integration experience in digital cloud, cloud computing, cloud security and other projects. It provides industrial solutions for enterprise digital transformation, and has built two business systems of enterprise and SaaS services, involving digital healthcare, new retail, intelligent manufacturing and real estate, etc. Committed to using data intelligence to promote intelligent transformation of enterprises.",
            background_image: ""
        },
        {// 招聘信息组件
            expand: "expand_8",
            status: true,
            blockTitle: [
                "We look forward to your joining us",
                true
            ],
            card_data: [
                {
                    url: "",
                    title: "Data Analysis Engineer",
                    summary: [
                        "Use data mining/statistical learning theories and methods to deeply mine and analyze user behavior and business data ",
                        "Responsible for data cleaning related policies, including the discovery and processing of fake, duplicate and low quality data",
                        "Responsible for data exploration, data feature analysis, data mining validation and modeling",
                        "Prepare data analysis report"
                    ]
                },
                {
                    url: "",
                    title: "Python backend developer",
                    summary: [
                        "Bachelor degree or above, major in computer science or related ",
                        "Good data structure and algorithm foundation, solid programming skills ",
                        "More than 3 years python Web framework development experience (Django or Flask), master RESTful API development ideas, love programming, good code style, understand advanced features of Python",
                        "Familiar with common database, proficient in MySQL, Redis, etc., familiar with database optimization, understand non-relational database such as MongoDB",
                        "Experience in building distributed task systems (Celery/RabbitMQ/Redis) is preferred ",
                        "Familiar with Linux operating system and Docker, able to test and deploy daily services ",
                        "Understand front-end technologies like React, HTML, JS, CSS, etc.",
                        "Good at self-study, independent problem solving, communication and team work",
                        "Independent thinking, product awareness, can propose system improvement and product optimization preferred"
                    ]
                },
                {
                    url: "",
                    title: "Natural language processing engineer",
                    summary: [
                        "Using natural language processing techniques to extract entities and relationships from unstructured knowledge to build knowledge maps",
                        "Optimize and improve the team's core knowledge map by using natural language processing technology to extract NER and relationship from articles, product descriptions, reviews and other information",
                        "Build a corpus based on knowledge mapping and natural language processing technology to realize automatic summarization, sentiment analysis, intention recognition, public opinion analysis and other functions"
                    ]
                },
            ],
        },
        {// feedback的数据
            expand: "expand_9",
            status: true,
            blockTitle: [
                "We can provide this service for you",
                true
            ],
        },
        {// 数据科学(数据采集)界面数据
            expand: "expand_10",
            status: true,
            blockTitle: [
                "Data collection",
                true
            ],
            card: [
                {
                    cover: card_default_4,
                    title: "Data collection",
                    summary: "Data collection: Big data collection refers to the process of obtaining data from sensors and smart devices, enterprise online systems, enterprise offline systems, social networks and Internet platforms.",
                    url: "/data_science/data_collection/resultlist/"
                },
                {
                    cover: card_default_5,
                    title: "IoT",
                    summary: "IoT，Communicate with the system through external hardware devices such as temperature and humidity sensors, gas sensors and video sensors, and transmit the data monitored by the sensors to the system for collection and use; Data obtained by IoT devices is one of the important means of data collection.",
                    url: "/data_science/iot/"
                },
            ],
        },
        {// 数据清洗界面数据
            expand: "expand_11",
            status: true,
            blockTitle: [
                "Data cleaning",
                true
            ],
            img_text: {
                feedback: true,
                shadow: true,
                context: [
                    {
                        id: "1",
                        img: content_cover_1,
                        title: "Points box method",
                        sumary:
                            "Box-sorting method is a frequently used method. The so-called box-sorting method is to put the data to be processed into boxes according to certain rules, and then test the data in each box, and take methods to process the data according to the actual situation of each box in the data. See here many friends just a little understand, but do not know how to divide boxes. How do you divide cases? We can divide boxes according to the number of recorded rows, so that each box has the same number of records. Or we can set the range of each container to a constant, so that we can divide containers according to the range of the range. In fact, we can also customize the interval for container sorting. All three are ok. By sorting the carton number, we can calculate the average, median or extreme value of each carton to draw a line chart. Generally speaking, the wider the line chart is, the more obvious the smoothness is.",
                    },
                    {
                        id: "2",
                        img: content_cover_2,
                        title: "Regression method",
                        sumary:
                            "Regression method is to use the data of the function to draw the image, and then smooth the image. There are two kinds of regression method, one is single linear regression, one is multi linear regression. Monolinear regression is to find the best line for two attributes, which can predict one attribute from the other. Multiple linear regression is to find many attributes to fit the data to a multidimensional surface, so that noise can be eliminated.",
                    },
                    {
                        id: "3",
                        img: content_cover_3,
                        title: "Clustering method",
                        sumary:
                            "The working process of clustering method is relatively simple, but the operation is really complicated. The so-called clustering method is to group abstract objects into different sets and find unexpected solitary points in the set, which are noise. In this way, the noise can be found directly and then removed.",
                    },
                ]
            }
        },
        {// 语义分析界面数据
            expand: "expand_12",
            status: true,
            blockTitle: [
                "语义分析",
                true
            ],
            img_text: {
                switch: false,
                context: [
                    {
                        id: "1",
                        img: content_cover_4,
                        title: "Points box method",
                        sumary:
                            "Box-sorting method is a frequently used method. The so-called box-sorting method is to put the data to be processed into boxes according to certain rules, and then test the data in each box, and take methods to process the data according to the actual situation of each box in the data. See here many friends just a little understand, but do not know how to divide boxes. How do you divide cases? We can divide boxes according to the number of recorded rows, so that each box has the same number of records. Or we can set the range of each container to a constant, so that we can divide containers according to the range of the range. In fact, we can also customize the interval for container sorting. All three are ok. By sorting the carton number, we can calculate the average, median or extreme value of each carton to draw a line chart. Generally speaking, the wider the line chart is, the more obvious the smoothness is.",
                    },
                    {
                        id: "2",
                        img: content_cover_5,
                        title: "Regression method",
                        sumary:
                            "Regression method is to use the data of the function to draw the image, and then smooth the image. There are two kinds of regression method, one is single linear regression, one is multi linear regression. Monolinear regression is to find the best line for two attributes, which can predict one attribute from the other. Multiple linear regression is to find many attributes to fit the data to a multidimensional surface, so that noise can be eliminated.",
                    },
                ]
            }
        },
        {// 数据挖掘分析界面数据
            expand: "expand_12",
            status: true,
            blockTitle: [
                "Data mining analysis",
                true
            ]
        },
    ],
}