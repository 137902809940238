import React from 'react'
import CommonTitle from '../../../../../Components/CommonTitle'
import Chain from '../../../../../Components/Chain'
import '../index.scss'

export default function CL_5(props) {
    const { common_title, chain_data } = props
    return (
        <>
            <div className={`mdui-container-filud common_2`}>
                <div className={`mdui-container common_2_block`}>
                    <CommonTitle res_data={common_title} />
                </div>
            </div>
            <div className={`mdui-container-filud common_2`}>
                <div className={`mdui-container common_2_block`}>
                    <Chain chain_data={chain_data} />
                </div>
            </div>
        </>
    )
}