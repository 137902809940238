import React from 'react';
import StandardGraphic from '../../../../Components/Portal/StandardGraphic';
import Expand7Css from './index.module.scss';

const Expand7 = (props) => {

    const {item, layout} = props

    return (
        <div style={{ backgroundImage: `url(${item.background_image})`, backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
            <div className={Expand7Css.template_background} style={{ backgroundImage: `url(${item.template_background})` }}></div>
            <div className={`mdui-container${layout && `-${layout}`}`}>
                <StandardGraphic title={item.title} summary={item.summary} />
            </div>
        </div>
    );
}

export default Expand7;
