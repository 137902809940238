import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import BannerCss from './index.module.scss';
import Store from '../../Redux/changeScroll';

const Banner = (param) => {

    const { res_data } = param

    const [bannerHeight, setBannerHeight] = useState({ h: 0, w: 0 })
    const onRescrollPosition = useCallback(() => {
        const scrollPosition = document.documentElement.scrollTop || document.body.scrollTop
        Store.dispatch({ type: 'changeScroll', v: scrollPosition > bannerHeight.h ? true : false })
    }, [bannerHeight])

    useEffect(() => {
        window.addEventListener('scroll', onRescrollPosition)
        return () => {
            window.removeEventListener('scroll', onRescrollPosition)
        }
    })

    useEffect(() => {
        const bw_bannerHeight = document.querySelector('#home_banner')
        const obj = { h: bw_bannerHeight.offsetHeight, w: bw_bannerHeight.offsetWidth }
        setBannerHeight(obj)
    }, [])

    const { t } = useTranslation()

    return (
        <div className={`mdui-container-fluid ${BannerCss.home_banner}`} style={{ backgroundImage: `url(${res_data.backgrand_image})`, minHeight: `${res_data.top_cover ? '82vh' : 'auto'}`, height: `${res_data.top_cover ? '82vh' : 'auto'}`, padding: '35px 0' }} id='home_banner'>
            <div className={`mdui-container`} style={{ height: `${res_data.top_cover && bannerHeight.h}px` }}>
                <div className={`${BannerCss.home_banner_container} mdui-valign`}>
                    <div className={`mdui-row mdui-center ${BannerCss.home_banner_row}`}>
                        <div className={`mdui-col-md-7 mdui-typo mdui-text-color-white`}>
                            <div className={`${BannerCss.home_banner_summary} wow slideInLeft animated`}>
                                <div className={`mdui-typo-display-1 ${BannerCss.home_banner_summary_title}`}>{res_data.title}</div>
                                <div className={`mdui-typo-subheading ${BannerCss.home_banner_summary_text}`}>{res_data.summary}</div>
                                {
                                    res_data.jump_to && <button className='mdui-btn mdui-ripple bw_theme bw_theme_blue mdui-hidden-sm-down' style={{ margin: '0 0 50px' }}>{t("Trans.Use")}</button>
                                }
                            </div>
                        </div>
                        {
                            res_data.top_cover && <div className={`mdui-col-md-5 ${BannerCss.home_banner_controller}`}>
                                <div className={`${BannerCss.home_banner_controller_now}`}>
                                    <img className={BannerCss.home_banner_img} src={res_data.top_cover} alt="" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Banner;
