import React, { useState, useEffect } from 'react';
import NotFoundCss from './index.module.scss';
import NotFoundImg from '../../../Source/Image/404.png';
import { useHistory } from 'react-router-dom';

const NotFound = () => {
    const [countdown, setCountdown] = useState(5)
    const history = useHistory()

    useEffect(() => {
        localStorage.setItem("title1", 'dashboard')
        localStorage.setItem("title2", 'dashboard_tr')
        let s = 5
        let t = setInterval(() => {
            if (s > 0) {
                setCountdown((countdown) => { s--; return (countdown - 1) })
            } else {
                clearInterval(t)
                history.push("/data_science/iot")
            }
        }, 1000)
    }, [])
    return (
        <div className={`mdui-container ${NotFoundCss.not_block}`}>
            <img src={NotFoundImg} alt="" width='50%' />
            <span>更多解决方案请联系我们, {countdown}秒后为您跳转到联系页面</span>
        </div>
    );
}

export default NotFound;
