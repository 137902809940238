import React from 'react'
import CommonTitle from '../../../../../Components/CommonTitle'
import '../index.scss'

export default function CL_3(props) {
    return (
        <div className={`mdui-container-filud common`}>
            <div className={`mdui-container common_block`}>
                <CommonTitle res_data={props.res_data} />
            </div>
        </div>
    )
}