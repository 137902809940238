import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { nanoid } from 'nanoid';
import NotFound from '../../../Components/Portal/NotFound';
import Banner from '../../../Components/Portal/Banner';
import BreadItem from '../../../Components/Portal/BreadItem';
import BlockTitle from '../../../Components/Portal/BlockTitle';
import {
    preschoolEducationIndustry,
    IntelligentManufacturing,
    fineChemicalIndustry
} from '../../../Tools/DemoData';
import {
    preschoolEducationIndustryHK,
    IntelligentManufacturingHK,
    fineChemicalIndustryHK
} from '../../../Tools/DemoDataHK';
import {
    preschoolEducationIndustryEN,
    IntelligentManufacturingEN,
    fineChemicalIndustryEN
} from '../../../Tools/DemoDataEN';
import BusinessTemplateCss from './index.module.scss'

// 注意Axios请求时,若请求不到数据, 查看异步请求结果

const BusinessTemplate = (props) => {

    const backToTop = () => {
        document.documentElement.scrollTop = document.body.scrollTop = 0;
    }

    backToTop()

    const { crumbs } = props
    const { pathname } = useLocation()

    const lang = localStorage.getItem('i18nextLng')

    let state = ''
    lang === 'zh-HK' ?
        state =
        pathname === "/solution/preschool_education_industry" ? preschoolEducationIndustryHK :
            pathname === "/solution/fine_chemical_industry" ? fineChemicalIndustryHK :
                pathname === "/solution/intelligent_manufacturing" ? IntelligentManufacturingHK : false
        :
        lang === 'en-US' ?
            state =
            pathname === "/solution/preschool_education_industry" ? preschoolEducationIndustryEN :
                pathname === "/solution/fine_chemical_industry" ? fineChemicalIndustryEN :
                    pathname === "/solution/intelligent_manufacturing" ? IntelligentManufacturingEN : false
            :
            state =
            pathname === "/solution/preschool_education_industry" ? preschoolEducationIndustry :
                pathname === "/solution/fine_chemical_industry" ? fineChemicalIndustry :
                    pathname === "/solution/intelligent_manufacturing" ? IntelligentManufacturing : false

    useEffect(() => {
        const old_title = (document.title).split(' ')
        document.title = `${state.title ? state.title : ''} ${old_title[old_title.length - 1]}`
    })

    return (
        <>
            {
                state ? (
                    <>
                        <Banner state={state.banner} />
                        <div className='bw_theme_white'>
                            <div className={`mdui-container${state.layout && `-${state.layout}`}`}>
                                <div className='mdui-row'>
                                    <BreadItem bread={crumbs} />
                                </div>
                            </div>
                        </div>

                        <div className={BusinessTemplateCss.btc_block}>
                            <div className={`mdui-container${state.layout && `-${state.layout}`}`}>
                                <div className={`mdui-col-md-12 mdui-hidden-md-up ${BusinessTemplateCss.ectopicCard_block}`}>
                                    <div className={`mdui-text-center ${BusinessTemplateCss.ectopicCard_title}`}>{state.block_1.title}</div>
                                </div>
                                <div className={`mdui-row ${BusinessTemplateCss.ectopicCard}`} style={{ minHeight: '280px' }}>
                                    <div className={`mdui-col-md-4 ${BusinessTemplateCss.ectopicCard_block} ${BusinessTemplateCss.ectopicCard_block_left}`}>
                                        <img className={BusinessTemplateCss.ectopicCard_cover} src={state.block_1.img} alt={state.block_1.title} />
                                    </div>
                                    <div className={`mdui-col-md-8 ${BusinessTemplateCss.ectopicCard_block}`}>
                                        <div className={`mdui-hidden-sm-down ${BusinessTemplateCss.ectopicCard_title}`}>{state.block_1.title}</div>
                                        <div className={BusinessTemplateCss.ectopicCard_detail}>{state.block_1.detail}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={BusinessTemplateCss.btc_block}>
                            <div className={BusinessTemplateCss.btc_title}><BlockTitle item={state.blockTitle_1} /></div>
                            <div className={`mdui-container${state.layout && `-${state.layout}`}`}>
                                <div className='mdui-row'>
                                    <div className={`mdui-col-sm-4 mdui-text-center ${BusinessTemplateCss.Expand_17}`}><div className={`${BusinessTemplateCss.Expand_17_title}`}><span>◆</span> {state.block_2.title_1}</div></div>
                                    <div className={`mdui-col-sm-4 mdui-text-center ${BusinessTemplateCss.Expand_17}`}><div className={`${BusinessTemplateCss.Expand_17_title}`}><span>◆</span> {state.block_2.title_2}</div></div>
                                    <div className={`mdui-col-sm-4 mdui-text-center ${BusinessTemplateCss.Expand_17}`}><div className={`${BusinessTemplateCss.Expand_17_title}`}><span>◆</span> {state.block_2.title_3}</div></div>
                                    <div className={`mdui-col-xs-12 ${BusinessTemplateCss.Expand_17_detail} mdui-valign`}>
                                        <div className='mdui-center'>{state.block_2.detail}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={BusinessTemplateCss.btc_block}>
                            <div className={BusinessTemplateCss.btc_title}><BlockTitle item={state.blockTitle_2} /></div>
                            <div className={`mdui-container${state.layout && `-${state.layout}`}`}>
                                <div className={`mdui-row`}>
                                    <div className={`mdui-col-md-4`}>
                                        <img className={``} src={state.block_3.cover} alt={state.block_1.title} width="100%" />
                                    </div>
                                    <div className={`mdui-col-md-8`}>
                                        <div className={BusinessTemplateCss.ectopicCard_detail}>
                                            <p><strong>{state.block_3.summary}</strong></p>
                                            <ul style={{ listStyle: 'none', margin: '0', padding: '0' }}>
                                                {
                                                    state.block_3.detail.map(
                                                        item => <li key={nanoid()}>
                                                            <ul className={BusinessTemplateCss.btc_end_detail}>
                                                                <li>{item.title}</li>
                                                                <li>{item.detail}</li>
                                                            </ul>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) :
                    <NotFound />
            }
        </>
    );
}

export default BusinessTemplate;
